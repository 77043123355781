import React, { useState } from 'react';
import { QueryRenderer, useMutation, useRelayEnvironment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  Alert,
  Button,
  Col, Divider, Form, Input, message, Row, Skeleton, Steps, Typography,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { useNavigate, useParams } from 'react-router-dom';
import {
  UserOutlined, WifiOutlined, LockOutlined, EnvironmentOutlined, HomeOutlined, KeyOutlined, PhoneOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';

const { Text } = Typography;
const { Step } = Steps;

const PropertyHouseManualFormQuery = graphql`
query PropertyHouseManualFormQuery($id: ID!) {
  property(id: $id) {
    title
    city
    country
    orderedImages {
      edges {
          node {
            id
            url
          }
      }
  }
    housemanual {
      id
      ownerContact
      wifiName
      wifiPassword
      keysLocation
      emergencyContact
      garbageInfo
      checkInCheckOutProcedure
      localTransporationInfo
      localAttractionsAndRecommendations
      houseRules
      parkingInfo
      safetyInformation
      miscellaneous
    }
  }
}
`;

const UpsertHouseManualMutation = graphql`
  mutation PropertyHouseManualFormMutation($input: UpsertHouseManualMutationInput!) {
    upsertHouseManual(input: $input) {
      property{
        housemanual {
          id
          ownerContact
          wifiName
          wifiPassword
          keysLocation
          emergencyContact
          garbageInfo
          checkInCheckOutProcedure
          localTransporationInfo
          localAttractionsAndRecommendations
          houseRules
          parkingInfo
          safetyInformation
          miscellaneous
        }
      }
    }
  }
`;

function PropertyHouseManualForm() {
  const environment = useRelayEnvironment();
  const { id } = useParams();
  const [commit, isInFlight] = useMutation(UpsertHouseManualMutation);
  const navigate = useNavigate();

  const createCustomFormatter = (threshold = 100) => ({ count, maxLength }) => (maxLength - count <= threshold ? `${count}/${maxLength}` : '');

  const onFinish = (values) => {
    commit({
      variables: {
        input: {
          houseManualData: {
            ...values,
            property: id, // assuming you've got the id from the useParams() hook
          },
        },
      },
      onCompleted: (response, errors) => {
        if (errors) {
          message.error(errors[0].message);
        } else {
          message.success('House manual saved.');
        }
      },
      onError: (error) => {
        message.error('Something went wrong.');
        console.error('Error:', error);
      },
    });
  };

  return (
    <QueryRenderer
      environment={environment}
      query={PropertyHouseManualFormQuery}
      variables={{ id }}
      render={({ error, props }) => {
        if (error) {
          throw error;
        }

        if (!props) {
          return (
            <Row>
              <Col span={12} offset={6}>
                <Skeleton active />
                <Divider />
                <Skeleton active />
                <Divider />
                <Skeleton active />
              </Col>
            </Row>
          );
        }

        const houseManual = props?.property?.housemanual;
        const title = props?.property.title;
        const city = props?.property.city;
        const country = props?.property.country;
        const firstImageUrl = props?.property?.orderedImages?.edges?.[0]?.node?.url || null;

        return (
          <div style={{
            minHeight: 'calc(100vh-66px)', height: 'calc(100vh - 66px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
          }}
          >
            <Row style={{
              flexGrow: 1, overflowY: 'auto', width: '100%', padding: 16,
            }}
            >
              <Col lg={{ span: 12, offset: 6 }} md={{ span: 16, offset: 4 }} sm={{ span: 24 }}>
                <div
                  className="headerWithOverlay"
                  style={{
                    backgroundImage: `url(${firstImageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: 'white',
                    padding: '32px',
                    textAlign: 'center',
                  }}
                >
                  <div className="headerText">
                    <Title level={2} style={{ color: 'white' }}>{title}</Title>
                    <Title level={4} italic style={{ color: 'white' }}>{`${city}, ${country}`}</Title>
                  </div>
                </div>
                <Alert
                  description="The house manual is shared only with members who have reserved the property."
                  type="info"
                  showIcon
                  style={{ margin: '20px 0' }}
                />
                <Form
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  onFinish={onFinish}

                  initialValues={{
                    ownerContact: houseManual?.ownerContact,
                    wifiName: houseManual?.wifiName,
                    wifiPassword: houseManual?.wifiPassword,
                    keysLocation: houseManual?.keysLocation,
                    emergencyContact: houseManual?.emergencyContact,
                    garbageInfo: houseManual?.garbageInfo,
                    checkInCheckOutProcedure: houseManual?.checkInCheckOutProcedure,
                    localTransporationInfo: houseManual?.localTransporationInfo,
                    localAttractionsAndRecommendations: houseManual?.localAttractionsAndRecommendations,
                    houseRules: houseManual?.houseRules,
                    parkingInfo: houseManual?.parkingInfo,
                    safetyInformation: houseManual?.safetyInformation,
                    miscellaneous: houseManual?.miscellaneous,
                  }}
                >
                  <Divider orientation="left" plain>
                    <PhoneOutlined />
                    {' '}
                    Contacts
                  </Divider>
                  <Form.Item
                    name="ownerContact"
                    label="Owner Contact"
                    rules={[{ message: 'Please input the owner contact!' }]}
                  >
                    <Input prefix={<UserOutlined />} placeholder="Owner Contact" />
                  </Form.Item>
                  <Form.Item
                    name="emergencyContact"
                    label="Emergency Contact"
                  >
                    <Input prefix={<PhoneOutlined />} placeholder="Emergency Contact" />
                  </Form.Item>

                  <Divider orientation="left" plain>
                    <WifiOutlined />
                    {' '}
                    Wi-Fi
                  </Divider>
                  <Form.Item
                    name="wifiName"
                    label="Wi-Fi Name"
                    rules={[{ message: 'Please input the Wi-Fi name!' }]}
                  >
                    <Input prefix={<WifiOutlined />} placeholder="Wi-Fi Name" />
                  </Form.Item>
                  <Form.Item
                    name="wifiPassword"
                    label="Wi-Fi Password"
                    rules={[{ message: 'Please input the Wi-Fi password!' }]}
                  >
                    <Input prefix={<LockOutlined />} placeholder="Wi-Fi Password" />
                  </Form.Item>

                  <Divider orientation="left" plain>
                    <HomeOutlined />
                    {' '}
                    Household and Local Information
                  </Divider>
                  <Form.Item
                    name="keysLocation"
                    label="Keys Location"
                  >
                    <Input prefix={<KeyOutlined />} placeholder="Keys Location" />
                  </Form.Item>

                  <Form.Item
                    name="garbageInfo"
                    label="Garbage Info"
                  >
                    <Input prefix={<EnvironmentOutlined />} placeholder="Garbage Info" />
                  </Form.Item>

                  <Form.Item
                    name="checkInCheckOutProcedure"
                    label="Check-In/Check-Out"
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      maxLength={500}
                      showCount={{ formatter: createCustomFormatter() }}
                      placeholder="Check-In/Check-Out Procedure"
                    />
                  </Form.Item>

                  <Form.Item
                    name="localTransporationInfo"
                    label="Local Transportation Info"
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      maxLength={500}
                      showCount={{ formatter: createCustomFormatter() }}
                      placeholder="Local Transportation Info"
                    />
                  </Form.Item>

                  <Form.Item
                    name="localAttractionsAndRecommendations"
                    label="Local Recommendations"
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      maxLength={500}
                      showCount={{ formatter: createCustomFormatter() }}
                      placeholder="Local Attractions and Recommendations"
                    />
                  </Form.Item>

                  <Form.Item
                    name="houseRules"
                    label="House Rules"
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      maxLength={500}
                      showCount={{ formatter: createCustomFormatter() }}
                      placeholder="House Rules"
                    />
                  </Form.Item>

                  <Form.Item
                    name="parkingInfo"
                    label="Parking Info"
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      maxLength={500}
                      showCount={{ formatter: createCustomFormatter() }}
                      placeholder="Parking Info"
                    />
                  </Form.Item>

                  <Form.Item
                    name="safetyInformation"
                    label="Safety Information"
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      maxLength={500}
                      showCount={{ formatter: createCustomFormatter() }}
                      placeholder="Safety Information"
                    />
                  </Form.Item>
                  <Form.Item
                    name="miscellaneous"
                    label="Other"
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      maxLength={500}
                      showCount={{ formatter: createCustomFormatter() }}
                      placeholder="Anything else that would be useful to know."
                    />
                  </Form.Item>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '16px 24px',
                      borderTop: '1px solid #d9d9d9',
                    }}
                  >
                    <Button onClick={() => navigate(-1)}>
                      Go Back
                    </Button>
                    <Button type="primary" htmlType="submit" loading={isInFlight}>
                      Save
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        );
      }}
    />
  );
}

export default PropertyHouseManualForm;
