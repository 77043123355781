// UserProfile.js
import React from 'react';
import {
  Card, Avatar, Divider, Space, Tooltip, Typography,
} from 'antd';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { QuestionCircleOutlined } from '@ant-design/icons';
import NightCurrency from '../common/NightCurrency';

const { Title } = Typography;

function UserProfile({ userRef }) {
  const user = useFragment(
    graphql`
      fragment UserProfile_user on CustomUserNode {
        id
        username
        nights
        firstName
        lastName
      }
    `,
    userRef,
  );

  return (
    <Card style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Avatar
          size={120}
          src={(
            <Typography.Title style={{ color: 'white' }}>
              {user?.username[0].toUpperCase() ?? 'U'}
            </Typography.Title>
            )}
          style={{ marginBottom: '10px', background: '#364d79' }}
        />
      </div>
      <Title level={4} style={{ textAlign: 'center', marginTop: 0 }}>{user.username.toUpperCase()}</Title>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Typography.Text style={{ width: '100%', textAlign: 'center', marginTop: 0 }}>
          {user.firstName}
          {' '}
          {user.lastName}
        </Typography.Text>
      </div>
      <Divider />
      <Space align="center">
        <Typography.Text>
          <Tooltip
            title="1 Luna equals a 1-night stay at any available property on the platform."
            placement="right"
          >
            <QuestionCircleOutlined style={{ marginRight: '5px' }} />
          </Tooltip>
          Lunas:
        </Typography.Text>
        <NightCurrency amount={user.nights} />
      </Space>
    </Card>
  );
}

export default UserProfile;
