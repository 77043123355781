/**
 * @generated SignedSource<<6eb05cec95e10734b3f8b5bbc173af08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectAmenitiesForm_propertyDraft",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AmenityNodeConnection",
      "kind": "LinkedField",
      "name": "amenities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AmenityNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AmenityNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PropertyDraftNode",
  "abstractKey": null
};

node.hash = "36491482bb65b26d46c62f5e22499893";

module.exports = node;
