import React, { useEffect, useState } from 'react';
import {
  Form, InputNumber, Divider,
} from 'antd';
import graphql from 'babel-plugin-relay/macro';
import dayjs from 'dayjs';
import { createFragmentContainer, useMutation } from 'react-relay';
import utc from 'dayjs-plugin-utc';
import { isDisabledDateByDateRanges } from '../../../../utils/calendarUtils';
import ButtonFooter from './MultiStepButtonFooter';
import AvailabilityInput from '../common/AvailabilityInput';
import AvailabilitiesList from '../common/AvailabilitiesList';

dayjs.extend(utc);

function SelectAvailabilitiesForm({
  propertyDraft, goBack, goNext,
}) {
  const [daysNoticeRequired, setDaysNoticeRequired] = useState(
    propertyDraft.daysNoticeRequired || 1,
  );
  const [dateRanges, setDateRanges] = useState([]);

  const [commitSetDateRange] = useMutation(graphql`
    mutation SelectAvailabilitiesFormMutation($input: SetPropertyAvailabilitiesMutationInput!) {
      setAvailabilities(input: $input) {
        propertyDraft {
          id
          daysNoticeRequired
          availabilities {
            edges{
              node {
                id
                availableFrom
                availableTo

              }
            }
          }
          step
        }
      }
    }
  `);

  useEffect(() => {
    const initialRanges = propertyDraft.availabilities.edges.map((edge) => [
      dayjs.utc(edge.node.availableFrom),
      dayjs.utc(edge.node.availableTo),
    ]);
    setDateRanges(initialRanges);
  }, [propertyDraft]);

  const handleSubmit = () => {
    const availabilitiesInput = dateRanges.map((range) => ({
      availableFrom: range[0].toISOString().split('T')[0],
      availableTo: range[1].toISOString().split('T')[0],
    }));

    commitSetDateRange({
      variables: {
        input: {
          daysNoticeRequired,
          availabilities: availabilitiesInput,
        },
      },
      onCompleted: () => {
        goNext();
      },
      onError: (error) => {
        console.error('Error during mutation:', error);
      },
    });
  };

  const handleDateRangeChange = (index, dates) => {
    if (index === dateRanges.length) {
      setDateRanges([...dateRanges, dates]);
    } else {
      const updatedRanges = dateRanges.map((range, idx) => (idx === index ? dates : range));
      setDateRanges(updatedRanges);
    }
  };

  const handleRemoveDateRange = (index) => {
    setDateRanges(dateRanges.filter((_, idx) => idx !== index));
  };

  const disabledDate = (current) => isDisabledDateByDateRanges(current, dateRanges);

  return (
    <>
      <div style={{ padding: 16 }}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Form.Item tooltip="How many days' notice you'd like before a guest arrives. This is your heads-up time to prepare for their stay." label="Days Notice" required>
            <InputNumber
              min={1}
              max={7}
              value={daysNoticeRequired}
              onChange={(value) => setDaysNoticeRequired(value)}
            />
          </Form.Item>
          <AvailabilityInput
            dateRanges={dateRanges}
            handleDateRangeChange={handleDateRangeChange}
            disabledDate={disabledDate}
          />
        </Form>

        {dateRanges.length > 0 && <Divider />}
        <AvailabilitiesList
          dateRanges={dateRanges}
          handleDateRangeChange={handleDateRangeChange}
          handleRemoveDateRange={handleRemoveDateRange}
        />
      </div>

      <ButtonFooter
        onBack={goBack}
        onNext={handleSubmit}
        nextDisabled={dateRanges.length === 0 || !daysNoticeRequired}
      />
    </>
  );
}

export default createFragmentContainer(SelectAvailabilitiesForm, {
  propertyDraft: graphql`
    fragment SelectAvailabilitiesForm_propertyDraft on PropertyDraftNode {
      daysNoticeRequired
      availabilities {
        edges{
          node {
            id
            availableFrom
            availableTo
          }
        }
      }
    }
  `,
});
