// App.js
import React, { useReducer } from 'react';
import { QueryRenderer, useRelayEnvironment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  Card, Col, Row, Skeleton,
} from 'antd';
import UserProfile from './UserProfile';
import MyPropertyReservations from './MyPropertyReservations';
import MyReservations from './MyReservations';
import MyProperties from './MyProperties';

const UserOverviewQuery = graphql`
query UserOverviewUserQuery {
  me {
    ...UserProfile_user
    ...MyPropertyReservations_user
    ...MyReservations_user
    ...MyProperties_user
  }
}
`;

function UserOverview() {
  const environment = useRelayEnvironment();

  return (
    <QueryRenderer
      environment={environment}
      query={UserOverviewQuery}
      render={({ error, props }) => {
        if (error) {
          throw error;
        }

        if (!props) {
          return (
            <Row>
              <Col span={20} offset={2} style={{ paddingTop: 20 }}>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Card style={{ marginBottom: 16 }}>

                      <Skeleton avatar paragraph={{ rows: 4 }} active />
                    </Card>
                  </Col>
                  <Col span={18}>
                    <Card style={{ marginBottom: 16 }}>
                      <Skeleton paragraph={{ rows: 1 }} active />
                    </Card>
                    <Card style={{ marginBottom: 16 }}>
                      <Skeleton paragraph={{ rows: 5 }} active />
                    </Card>
                    <Card style={{ marginBottom: 16 }}>
                      <Skeleton paragraph={{ rows: 5 }} active />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        }
        return (
          <Row>
            <Col lg={{ span: 20, offset: 2 }} xs={{ span: 24, offset: 0 }} style={{ padding: 16 }}>
              <Row gutter={[16, 16]}>
                <Col md={6} xs={24}>
                  <UserProfile userRef={props.me} />
                </Col>
                <Col md={18} xs={24}>
                  <MyProperties user={props.me} />
                  <MyPropertyReservations user={props.me} />
                  <MyReservations user={props.me} />
                </Col>
              </Row>
            </Col>
          </Row>
        );
      }}
    />
  );
}

export default UserOverview;
