import React, { useState } from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  Typography, List, Divider, Alert,
} from 'antd';
import {
  PhoneOutlined, WifiOutlined, KeyOutlined, EnvironmentOutlined, HomeOutlined, CarOutlined, InfoCircleOutlined, SafetyOutlined, CompassOutlined, ClockCircleOutlined,
} from '@ant-design/icons';
import formatNewlines from 'src/utils/miscUtils';

function HouseManualDetails({ property, preview = false }) {
  const houseManual = property?.housemanual;
  const [expandedItemsIndices, setExpandedItemsIndices] = useState([]);

  if (property.hasHouseManual !== true || houseManual == null) {
    const description = property.isOwner
      ? 'Create a house manual that will be shared with guests when they make a reservation.'
      : 'The owner has not provided a house manual for this property.';

    return (
      <Alert
        style={{ marginBottom: '18px' }}
        message="Manual Missing"
        description={description}
        type="warning"
        showIcon
      />
    );
  }

  const manualDetails = [
    { title: 'Owner Contact', icon: <PhoneOutlined />, content: houseManual?.ownerContact },
    { title: 'Wifi Name', icon: <WifiOutlined />, content: houseManual?.wifiName },
    { title: 'Wifi Password', icon: <WifiOutlined />, content: houseManual?.wifiPassword },
    { title: 'Keys Location', icon: <KeyOutlined />, content: houseManual?.keysLocation },
    { title: 'Emergency Contact', icon: <PhoneOutlined />, content: houseManual?.emergencyContact },
    { title: 'Garbage Info', icon: <EnvironmentOutlined />, content: houseManual?.garbageInfo },
    { title: 'Check-In/Check-Out', icon: <ClockCircleOutlined />, content: houseManual?.checkInCheckOutProcedure },
    { title: 'Local Transportation', icon: <CarOutlined />, content: houseManual?.localTransporationInfo },
    { title: 'Local Recommendations', icon: <CompassOutlined />, content: houseManual?.localAttractionsAndRecommendations },
    { title: 'House Rules', icon: <HomeOutlined />, content: houseManual?.houseRules },
    { title: 'Parking Info', icon: <CarOutlined />, content: houseManual?.parkingInfo },
    { title: 'Safety Information', icon: <SafetyOutlined />, content: houseManual?.safetyInformation },
    { title: 'Other', icon: <InfoCircleOutlined />, content: houseManual?.miscellaneous },
  ];

  const displayItems = manualDetails.filter((item) => item.content);
  if (preview && displayItems.length > 3) {
    displayItems.length = 3;
  }

  const handleExpand = (itemToExpand) => {
    // Determine the index of the item being expanded
    const itemIndex = manualDetails.findIndex((item) => item.title === itemToExpand.title);

    // Check if the item is already expanded
    const isAlreadyExpanded = expandedItemsIndices.includes(itemIndex);

    // Update the state: if the item is already expanded, remove it from the expanded items,
    // otherwise add it to the expanded items
    setExpandedItemsIndices((prevIndices) => (isAlreadyExpanded
      ? prevIndices.filter((index) => index !== itemIndex)
      : [...prevIndices, itemIndex]));
  };

  return (
    <List
      dataSource={displayItems}
      renderItem={(item, index) => item.content && (
        <>
          <Divider orientation="left" plain orientationMargin={0} style={{ marginBottom: 5 }}>
            <Typography.Text strong>
              {item.icon}
              {' '}
              {item.title}
            </Typography.Text>
          </Divider>
          <Typography.Paragraph
            ellipsis={{
              rows: 2,
              expandable: !preview,
              onExpand: () => handleExpand(item),
            }}
            style={{ paddingLeft: 20 }}
          >
            {item.content}
            {expandedItemsIndices.includes(index) ? formatNewlines(item.content) : item.content}
          </Typography.Paragraph>
          {index === 2 && preview && (
            <div style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '40px',
              background: 'linear-gradient(to bottom,  rgba(255,255,255,0.0) 0%,rgba(255,255,255,1) 100%)',
            }}
            />
          )}
        </>
      )}
    />
  );
}

export default createFragmentContainer(HouseManualDetails, {
  property: graphql`
    fragment HouseManualDetails_property on PropertyNode {
      isOwner
      hasHouseManual
      housemanual {
        ownerContact
        wifiName
        wifiPassword
        keysLocation
        emergencyContact
        garbageInfo
        checkInCheckOutProcedure
        localTransporationInfo
        localAttractionsAndRecommendations
        houseRules
        parkingInfo
        safetyInformation
        miscellaneous
      }
    }
  `,
});
