/**
 * @generated SignedSource<<3d6f7b50bd6d734fd488560b6ed77916>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyInlineTitleForm_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "PropertyNode",
  "abstractKey": null
};

node.hash = "6ee80952bf2fdc6c6a9b249e389b6e75";

module.exports = node;
