import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

function PropertyCreatedSuccessScreen({ propertyDraft }) {
  const navigate = useNavigate();

  const handleViewListing = () => {
    navigate(`/properties/${propertyDraft.property.id}/edit`);
  };

  return (
    <Result
      status="success"
      title="Listing Complete"
      subTitle="Your listing has been successfully created - see what it looks like!"
      extra={[
        <Button type="primary" key="view-listing" onClick={handleViewListing}>
          View Listing
        </Button>,
      ]}
    />
  );
}

export default createFragmentContainer(PropertyCreatedSuccessScreen, {
  propertyDraft: graphql`
    fragment PropertyCreatedSuccessScreen_propertyDraft on PropertyDraftNode {
      property {
        id
      }
    }
  `,
});
