/**
 * @generated SignedSource<<26c2efc8c3afe758dd2384e7b36b5e31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isReferred",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalReferrals",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserReferralsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserReferralsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c8c74aedae0a5152939f4dd4559591c8",
    "id": null,
    "metadata": {},
    "name": "UserReferralsPageQuery",
    "operationKind": "query",
    "text": "query UserReferralsPageQuery {\n  me {\n    username\n    isReferred\n    totalReferrals\n    id\n  }\n}\n"
  }
};
})();

node.hash = "a94ba15f2a498530f816c79e3a03a3ce";

module.exports = node;
