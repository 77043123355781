import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {
  Button,
  Card,
  Col, Divider, Row, Typography,
} from 'antd';
import { createFragmentContainer } from 'react-relay';
import { getHouseManualEditUrl } from 'src/utils/urlUtils';
import HouseManualDetails from './HouseManualDetails';

function PropertyHouseManualCard({ property }) {
  return (
    <Card bordered={false}>
      <Row>
        <Typography.Title level={4} style={{ marginTop: 0 }}>House Manual</Typography.Title>
      </Row>
      <Row>
        <HouseManualDetails property={property} preview />
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <Row style={{ marginTop: '16px' }}>
        <Col span={24}>
          <Button
            block
            type="primary"
            href={getHouseManualEditUrl(property.id)}
          >
            {property.hasHouseManual === true ? 'Edit House Manual' : 'Create House Manual'}
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

export default createFragmentContainer(PropertyHouseManualCard, {
  property: graphql`
    fragment PropertyHouseManualCard_property on PropertyNode {
      id
      hasHouseManual
      ...HouseManualDetails_property
    }
  `,
});
