import React, { useState } from 'react';
import { QueryRenderer, useRelayEnvironment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  Col, Row, Skeleton, Steps,
} from 'antd';
import Title from 'antd/es/typography/Title';
import SelectPropertyTypeForm from './SelectPropertyTypeForm';
import SelectLocationForm from './SelectLocationForm';
import ConfirmPinForm from './ConfirmPinForm';
import ConfigureDetailsForm from './ConfigureDetailsForm';
import SelectAmenitiesForm from './SelectAmenitiesForm';
import UploadPhotosForm from './UploadPhotosForm';
import ProvideTitleForm from './ProvideTitleForm';
import ProvideDescriptionForm from './ProvideDescriptionForm';
import SelectAvailabilitiesForm from './SelectAvailabilitiesForm';
import FinalReviewForm from './FinalReviewForm';
import PropertyCreatedSuccessScreen from './PropertyCreatedSuccessScreen';

const { Step } = Steps;

const DraftPropertyMultiStepFormQuery = graphql`
query DraftPropertyMultiStepFormQuery {
  me {
    propertydraft {
      step
      ...SelectPropertyTypeForm_propertyDraft
      ...SelectLocationForm_propertyDraft
      ...ConfirmPinForm_propertyDraft
      ...ConfigureDetailsForm_propertyDraft
      ...SelectAmenitiesForm_propertyDraft
      ...UploadPhotosForm_propertyDraft
      ...ProvideTitleForm_propertyDraft
      ...ProvideDescriptionForm_propertyDraft
      ...SelectAvailabilitiesForm_propertyDraft
      ...FinalReviewForm_propertyDraft
      ...PropertyCreatedSuccessScreen_propertyDraft
    }
  }
  propertyTypes {
    ...SelectPropertyTypeForm_propertyTypes
  }
  amenities {
    ...SelectAmenitiesForm_amenities
  }
}
`;

function DraftPropertyMultiStepForm() {
  const environment = useRelayEnvironment();

  // Add a new state variable to manage the current step locally
  const [localStep, setLocalStep] = useState(null);

  const steps = [
    'SELECT_TYPE',
    'SELECT_LOCATION',
    'CONFIRM_PIN',
    'CONFIGURE_DETAILS',
    'SELECT_AMENITIES',
    'UPLOAD_PHOTOS',
    'PROVIDE_TITLE',
    'PROVIDE_DESCRIPTION',
    'SELECT_AVAILABILITY',
    'FINAL_REVIEW',
    'COMPLETED',
  ];

  const stepGroups = [
    {
      title: 'Lay the Foundation',
      steps: ['SELECT_TYPE', 'SELECT_LOCATION', 'CONFIRM_PIN', 'CONFIGURE_DETAILS'],
    },
    {
      title: 'Make it Stand Out',
      steps: ['SELECT_AMENITIES', 'UPLOAD_PHOTOS'],
    },
    {
      title: 'Tell Your Story',
      steps: ['PROVIDE_TITLE', 'PROVIDE_DESCRIPTION'],
    },
    {
      title: 'Set the Stage',
      steps: ['SELECT_AVAILABILITY', 'FINAL_REVIEW'],
    },
  ];

  const stepTitles = {
    SELECT_TYPE: 'Select Property Type',
    SELECT_LOCATION: 'Select Location',
    CONFIRM_PIN: 'Confirm Pin',
    CONFIGURE_DETAILS: 'Configure Details',
    SELECT_AMENITIES: 'Select Amenities',
    UPLOAD_PHOTOS: 'Upload Photos',
    PROVIDE_TITLE: 'Provide Title',
    PROVIDE_DESCRIPTION: 'Provide Description',
    SELECT_AVAILABILITY: 'Select Availability',
    FINAL_REVIEW: 'Final Review',
    COMPLETED: null,
  };

  const renderForm = (props, goBack, goNext, step) => {
    switch (step) {
      case 'SELECT_TYPE':
        return (
          <SelectPropertyTypeForm
            propertyDraft={props.me.propertydraft}
            propertyTypes={props.propertyTypes}
            goBack={goBack}
            goNext={goNext}
          />
        );
      case 'SELECT_LOCATION':
        return (
          <SelectLocationForm
            propertyDraft={props.me.propertydraft}
            goBack={goBack}
            goNext={goNext}
          />
        );
      case 'CONFIRM_PIN':
        return (
          <ConfirmPinForm
            propertyDraft={props.me.propertydraft}
            goBack={goBack}
            goNext={goNext}
          />
        );
      case 'CONFIGURE_DETAILS':
        return (
          <ConfigureDetailsForm
            propertyDraft={props.me.propertydraft}
            goBack={goBack}
            goNext={goNext}
          />
        );
      case 'SELECT_AMENITIES':
        return (
          <SelectAmenitiesForm
            propertyDraft={props.me.propertydraft}
            amenities={props.amenities}
            goBack={goBack}
            goNext={goNext}
          />
        );
      case 'UPLOAD_PHOTOS':
        return (
          <UploadPhotosForm
            propertyDraft={props.me.propertydraft}
            goBack={goBack}
            goNext={goNext}
          />
        );
      case 'PROVIDE_TITLE':
        return (
          <ProvideTitleForm
            propertyDraft={props.me.propertydraft}
            goBack={goBack}
            goNext={goNext}
          />
        );
      case 'PROVIDE_DESCRIPTION':
        return (
          <ProvideDescriptionForm
            propertyDraft={props.me.propertydraft}
            goBack={goBack}
            goNext={goNext}
          />
        );
      case 'SELECT_AVAILABILITY':
        return (
          <SelectAvailabilitiesForm
            propertyDraft={props.me.propertydraft}
            goBack={goBack}
            goNext={goNext}
          />
        );
      case 'FINAL_REVIEW':
        return (
          <FinalReviewForm
            propertyDraft={props.me.propertydraft}
            goBack={goBack}
            goNext={goNext}
          />
        );
      case 'COMPLETED':
        return (
          <PropertyCreatedSuccessScreen
            propertyDraft={props.me.propertydraft}
          />
        );
      default:
        return null;
    }
  };

  return (
    <QueryRenderer
      environment={environment}
      query={DraftPropertyMultiStepFormQuery}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          throw error;
        }

        if (!props) {
          return (
            <div style={{
              minHeight: 'calc(100vh)', height: 'calc(100vh)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
            }}
            >
              <Row style={{
                flexGrow: 1, overflowY: 'auto', width: '100%', display: 'flex', alignItems: 'center',
              }}
              >
                <Col span={12} offset={6}>
                  <Skeleton active />
                </Col>
              </Row>
            </div>
          );
        }
        const step = localStep || props.me.propertydraft.step;

        const goBack = () => {
          const currentStepIndex = steps.indexOf(step);
          if (currentStepIndex > 0) {
            setLocalStep(steps[currentStepIndex - 1]);
          }
        };

        const goNext = () => {
          const currentStepIndex = steps.indexOf(step);
          if (currentStepIndex < steps.length) {
            setLocalStep(steps[currentStepIndex + 1]);
          }
        };

        const currentStepIndex = steps.indexOf(step);
        const isLastStep = currentStepIndex === steps.length - 1;

        const currentGroupIndex = isLastStep
          ? stepGroups.length - 1
          : stepGroups.findIndex((group) => group.steps.includes(step));

        const groupPercentage = isLastStep
          ? 100
          : Math.ceil(
            (((currentStepIndex) % (stepGroups[currentGroupIndex].steps.length))
              / stepGroups[currentGroupIndex].steps.length)
            * 100,
          );

        return (
          <div style={{
            minHeight: 'calc(100vh)', height: 'calc(100vh)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
          }}
          >
            <Row style={{
              flexGrow: 1, overflowY: 'auto', width: '100%', display: 'flex', alignItems: 'center',
            }}
            >
              <Col xs={{ span: 24 }} sm={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
                {stepTitles[step] && <Title level={2} style={{ textAlign: 'center' }}>{stepTitles[step]}</Title>}
                {renderForm(props, goBack, goNext, step)}
              </Col>
            </Row>
            {!isLastStep && (
              <Row style={{ width: '100%', backgroundColor: 'white', borderTop: '1px solid #d9d9d9' }}>
                <Col span={22} offset={1} style={{ padding: '16px' }}>
                  <Steps current={currentGroupIndex} percent={groupPercentage}>
                    {stepGroups.map((group, index) => (
                      <Step key={index} title={group.title} />
                    ))}
                  </Steps>
                </Col>
              </Row>
            )}
          </div>
        );
      }}
    />
  );
}

export default DraftPropertyMultiStepForm;
