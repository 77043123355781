import React, { useState } from 'react';
import {
  Typography, message, Button, Alert,
} from 'antd';
import { createFragmentContainer, useMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import PropertyImagesMasonry from '../../PropertyImagesMasonry';
import ImageUploadReorder from '../common/ImageUploadReorder';

const updatePropertyMutation = graphql`
  mutation PropertyInlineImagesFormMutation($input: UpdatePropertyMutationInput!) {
    updateProperty(input: $input) {
      property {
        id
        orderedImages {
          edges {
            node {
              id
              url
            }
          }
        }
      }
    }
  }
`;

function PropertyInlineImagesForm({ property }) {
  const [commitMutation] = useMutation(updatePropertyMutation);
  const [editMode, setEditMode] = useState(false);
  const [fileList, setFileList] = useState([]);

  const onFinish = async () => {
    const imageUrls = fileList
      .filter((file) => file.status === 'done')
      .map((file) => file.url);
    commitMutation({
      variables: {
        input: {
          id: property.id,
          imageUrls,
        },
      },
      onCompleted: (response, errors) => {
        setEditMode(false);
        if (errors && errors.length > 0) {
          const errMessage = errors[0].message || 'An error occurred while saving the images.';
          message.error(errMessage);
        } else {
          message.success('Images saved successfully!');
        }
      },
      onError: () => {
        message.error('Error updating images. Please try again.');
      },
    });
  };

  const handleEditButtonClick = () => {
    setEditMode(true);
  };

  const handleImagesChange = (newFileList) => {
    setFileList(newFileList);
  };

  const initialImages = property.orderedImages.edges.map(({ node }) => ({
    id: node.id,
    url: node.url,
  }));

  return (
    property && (
      editMode ? (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '20px',
          background: '#f0f2f5',
          borderRadius: '10px',
          minHeight: '420px',
        }}
        >
          <div>
            <Alert
              message="You can reorder the images by dragging and dropping. Make sure to have a minimum of 5 images."
              type="info"
              showIcon
              style={{ marginBottom: '20px' }}
            />
            <ImageUploadReorder
              initialImages={initialImages}
              onImagesChange={handleImagesChange}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button style={{ marginRight: '10px' }} onClick={() => setEditMode(false)}>
              Cancel
            </Button>
            <Button type="primary" onClick={onFinish} disabled={fileList == null || fileList.length < 5}>
              Save
            </Button>
          </div>
        </div>
      ) : (
        <PropertyImagesMasonry
          property={property}
          showEditButton
          onEditButtonClick={handleEditButtonClick}
        />
      )

    )
  );
}

export default createFragmentContainer(PropertyInlineImagesForm, {
  property: graphql`
    fragment PropertyInlineImagesForm_property on PropertyNode {
      id
      orderedImages {
        edges {
          node {
            id
            url
          }
        }
      }
      ...PropertyImagesMasonry_property
    }
  `,
});
