import React from 'react';
import { Typography, message } from 'antd';
import { useFragment, useMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

const updatePropertyMutation = graphql`
  mutation PropertyInlineTitleFormMutation($input: UpdatePropertyMutationInput!) {
    updateProperty(input: $input) {
      property {
        id
        title
        ...PropertyInlineTitleForm_property
      }
    }
  }
`;

function PropertyInlineTitleForm({ propertyRef }) {
  const property = useFragment(
    graphql`
      fragment PropertyInlineTitleForm_property on PropertyNode {
        id
        title
      }
    `,
    propertyRef,
  );

  const [commitMutation] = useMutation(updatePropertyMutation);

  const handleSave = (newTitle) => {
    if (newTitle === property.title) {
      return;
    }

    commitMutation({
      variables: {
        input: {
          id: property.id,
          title: newTitle,
        },
      },
      onCompleted: (response, errors) => {
        if (errors && errors.length > 0) {
          const errMessage = errors[0].message || 'An error occurred while editing the title.';
          message.error(errMessage);
        } else {
          message.success('Title updated successfully!');
        }
      },
      onError: () => {
        message.error('Error updating title. Please try again.');
      },
      optimisticResponse: {
        updateProperty: {
          property: {
            id: property.id,
            title: newTitle,
          },
        },
      },
    });
  };

  return (
    property && (
      <Typography.Title
        level={3}
        editable={{
          maxLength: 50,
          onChange: handleSave,
        }}
        style={{ margin: 0, display: 'block', width: '100%' }}
      >
        {property.title}
      </Typography.Title>
    )
  );
}

export default PropertyInlineTitleForm;
