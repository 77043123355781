import React, { useState } from 'react';
import {
  Col, Row, Button, Modal, Space, Image,
} from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { EditOutlined, PictureOutlined } from '@ant-design/icons';
import PreviewGroup from 'antd/es/image/PreviewGroup';

function PropertyImagesMasonry({ property, showEditButton = false, onEditButtonClick = () => {} }) {
  const [visible, setVisible] = useState(false);
  const images = property?.orderedImages?.edges?.map((edge) => edge.node);

  const showAllImages = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handleEditButtonClick = () => {
    onEditButtonClick();
  };

  return (
    <div style={{ position: 'relative' }}>
      <Row gutter={[8, 8]}>
        <PreviewGroup>
          <Col md={12} xs={24}>
            <div
              style={{
                width: '100%',
                height: '412px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              }}
            >
              <Image
                src={images[0]?.url}
                alt=""
                style={{
                  minHeight: '412px',
                  height: '100%',
                  objectFit: 'cover',
                }}
                groupKey="gallery"
              />
            </div>
          </Col>
          <Col md={12} xs={24}>
            <Row gutter={[8, 8]}>
              {images.slice(1, 5).map((image, index) => (
                <Col span={12} key={image.id}>
                  <div
                    style={{
                      width: '100%',
                      height: '200px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                      borderTopRightRadius: index === 1 ? '10px' : undefined,
                      borderBottomRightRadius: index === 3 ? '10px' : undefined,
                    }}
                  >
                    <Image
                      src={image.url}
                      alt=""
                      groupKey="gallery" // All images that are related should share the same groupKey
                      style={{
                        minHeight: '200px',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </PreviewGroup>
      </Row>
      <Space
        style={{ position: 'absolute', bottom: '20px', right: '20px' }}
        direction="vertical"
      >
        {showEditButton && (
        <Button
          icon={<EditOutlined />}
          type="primary"
          onClick={handleEditButtonClick}
        >
          Edit Images
        </Button>
        )}
        <Button
          icon={<PictureOutlined />}
          onClick={showAllImages}
        >
          Show all
        </Button>
      </Space>
      <Modal
        title="All Images"
        open={visible}
        onCancel={closeModal}
        footer={null}
        width="90%"
      >
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
        >
          <PreviewGroup>
            <Masonry gutter="5px">
              {images.map((image) => (
                <Image
                  key={image.id}
                  src={image.url}
                  alt=""
                  style={{
                    width: '100%',
                    display: 'block',
                    marginBottom: '5px',
                  }}
                />
              ))}
            </Masonry>
          </PreviewGroup>
        </ResponsiveMasonry>
      </Modal>
    </div>
  );
}

export default createFragmentContainer(PropertyImagesMasonry, {
  property: graphql`
    fragment PropertyImagesMasonry_property on PropertyNode {
      id
      orderedImages {
        edges {
          node {
            id
            url
          }
        }
      }
    }
  `,
});
