import React from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

function MultiStepButtonFooter({
  onBack,
  onNext,
  nextDisabled,
  backButtonHidden = false,
  nextButtonHidden = false,
  nextButtonText = 'Next',
}) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
        borderTop: '1px solid #d9d9d9',
      }}
    >
      {backButtonHidden === false && (
        <Button onClick={onBack} icon={<ArrowLeftOutlined />}>
          Back
        </Button>
      )}
      <div style={{ flexGrow: 1 }} />
      {nextButtonHidden === false && (
        <Button
          type="primary"
          onClick={onNext}
          disabled={nextDisabled}
          icon={<ArrowRightOutlined />}
        >
          {nextButtonText}
        </Button>
      )}
    </div>
  );
}

export default MultiStepButtonFooter;
