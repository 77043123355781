import React from 'react';
import {
  mdiPool,
  mdiDumbbell,
  mdiAirConditioner,
  mdiElevator,
  mdiFireplace,
  mdiGrill,
  mdiHotTub,
  mdiParking,
  mdiRadiator,
  mdiTelevision,
  mdiWashingMachine,
  mdiWheelchairAccessibility,
  mdiWifi,
  mdiBalcony,
  mdiBabyBuggy,
  mdiBell,
  mdiBike,
  mdiBus,
  mdiDesk,
  mdiDogSide,
  mdiDoor,
  mdiDoorClosed,
  mdiFlower,
  mdiGamepadVariant,
  mdiGarage,
  mdiShieldCheck,
  mdiStove,
  mdiSpa,
  // ... other imports for remaining amenities
} from '@mdi/js';
import Icon from '@mdi/react';

const iconMap = {
  'Air conditioning': mdiAirConditioner,
  Heating: mdiRadiator,
  'Free Wi-Fi': mdiWifi,
  'Smart TV': mdiTelevision,
  'Fully equipped kitchen': mdiStove,
  'Washer and dryer': mdiWashingMachine,
  'Balcony or patio': mdiDoorClosed,
  'Private entrance': mdiDoor,
  'Parking on premises': mdiParking,
  Garage: mdiGarage,
  Elevator: mdiElevator,
  'Wheelchair accessible': mdiWheelchairAccessibility,
  Pool: mdiPool,
  'Hot tub': mdiHotTub,
  Gym: mdiDumbbell,
  Sauna: mdiSpa,
  'Game room': mdiGamepadVariant,
  'Rooftop terrace': mdiBalcony,
  'Garden or backyard': mdiFlower,
  'Barbecue grill': mdiGrill,
  Fireplace: mdiFireplace,
  'Pet-friendly': mdiDogSide,
  'Child-friendly': mdiBabyBuggy,
  Workspace: mdiDesk,
  '24-hour security': mdiShieldCheck,
  'Concierge service': mdiBell,
  'Nearby public transportation': mdiBus,
  'Bike storage': mdiBike,
};

function AmenityIcon({ name, size = 1.1 }) {
  const iconPath = iconMap[name];

  if (!iconPath) {
    return null;
  }

  return (
    <Icon path={iconPath} size={size} />
  );
}

export default AmenityIcon;
