// UserAvatarGroup.js
import React from 'react';
import { Avatar, Tooltip } from 'antd';

const COLORS = ['#f56a00', '#1890ff', '#fa541c', '#fa8c16', '#13c2c2', '#722ed1'];
const MAX_AVATARS = 5; // Change this value based on your preferred cap

function UserAvatarGroup({ users }) {
  return (
    <Avatar.Group maxCount={MAX_AVATARS} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
      {users.map((user, index) => (
        <Tooltip title={`${user.firstName} ${user.lastName}`} key={index}>
          <Avatar style={{ backgroundColor: COLORS[index % COLORS.length] }}>
            {user.firstName.charAt(0)}
            {user.lastName.charAt(0)}
          </Avatar>
        </Tooltip>
      ))}
    </Avatar.Group>
  );
}

export default UserAvatarGroup;
