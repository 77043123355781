import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Form, Input, message, Skeleton,
} from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer, useMutation } from 'react-relay';
import {
  GoogleMap, MarkerF, StandaloneSearchBox, useJsApiLoader,
} from '@react-google-maps/api';
import { mapOptions } from 'src/utils/googleMapUtils';
import ButtonFooter from './MultiStepButtonFooter';

const extractAddressComponents = (place) => {
  let streetAddress = '';
  let city = '';
  let country = '';
  let postalCode = '';

  place.address_components.forEach((component) => {
    const componentType = component.types[0];

    switch (componentType) {
      case 'street_number':
        streetAddress = `${component.long_name} ${streetAddress}`;
        break;
      case 'route':
        streetAddress += component.long_name;
        break;
      case 'locality':
        city = component.long_name;
        break;
      case 'postal_town':
        if (!city) {
          city = component.long_name;
        }
        break;
      case 'country':
        country = component.long_name;
        break;
      case 'postal_code':
        postalCode = component.long_name;
        break;
      default:
        break;
    }
  });

  return {
    streetAddress, city, country, postalCode,
  };
};

function SelectLocationForm({ propertyDraft, goBack, goNext }) {
  const [searchValue, setSearchValue] = useState('');
  const [initialSearchPerformed, setInitialSearchPerformed] = useState(
    Boolean(propertyDraft.latitude || propertyDraft.longitude),
  );

  const [location, setLocation] = useState({
    latitude: parseFloat(propertyDraft.latitude) || 0,
    longitude: parseFloat(propertyDraft.longitude) || 0,
    city: propertyDraft.city,
    country: propertyDraft.country,
    street_address: propertyDraft.streetAddress,
    postal_code: propertyDraft.postalCode,
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA25x-2K58jI2YByiq72l4ikwAcypprtus',
    libraries: ['places'],
    language: 'en',
  });

  const searchBoxRef = useRef(null);
  const mapRef = useRef(null);

  const isFormValid = () => location.city
    && location.country && location.street_address && location.latitude && location.longitude;

  const onPlacesChanged = useCallback(() => {
    const places = searchBoxRef.current.getPlaces();
    const place = places[0];

    if (!place.geometry) return;

    const {
      streetAddress, city, country, postalCode,
    } = extractAddressComponents(place);

    const newLocation = {
      ...location,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      street_address: streetAddress,
      city,
      country,
      postal_code: postalCode,
    };

    setLocation(newLocation);
    setSearchValue(place.formatted_address);
    setInitialSearchPerformed(true);
  }, [location]);

  const onMapClick = (e) => {
    setLocation({
      ...location,
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
  };

  // useEffect(() => {
  //   if (mapRef.current && location.latitude && location.longitude && initialSearchPerformed) {
  //     setTimeout(() => {
  //       mapRef.current.panTo({ lat: location.latitude, lng: location.longitude });
  //     }, 1000); // Delay of 1 second
  //   }
  // }, [location, initialSearchPerformed]);

  const [commitSetLocation] = useMutation(graphql`
    mutation SelectLocationFormMutation($input: SetLocationMutationInput!) {
      setLocation(input: $input) {
        propertyDraft {
          latitude
          longitude
          city
          country
          streetAddress
          postalCode
          step
        }
      }
    }
  `);

  const handleSubmit = () => {
    commitSetLocation({
      variables: {
        input: {
          latitude: location.latitude,
          longitude: location.longitude,
          city: location.city,
          country: location.country,
          streetAddress: location.street_address,
          postalCode: location.postal_code,
        },
      },
      onCompleted: () => {
        goNext();
      },
      onError: (error) => {
        message.error('Something went wrong.');
      },
    });
  };

  return isLoaded ? (
    <>
      <div style={{ padding: 16 }}>
        <StandaloneSearchBox
          onLoad={(ref) => {
            searchBoxRef.current = ref;
          }}
          onPlacesChanged={onPlacesChanged}
        >
          <Input.Search
            value={searchValue}
            size="large"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search for a location"
            style={{
              marginBottom: '16px',
            }}
          />
        </StandaloneSearchBox>
        {initialSearchPerformed && (
        <GoogleMap
          ref={mapRef}
          mapContainerStyle={{
            width: '100%',
            height: '250px',
            marginBottom: '16px',
          }}
          center={{
            lat: location.latitude,
            lng: location.longitude,
          }}
          onClick={onMapClick}
          onLoad={(map) => {
            mapRef.current = map;
          }}
          zoom={15}
          options={mapOptions}
        >
          {location.latitude && location.longitude && (
            <MarkerF position={{ lat: location.latitude, lng: location.longitude }} />
          )}
        </GoogleMap>
        )}
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          size="large"
          onFinish={handleSubmit}
          requiredMark={false}
        >
          {initialSearchPerformed && (
          <>
            <Form.Item required label="Street Address">
              <Input
                value={location.street_address}
                onChange={(e) => setLocation({ ...location, street_address: e.target.value })}
              />
            </Form.Item>
            <Form.Item required label="City">
              <Input
                value={location.city}
                onChange={(e) => setLocation({ ...location, city: e.target.value })}
              />
            </Form.Item>
            <Form.Item required label="Country">
              <Input
                value={location.country}
                onChange={(e) => setLocation({ ...location, country: e.target.value })}
              />
            </Form.Item>
            <Form.Item label="Postal Code">
              <Input
                value={location.postal_code}
                onChange={(e) => setLocation({ ...location, postal_code: e.target.value })}
              />
            </Form.Item>
          </>
          )}
        </Form>
      </div>
      <ButtonFooter
        onBack={goBack}
        onNext={handleSubmit}
        nextDisabled={!isFormValid()}
      />
    </>
  ) : <Skeleton active />;
}

export default createFragmentContainer(SelectLocationForm, {
  propertyDraft: graphql`
    fragment SelectLocationForm_propertyDraft on PropertyDraftNode {
      latitude
      longitude
      city
      country
      streetAddress
      postalCode
    }
  `,
});
