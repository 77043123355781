import React from 'react';
import { useMutation } from 'react-relay/hooks';
import graphql from 'babel-plugin-relay/macro';
import {
  Form, Input, Button, message, Typography, Card,
} from 'antd';
import { MailOutlined } from '@ant-design/icons';

const { Title } = Typography;

const SendPasswordResetEmailMutation = graphql`
  mutation PasswordResetPageMutation($input: SendPasswordResetEmailInput!) {
    sendPasswordResetEmail(input: $input) {
      success
    }
  }
`;

function PasswordResetPage() {
  const [commit, isInFlight] = useMutation(SendPasswordResetEmailMutation);

  const onFinish = ({ email }) => {
    commit({
      variables: { input: { email: email.toLowerCase() } },
      onCompleted: (data) => {
        if (data.sendPasswordResetEmail.success) {
          message.success('Reset password email sent successfully!');
        } else {
          message.error('Something went wrong!');
        }
      },
      onError: (err) => {
        message.error('Error encountered!');
      },
    });
  };

  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh',
    }}
    >
      <Card style={{ width: '400px' }}>
        <Title style={{ textAlign: 'center', marginTop: 0 }}>Reset Password</Title>
        <Form onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0 }}
          >
            <Button type="primary" htmlType="submit" loading={isInFlight}>Reset Password</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default PasswordResetPage;
