import React from 'react';
import {
  Card, Col, Empty, Row, Space, Typography,
} from 'antd';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import ReservationList from './ReservationList';

const { Title, Text } = Typography;

function NoReservations({ title }) {
  return (
    <Row justify="center" align="middle" style={{ minHeight: '130px' }}>
      <Col>
        <Space direction="vertical" size="middle">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
              height: 60,
            }}
            description={<Text type="secondary">{title}</Text>}
          />
        </Space>
      </Col>
    </Row>
  );
}

function MyReservations({ user }) {
  const upcomingReservations = user.reservations_UPCOMING.edges.map((edge) => edge.node) || null;
  const pastReservations = user.reservations_PAST.edges.map((edge) => edge.node) || null;
  const cancelledReservations = user.reservations_CANCELLED.edges.map((edge) => edge.node) || null;

  return (
    <Card style={{ width: '100%', marginTop: 20 }} bodyStyle={{ paddingBottom: '8px' }}>
      <Title level={3} style={{ marginTop: 0 }}>Where you are going</Title>
      {upcomingReservations != null && upcomingReservations.length > 0 ? (
        <ReservationList
          reservations={upcomingReservations}
        />
      ) : (
        <NoReservations title="No upcoming trips" />
      )}
      <Title level={3} style={{ marginTop: 0 }}>Where you have been</Title>
      {pastReservations != null && pastReservations.length > 0 ? (
        <ReservationList reservations={pastReservations} />
      ) : (
        <NoReservations title="No past trips" />
      )}
      <Title level={3} style={{ marginTop: 0 }}>Cancelled trips</Title>
      {cancelledReservations != null && cancelledReservations.length > 0 ? (
        <ReservationList reservations={cancelledReservations} />
      ) : (
        <NoReservations title="No cancelled trips" />
      )}
    </Card>
  );
}

export default createFragmentContainer(MyReservations, {
  user: graphql`
    fragment MyReservations_user on CustomUserNode {
      reservations_UPCOMING: reservationsMade(first: 10, statusFilter: UPCOMING) {
        edges {
          node {
            ...ReservationList_reservations
          }
        }
      }
      reservations_PAST: reservationsMade(first: 10, statusFilter: PAST) {
        edges {
          node {
            ...ReservationList_reservations
          }
        }
      }
      reservations_CANCELLED: reservationsMade(first: 10, statusFilter: CANCELLED) {
        edges {
          node {
            ...ReservationList_reservations
          }
        }
      }
    }
  `,
});
