/**
 * @generated SignedSource<<d8b307772f13e6ae06332146d450b9a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "step",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomUserNode",
  "kind": "LinkedField",
  "name": "me",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCompletedPropertySetup",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FinalReviewFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteDraftMutationPayload",
        "kind": "LinkedField",
        "name": "completeDraft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyDraftNode",
            "kind": "LinkedField",
            "name": "propertyDraft",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PropertyCreatedSuccessScreen_propertyDraft"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FinalReviewFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteDraftMutationPayload",
        "kind": "LinkedField",
        "name": "completeDraft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyDraftNode",
            "kind": "LinkedField",
            "name": "propertyDraft",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyNode",
                "kind": "LinkedField",
                "name": "property",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5d8912bd5535eebd54f9cd4fa298ff7c",
    "id": null,
    "metadata": {},
    "name": "FinalReviewFormMutation",
    "operationKind": "mutation",
    "text": "mutation FinalReviewFormMutation(\n  $input: CompleteDraftMutationInput!\n) {\n  completeDraft(input: $input) {\n    propertyDraft {\n      id\n      step\n      ...PropertyCreatedSuccessScreen_propertyDraft\n    }\n    me {\n      id\n      hasCompletedPropertySetup\n    }\n  }\n}\n\nfragment PropertyCreatedSuccessScreen_propertyDraft on PropertyDraftNode {\n  property {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "a7aae1291ad1d89fa9f77a3b7201e368";

module.exports = node;
