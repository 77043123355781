// ReservationCancelButton.js
import React from 'react';
import { commitLocalUpdate, useMutation, useRelayEnvironment } from 'react-relay';
import {
  Button, message, Popconfirm, Typography,
} from 'antd';
import graphql from 'babel-plugin-relay/macro';
import NightCurrency from '../common/NightCurrency';

const ReservationCancelMutation = graphql`
  mutation ReservationCancelButtonMutation($input: CancelReservationMutationInput!) {
    cancelReservation(input: $input) {
      reservation {
        id
        status
        ...ReservationItem_reservation
      }
      me {
        id
        ...UserProfile_user
      }
    }
  }
`;

function ReservationCancelButton({
  reservation, onCancellation, type = 'text', buttonText = 'Cancel', block = false,
  isLateCancellation = false, isViewerGuest = false,
}) {
  const [commitReservationCancelMutation] = useMutation(ReservationCancelMutation);
  const [messageApi, contextHolder] = message.useMessage();

  const handleCancel = () => {
    messageApi.open({
      type: 'loading',
      content: 'Cancelling reservation..',
      duration: 0,
    });
    commitReservationCancelMutation({
      variables: {
        input: {
          reservationId: reservation.id,
        },
      },
      onCompleted: (response, errors) => {
        messageApi.destroy();
        if (errors) {
          message.error('Error occured during the cancellation of the reservation.');
        } else {
          onCancellation();
        }
      },
      onError: (error) => {
        messageApi.destroy();
        message.error('Error occured during the cancellation of the reservation.');
      },
    });
  };

  return (
    <Popconfirm
      title="Confirm Reservation Cancellation"
      description={(
        <div style={{ maxWidth: '400px' }}>
          Cancelling the reservation will
          {isViewerGuest
            ? ' return any spent Lunas to you, '
            : ' refund any spent Lunas to the guest, '}
          {isLateCancellation
            ? (
              <>
                and deduct a flat
                <NightCurrency amount={2} />
                {' '}
                cancellation fee.
              </>
            )
            : ' without any penalty.'}
        </div>
        )}
      onConfirm={handleCancel}
      okText="Confirm Cancellation"
      cancelText="Keep Reservation"
    >
      <Button danger type={type} block={block}>{buttonText}</Button>
    </Popconfirm>
  );
}

export default ReservationCancelButton;
