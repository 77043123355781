const formatNewlines = (text) => {
  if (text == null) {
    return null;
  }

  return text.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
};

export default formatNewlines;
