import React from 'react';
import { Card, Typography } from 'antd';

function CustomSelectCard({
  id,
  name,
  selected,
  onSelect,
  onDeselect,
  icon = null,
}) {
  const handleClick = () => {
    if (selected) {
      onDeselect(id);
    } else {
      onSelect(id);
    }
  };

  const cardStyle = {
    borderColor: selected ? 'dodgerblue' : 'lightgrey',
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: selected ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
    transform: selected ? 'scale(1.025)' : 'scale(1)',
    transition: 'all 0.2s ease',
  };

  return (
    <Card
      size="small"
      style={cardStyle}
      hoverable
      onClick={handleClick}
      cover={icon && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '25px',
          height: '40px',
        }}
        >
          {icon}
        </div>
      )}
    >
      <Typography.Paragraph
        ellipsis={{ rows: 2 }}
        style={{
          width: '100%',
          margin: 0,
          display: 'flex',
          height: '2.5em',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        {name}
      </Typography.Paragraph>
    </Card>
  );
}

export default CustomSelectCard;
