/**
 * @generated SignedSource<<6fcdf32ed822ccdd41e63a4762326a32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertySummary_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numGuests",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numRooms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numBathrooms",
      "storageKey": null
    }
  ],
  "type": "PropertyNode",
  "abstractKey": null
};

node.hash = "5a559b94e8c572379d0dd273071c8995";

module.exports = node;
