import React, { useState } from 'react';
import {
  Card, Col, Form, InputNumber, Row,
} from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer, useMutation } from 'react-relay';
import ButtonFooter from './MultiStepButtonFooter';

function ConfigureDetailsForm({ propertyDraft, goBack, goNext }) {
  const [numGuests, setNumGuests] = useState(propertyDraft.numGuests ?? 2);
  const [numBathrooms, setNumBathrooms] = useState(propertyDraft.numBathrooms ?? 1);
  const [numRooms, setNumRooms] = useState(propertyDraft.numRooms ?? 1);

  const [commitSetDetails] = useMutation(graphql`
    mutation ConfigureDetailsFormMutation($input: SetDetailsMutationInput!) {
      setDetails(input: $input) {
        propertyDraft {
          numGuests
          numBathrooms
          numRooms
          step
        }
      }
    }
  `);

  const handleSubmit = () => {
    commitSetDetails({
      variables: {
        input: {
          numGuests,
          numBathrooms,
          numRooms,
        },
      },
      onCompleted: () => {
        goNext();
      },
      onError: (error) => {
        console.error('Error during mutation:', error);
      },
    });
  };

  return (
    <>
      <Form requiredMark={false} onFinish={handleSubmit} size="large" style={{ padding: 16 }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8}>
            <Card title="Guests" size="small">
              <InputNumber
                min={1}
                max={10}
                style={{ width: '100%' }}
                value={numGuests}
                onChange={(value) => setNumGuests(value)}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title="Rooms" size="small">
              <InputNumber
                min={1}
                max={10}
                style={{ width: '100%' }}
                value={numRooms}
                onChange={(value) => setNumRooms(value)}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title="Bathrooms" size="small">
              <InputNumber
                min={1}
                max={10}
                style={{ width: '100%' }}
                value={numBathrooms}
                onChange={(value) => setNumBathrooms(value)}
              />
            </Card>
          </Col>
        </Row>
      </Form>
      <ButtonFooter
        onBack={goBack}
        onNext={handleSubmit}
        nextDisabled={!numGuests || !numBathrooms || !numRooms}
      />
    </>
  );
}

export default createFragmentContainer(ConfigureDetailsForm, {
  propertyDraft: graphql`
    fragment ConfigureDetailsForm_propertyDraft on PropertyDraftNode {
      numGuests
      numBathrooms
      numRooms
    }
  `,
});
