import './App.css';
import {
  Routes, Route, BrowserRouter, Outlet, Navigate,
} from 'react-router-dom';
import { FirestoreProvider, useFirebaseApp, StorageProvider } from 'reactfire';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import React from 'react';
import { ConfigProvider } from 'antd';
import RegisterPage from './components/auth/RegisterPage';
import LoginPage from './components/auth/LoginPage';
import AppLayout from './components/AppLayout';
import PlatformPage from './components/PlatformPage';
import PasswordResetPage from './components/auth/PasswordResetPage';
import NewPasswordPage from './components/auth/NewPasswordPage';
import VerifyEmailPage from './components/auth/VerifyEmailPage';
import PageErrorBoundary from './components/common/PageErrorBoundary';

function App() {
  const firestoreInstance = getFirestore(useFirebaseApp());
  const fireStorage = getStorage(useFirebaseApp());

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <StorageProvider sdk={fireStorage}>
        <ConfigProvider
          theme={{
            token: {
              wireframe: false,
              colorBorder: '#d9d9d9',
              colorPrimary: '#3c89f6',
              colorSuccess: '#4caf50',
              colorWarning: '#ffc107',
              colorError: '#f44336',
              colorInfo: '#2196f3',
              fontSize: 15,
              colorTextBase: '#333333',
              colorBgBase: '#ffffff',
              borderRadius: 8,
            },
          }}
        >
          <BrowserRouter>
            <PageErrorBoundary key="homepage">
              <Routes>
                <Route path="/" element={<Navigate to="/properties" />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/verify" element={<VerifyEmailPage />} />
                <Route path="/password-reset" element={<PasswordResetPage />} />
                <Route path="/new-password/*" element={<NewPasswordPage />} />
                <Route
                  path="/*"
                  element={(
                    <AppLayout>
                      <PlatformPage />
                      <Outlet />
                    </AppLayout>
            )}
                />
              </Routes>
            </PageErrorBoundary>
          </BrowserRouter>
        </ConfigProvider>
      </StorageProvider>
    </FirestoreProvider>
  );
}

export default App;
