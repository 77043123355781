import React, { useCallback, useState } from 'react';
import {
  Button,
  List, Modal, Space, Tooltip, Typography,
} from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import AmenityIcon from '../common/AmenityIcon';

function PropertyAmenities({ property }) {
  const amenities = property?.amenities?.edges?.map((edge) => edge.node);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = useCallback(() => {
    setIsModalVisible(!isModalVisible);
  }, [isModalVisible]);

  const renderAmenityItem = (amenity) => (
    <List.Item>
      <Tooltip title={amenity.description}>
        <Space align="start">
          <AmenityIcon name={amenity.name} />
          {' '}
          <Typography.Text>
            {amenity.name}
          </Typography.Text>
        </Space>
      </Tooltip>
    </List.Item>
  );

  return (
    <>
      <List
        grid={{ gutter: 16, column: 2 }}
        dataSource={amenities.slice(0, 8)}
        renderItem={renderAmenityItem}
      />
      {amenities.length > 8 && (
      <Button size="large" onClick={toggleModal}>
        Show all
        {' '}
        {amenities.length}
        {' '}
        amenities
      </Button>
      )}
      <Modal
        title="All Amenities"
        open={isModalVisible}
        onCancel={toggleModal}
        footer={null}
        width="50%"
      >
        <List
          grid={{ gutter: 16, column: 2 }}
          dataSource={amenities}
          renderItem={renderAmenityItem}
        />
      </Modal>
    </>
  );
}

export default createFragmentContainer(PropertyAmenities, {
  property: graphql`
    fragment PropertyAmenities_property on PropertyNode {
      id
      amenities {
        edges {
          node {
            id
            name
            description
          }
        }
      }
    }
  `,
});
