/**
 * @generated SignedSource<<111f2928a905e7bf16112c8b9833ebfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PropertyTypeNode",
  "kind": "LinkedField",
  "name": "propertyType",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "step",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectPropertyTypeFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetPropertyTypeMutationPayload",
        "kind": "LinkedField",
        "name": "setPropertyType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyDraftNode",
            "kind": "LinkedField",
            "name": "propertyDraft",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectPropertyTypeFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetPropertyTypeMutationPayload",
        "kind": "LinkedField",
        "name": "setPropertyType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyDraftNode",
            "kind": "LinkedField",
            "name": "propertyDraft",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "635a5f88f1ae33382342678dea1c2c0b",
    "id": null,
    "metadata": {},
    "name": "SelectPropertyTypeFormMutation",
    "operationKind": "mutation",
    "text": "mutation SelectPropertyTypeFormMutation(\n  $input: SetPropertyTypeMutationInput!\n) {\n  setPropertyType(input: $input) {\n    propertyDraft {\n      propertyType {\n        id\n        name\n      }\n      step\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "c51dffdebbf216fdb20a3347b9b016ed";

module.exports = node;
