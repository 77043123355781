/**
 * @generated SignedSource<<f7a7dc6839c34b229fcd73e0a61d7494>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyInlineDescriptionForm_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "PropertyNode",
  "abstractKey": null
};

node.hash = "853df39399c3f0a8fc20ab09b39ddf05";

module.exports = node;
