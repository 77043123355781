import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import {
  Form, Input, Button, Card, Typography, Result, message, Checkbox,
} from 'antd';
import { useMutation } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import {
  IdcardOutlined, LockOutlined, MailOutlined, UserOutlined,
} from '@ant-design/icons';
import { MeasuringFrequency } from '@dnd-kit/core';

const { Title } = Typography;

const REGISTER_MUTATION = graphql`
  mutation RegisterPageCreateUserMutation($input: RegisterInput!) {
    register(input: $input) {
        success
        errors
    }
  }
`;

const RESEND_ACTIVATION_EMAIL_MUTATION = graphql`
  mutation RegisterPageResendActivationEmailMutation($input: ResendActivationEmailInput!) {
    resendActivationEmail(input: $input) {
      success
      errors
    }
  }
`;

function RegisterPage() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [registerMutation, isLoading] = useMutation(REGISTER_MUTATION);
  const [resendActivationEmailMutation, isResending] = useMutation(RESEND_ACTIVATION_EMAIL_MUTATION);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  function handleSubmit(values) {
    const variables = {
      input: {
        username: values.username && values.username.toLowerCase(),
        firstName: values.firstName,
        lastName: values.lastName,
        password1: values.password,
        password2: values.password2,
        email: values.email && values.email.toLowerCase(),
      },
    };

    registerMutation({
      variables,
      onCompleted: (response, errors) => {
        if (errors && errors.length > 0) {
          const errMessage = errors[0].message || 'An error occurred while registering.';
          message.error(errMessage);
          return;
        }

        const { register } = response;

        if (register == null) {
          message.error('Something went wrong');
        }

        if (register.success) {
          setRegistrationSuccess(true);
        } else {
          const fieldErrors = [];

          for (const key in register.errors) {
            fieldErrors.push({
              name: key,
              errors: register.errors[key].map((error) => error.message),
            });
          }

          form.setFields(fieldErrors);
        }
      },
      onError: (error) => {
        message.error('Something went wrong.');
      },
    });
  }

  function handleResendActivation(email) {
    const variables = {
      input: {
        email,
      },
    };

    resendActivationEmailMutation({
      variables,
      onCompleted: (response) => {
        const { success } = response.resendActivationEmail;
        if (success) {
          message.success('Activation email has been resent successfully.');
        } else {
          message.error('Failed to resend activation email. Please try again.');
        }
      },
      onError: (error) => {
        message.error('Something went wrong while resending the activation email.');
      },
    });
  }

  const confirmPasswordValidator = (_, value) => {
    const password = form.getFieldValue('password');
    if (value && value !== password) {
      return Promise.reject('Passwords do not match');
    }
    return Promise.resolve();
  };

  const registrationComponent = (
    <Card style={{ width: '400px', marginTop: '50px' }}>
      <Title style={{ textAlign: 'center', marginTop: 0 }}>Register</Title>
      <Form
        requiredMark={false}
        form={form}
        name="register"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: 'Please input your First Name' }]}
          style={{ marginBottom: 8 }}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="First Name"
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[{ required: true, message: 'Please input your Last Name' }]}
          extra={(
            <>
              Your names are not publicly available and will be shared only when a reservation is made.
            </>
            )}
        >
          <Input
            prefix={<IdcardOutlined />}
            placeholder="Last Name"
          />
        </Form.Item>

        <Typography.Paragraph type="secondary" />

        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your username' }]}
          style={{ marginBottom: 8 }}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          extra={(
            <>
              To see the full list of allowlisted corporations and emails click
              {' '}
              <Typography.Link target="_blank" href="https://nestwop.notion.site/Allowlisted-Companies-edfb01feb1c846eda6f8b6141b557baa?pvs=4">here</Typography.Link>
              .
            </>
)}
          name="email"
          rules={[{ required: true, message: 'Please input your email' }]}
        >
          <Input
            prefix={<MailOutlined />}
            type="email"
            placeholder="Corporate Email"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password' }]}
          style={{ marginBottom: 8 }}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="password2"
          dependencies={['password']}
          style={{ marginBottom: 16 }}
          rules={[
            { required: true, message: 'Please confirm your password' },
            { validator: confirmPasswordValidator },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Confirm Password"
          />
        </Form.Item>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('You must agree to the terms and privacy policy'))),
            },
          ]}
          style={{ marginBottom: 16 }}
        >
          <Checkbox>
            I have read and agree to the
            {' '}
            <Typography.Link target="_blank" href="https://nestwop.notion.site/Terms-of-Service-0f4b79c049804d8f85cc0bc8bfabb69b?pvs=4">Terms of Service</Typography.Link>
            {' '}
            and
            {' '}
            <Typography.Link target="_blank" href="https://nestwop.notion.site/Nestwop-Privacy-Policy-c2f83409b5c64e479b334c42a279fbd4?pvs=4">Privacy Policy</Typography.Link>
            .
          </Checkbox>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginBottom: 4 }}
            loading={isLoading}
            block
          >
            Register
          </Button>
          Already have an account?
          {' '}
          <Typography.Link href="/login">Log in</Typography.Link>
        </Form.Item>
      </Form>
    </Card>
  );

  const registrationSuccessComponent = (
    <Result
      status="success"
      title="Registration Successful!"
      subTitle={(
        <>
          Please check your email for a confirmation link to complete the registration process.
          {' '}
          <Typography.Link onClick={() => handleResendActivation(form.getFieldValue('email'))}>
            Click here
          </Typography.Link>
          {' '}
          if you did not receive the email.
        </>
      )}
      extra={[
        <Button type="primary" key="console" onClick={() => navigate('/Login')}>
          Go to Login Page
        </Button>,
      ]}
    />
  );

  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh',
    }}
    >
      {registrationSuccess ? registrationSuccessComponent : registrationComponent}
    </div>
  );
}

export default RegisterPage;
