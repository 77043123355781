import { Modal } from 'antd';
import React from 'react';
import PropertySearchBar from './PropertySearchBar';

function MobileSearchModal({ visible, onCancel }) {
  return (
    <Modal title="Search Properties" open={visible} onCancel={onCancel} footer={null} centered>
      <PropertySearchBar onCancel={onCancel} />
    </Modal>
  );
}

export default MobileSearchModal;
