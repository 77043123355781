import graphql from 'babel-plugin-relay/macro';

const useCreatePropertyDraftMutation = graphql`
  mutation useCreatePropertyDraftMutation {
    createPropertyDraft {
      success
      propertyDraft {
        id
        step
      }
    }
  }
`;

export default useCreatePropertyDraftMutation;
