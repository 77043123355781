import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import {
  Avatar, Typography, Space,
} from 'antd';

function UserSummary({ user }) {
  const username = user?.username ?? 'User';
  const companyName = user?.company?.name ?? 'Company';

  return (
    <div style={{ width: '100%', textAlign: 'right' }}>
      <Space direction="vertical">
        <Space align="center">
          <Avatar
            size={32}
            src={(
              <Typography.Title level={5} style={{ color: 'white', margin: 0, marginTop: 2 }}>
                {username[0].toUpperCase() ?? 'U'}
              </Typography.Title>
            )}
            style={{ background: '#364d79' }}
          />
        </Space>
        <Space align="center">
          {/* <img
            src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1"
            alt="company logo"
            style={{ width: '16px', height: '16px' }}
          /> */}
          <Typography.Text type="secondary">{companyName}</Typography.Text>
        </Space>
      </Space>
    </div>
  );
}

export default createFragmentContainer(UserSummary, {
  user: graphql`
    fragment UserSummary_user on UserNode {
      username
      company {
        name
      }
    }
  `,
});
