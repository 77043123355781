import React, { useState } from 'react';
import { QueryRenderer, useMutation, useRelayEnvironment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  Alert, Button, Card, Col, Divider, Form, Input, message, Row, Skeleton, Statistic, Tooltip, Typography,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import NightCurrency from '../common/NightCurrency';

const { Title, Text } = Typography;

const UserReferralsPageQuery = graphql`
query UserReferralsPageQuery {
  me {
    username
    isReferred
    totalReferrals
  }
}
`;

const AssignReferralMutation = graphql`
  mutation UserReferralsPageMutation($input: AssignReferralMutationInput!) {
    assignReferral(input: $input) {
      user {
        isReferred
        totalReferrals
      }
    }
  }
`;

function UserOverview() {
  const environment = useRelayEnvironment();
  const [form] = Form.useForm();
  const [commitMutation, isInFlight] = useMutation(AssignReferralMutation);

  const onFinish = (values) => {
    commitMutation({
      variables: { input: { referralCode: values.referralCode } },
      onCompleted: (response, errors) => {
        if (errors) {
          message.error(errors[0].message);
        } else {
          message.success('Referral code submitted successfully!');
        }
      },
      onError: (err) => {
        message.error('An error occurred');
      },
    });
  };

  const handleCopyClick = (referralCode) => {
    navigator.clipboard.writeText(referralCode);
    message.success('Referral code copied to clipboard!');
  };

  return (
    <QueryRenderer
      environment={environment}
      query={UserReferralsPageQuery}
      render={({ error, props }) => {
        if (error) {
          throw error;
        }

        if (!props) {
          return (
            <Row>
              <Col span={8} offset={8} style={{ paddingTop: 20 }}>
                <Card>
                  <Skeleton active />
                </Card>
              </Col>
            </Row>
          );
        }
        return (
          <Row>
            <Col span={8} offset={8} style={{ paddingTop: 20 }}>
              <Title level={2} style={{ marginTop: 0 }}>Referrals</Title>
              <Card>
                <Title level={4} style={{ marginTop: 0 }}>Your referrals</Title>
                <Row>
                  <Col span={12}>
                    <Text type="secondary">Referral Code</Text>
                    <br />
                    <Button type="dashed" size="large" icon={<CopyOutlined />} style={{ marginTop: 4 }} onClick={() => handleCopyClick(props.me.username.toUpperCase())}>
                      {props.me.username.toUpperCase()}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Statistic title="Total referrals" value={props.me.totalReferrals} />
                  </Col>
                  <Text type="secondary" style={{ marginTop: 12 }}>
                    Each referral earns a bonus
                    <NightCurrency amount={1} />
                    {' '}
                    for you and your friend.
                  </Text>
                </Row>
                <Divider />
                {props.me.isReferred ? (
                  <Alert message="You have been referred!" type="success" showIcon />
                ) : (
                  <>
                    <Title level={4} style={{ marginTop: 0 }}>Have a referral code?</Title>
                    <Form form={form} onFinish={onFinish}>
                      <Form.Item
                        name="referralCode"
                        rules={[{ required: true, message: 'Please input your referral code!' }]}
                      >
                        <Input placeholder="Enter referral code" />
                      </Form.Item>
                      <Form.Item style={{ margin: 0 }}>
                        <Button type="primary" htmlType="submit" loading={isInFlight}>Submit Referral Code</Button>
                      </Form.Item>
                    </Form>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        );
      }}
    />
  );
}

export default UserOverview;
