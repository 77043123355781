import React from 'react';
import {
  Routes, Route, useNavigate, useLocation,
} from 'react-router-dom';
import PageErrorBoundary from './common/PageErrorBoundary';
import DraftPropertyMultiStepForm from './property/forms/draft/DraftPropertyMultiStepForm';
import PropertyHouseManualForm from './property/forms/PropertyHouseManualForm';
import PropertyDetails from './property/PropertyDetails';
import PropertyDetailsEdit from './property/PropertyDetailsEdit';
import PropertySearchPage from './property/PropertySearchPage';
import ReservationDetails from './reservation/ReservationDetails';
import UserOverview from './user/UserOverview';
import UserReferralsPage from './user/UserReferralsPage';

function PlatformPage() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div>
      <PageErrorBoundary navigate={navigate} key={location.pathname}>
        <Routes>
          <Route path="properties" element={<PropertySearchPage />} />
          <Route path="properties/:id" element={<PropertyDetails />} />
          <Route path="properties/:id/edit" element={<PropertyDetailsEdit />} />
          <Route path="properties/:id/housemanual/edit" element={<PropertyHouseManualForm />} />
          <Route path="profile" element={<UserOverview />} />
          <Route path="refer" element={<UserReferralsPage />} />
          <Route path="draft_property" element={<DraftPropertyMultiStepForm />} />
          <Route path="reservations/:id" element={<ReservationDetails />} />
        </Routes>
      </PageErrorBoundary>
    </div>
  );
}

export default PlatformPage;
