import React, { useEffect, useState } from 'react';
import {
  message, Calendar, Tooltip, Form, Button, Row, Col, Space,
} from 'antd';
import { createFragmentContainer, useMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import dayjs from 'dayjs';
import { isDateWithinAvailableDates, isDisabledDateByDateRanges } from 'src/utils/calendarUtils';
import utc from 'dayjs-plugin-utc';
import { PlusOutlined } from '@ant-design/icons';
import AvailabilitiesList from '../common/AvailabilitiesList';
import AvailabilityInput from '../common/AvailabilityInput';

dayjs.extend(utc);

const updatePropertyMutation = graphql`
  mutation PropertyAvailabilitiesFormFormMutation($input: UpdatePropertyMutationInput!) {
    updateProperty(input: $input) {
      property {
        id
        availabilities {
          edges {
            node {
              id
              availableFrom
              availableTo
            }
          }
        }
      }
    }
  }
`;

function PropertyAvailabilitiesForm({ property }) {
  const [commitMutation] = useMutation(updatePropertyMutation);
  const sixMonthsAgo = dayjs().subtract(6, 'month');
  const calendarLimitDate = dayjs().add(12, 'month');
  const [dateRanges, setDateRanges] = useState([]);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    const initialRanges = property.availabilities.edges.map((edge) => [
      dayjs.utc(edge.node.availableFrom),
      dayjs.utc(edge.node.availableTo),
    ]);
    setDateRanges(initialRanges);
  }, [property]);

  const handleSubmit = () => {
    const availabilitiesInput = dateRanges.map((range) => ({
      availableFrom: range[0].toISOString().split('T')[0],
      availableTo: range[1].toISOString().split('T')[0],
    }));

    commitMutation({
      variables: {
        input: {
          id: property.id,
          availabilities: availabilitiesInput,
        },
      },
      onCompleted: (response, errors) => {
        if (errors && errors.length > 0) {
          const errMessage = errors[0].message || 'An error occurred while updating availabilities.';
          message.error(errMessage);
        } else {
          message.success('Availabilities updated successfully!');
        }
      },
      onError: (error) => {
        message.error('Error updating availabilities. Please try again.');
      },
    });
  };

  // check if the current date is the first date in any of the availability ranges
  const isFirstDateInRange = (date) => dateRanges.some((range) => dayjs.utc(range[0]).isSame(date, 'day'));

  // check if the current date is the last date in any of the availability ranges
  const isLastDateInRange = (date) => dateRanges.some((range) => dayjs.utc(range[1]).isSame(date, 'day'));

  const dateFullCellRender = (value) => {
    const dateIsAvailable = isDateWithinAvailableDates(value, dateRanges);
    const isFirst = isFirstDateInRange(value);
    const isLast = isLastDateInRange(value);

    const cellContent = (
      <div className="ant-picker-cell-inner">
        {value.date()}
      </div>
    );

    if (dateIsAvailable) {
      return (
        <Tooltip title="Available">
          <div style={{
            borderRadius: isFirst ? '15px 0 0 15px' : isLast ? '0 15px 15px 0' : '0',
            backgroundColor: dateIsAvailable ? '#d9f7be' : 'initial',
          }}
          >
            {cellContent}
          </div>
        </Tooltip>
      );
    }

    return cellContent;
  };

  const handleDateRangeChange = (index, dates) => {
    setIsAdding(false);
    if (index === dateRanges.length) {
      setDateRanges([...dateRanges, dates]);
    } else {
      const updatedRanges = dateRanges.map((range, idx) => (idx === index ? dates : range));
      setDateRanges(updatedRanges);
    }
  };

  const handleRemoveDateRange = (index) => {
    setDateRanges(dateRanges.filter((_, idx) => idx !== index));
  };

  const disabledDate = (current) => isDisabledDateByDateRanges(current, dateRanges);

  return (
    property && (
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Space style={{ width: '100%' }} direction="vertical">
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              requiredMark={false}
            >
              {isAdding ? (
                <Space size={0} direction="vertical" style={{ width: '100%' }}>
                  <AvailabilityInput
                    disabledDate={disabledDate}
                    dateRanges={dateRanges}
                    handleDateRangeChange={handleDateRangeChange}
                    label={null}
                  />
                </Space>
              ) : (
                <Button
                  style={{ marginBottom: '16px' }}
                  block
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => setIsAdding(true)}
                >
                  Add New Availability
                </Button>
              )}
              <AvailabilitiesList
                isRangeEditable={!isAdding}
                handleDateRangeChange={handleDateRangeChange}
                dateRanges={dateRanges}
                handleRemoveDateRange={handleRemoveDateRange}
              />
              <Button block type="primary" htmlType="submit" style={{ marginTop: '16px' }}>
                Save
              </Button>
            </Form>
          </Space>
        </Col>
        <Col xs={24} md={12}>
          <Calendar
            fullscreen={false}
            validRange={[sixMonthsAgo, calendarLimitDate]}
            dateFullCellRender={dateFullCellRender}
          />
        </Col>
      </Row>
    )
  );
}

export default createFragmentContainer(PropertyAvailabilitiesForm, {
  property: graphql`
    fragment PropertyAvailabilitiesForm_property on PropertyNode {
      id
      availabilities {
        edges {
          node {
            id
            availableFrom
            availableTo
          }
        }
      }
    }
  `,
});
