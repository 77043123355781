import Icon from '@ant-design/icons';
import { Space, Typography } from 'antd';
import React from 'react';

const fontSizeMap = {
  normal: '1em',
  large: '1.4em',
};

function LunaSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" fill="currentColor">
      <path
        d="M12 2A9.91 9.91 0 0 0 9 2.46A10 10 0 0 1 9 21.54A10 10 0 1 0 12 2Z"
        fill="#464655"
      />
    </svg>
  );
}

function LunaIcon(props) {
  return <Icon component={LunaSvg} {...props} />;
}

function NightCurrency({ amount, size = 'normal' }) {
  return (
    <Space align="center" size={[2, 2]}>
      <LunaIcon style={{ fontSize: fontSizeMap[size] }} />
      <Typography.Text strong style={{ fontSize: fontSizeMap[size], lineHeight: '1' }}>
        {amount}
      </Typography.Text>
    </Space>
  );
}

export default NightCurrency;
