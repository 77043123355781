/**
 * @generated SignedSource<<0b3f4614cd85c80e912f66eda40b7aa2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ReservationEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReservationNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReservationList_reservations"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyReservations_user",
  "selections": [
    {
      "alias": "reservations_UPCOMING",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "statusFilter",
          "value": "UPCOMING"
        }
      ],
      "concreteType": "ReservationConnection",
      "kind": "LinkedField",
      "name": "reservationsMade",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "reservationsMade(first:10,statusFilter:\"UPCOMING\")"
    },
    {
      "alias": "reservations_PAST",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "statusFilter",
          "value": "PAST"
        }
      ],
      "concreteType": "ReservationConnection",
      "kind": "LinkedField",
      "name": "reservationsMade",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "reservationsMade(first:10,statusFilter:\"PAST\")"
    },
    {
      "alias": "reservations_CANCELLED",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "statusFilter",
          "value": "CANCELLED"
        }
      ],
      "concreteType": "ReservationConnection",
      "kind": "LinkedField",
      "name": "reservationsMade",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "reservationsMade(first:10,statusFilter:\"CANCELLED\")"
    }
  ],
  "type": "CustomUserNode",
  "abstractKey": null
};
})();

node.hash = "b59bdd695d204984253a5cff0b31c930";

module.exports = node;
