import React, { useCallback } from 'react';
import { QueryRenderer } from 'react-relay';
import {
  Typography, Divider, Col, Row, Affix, Space, Skeleton, Button, message,
} from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useNavigate, useParams } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { getFullPropertyUrl, getPropertyUrl } from 'src/utils/urlUtils';
import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import PropertyHouseManualCard from './PropertyHouseManualCard';
import PropertyAmenities from './PropertyAmenities';
import PropertySummary from './PropertySummary';
import PropertyInlineTitleForm from './forms/property/PropertyInlineTitleForm';
import PropertyInlineDescriptionForm from './forms/property/PropertyInlineDescriptionForm';
import PropertyAvailabilitiesForm from './forms/property/PropertyAvailabilitiesForm';
import PropertyInlineImagesForm from './forms/property/PropertyInlineImagesForm';
import PropertyTypeSummary from './PropertyTypeSummary';

const { Text } = Typography;

const { useRelayEnvironment } = require('react-relay');

const PropertyDetailsEditQuery = graphql`
  query PropertyDetailsEditPropertyQuery($id: ID!) {
    property(id: $id) {
      id
      latitude
      longitude
      city
      country
      isOwner
      ...PropertyInlineImagesForm_property
      ...PropertyAmenities_property
      ...PropertySummary_property
      ...PropertyTypeSummary_property
      ...PropertyInlineTitleForm_property
      ...PropertyInlineDescriptionForm_property
      ...PropertyHouseManualCard_property
      ...PropertyAvailabilitiesForm_property
    }
  }
`;

function PropertyDetailsEdit() {
  const { id } = useParams();
  const environment = useRelayEnvironment();
  const navigate = useNavigate();
  const propertyURL = getPropertyUrl(id);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA25x-2K58jI2YByiq72l4ikwAcypprtus',
    libraries: ['places'],
    language: 'en',
  });

  const variables = {
    id,
  };

  const copyUrlToClipboard = useCallback(() => {
    navigator.clipboard.writeText(getFullPropertyUrl(id)).then(() => {
      message.success('URL copied to clipboard!');
    }, () => {
      message.error('Failed to copy URL');
    });
  }, []);

  return (
    <QueryRenderer
      environment={environment}
      query={PropertyDetailsEditQuery}
      variables={variables}
      render={({ error, props }) => {
        if (error) {
          throw error;
        }

        if (props) {
          const { property } = props;

          if (property.isOwner !== true) {
            navigate(propertyURL);
            return null;
          }

          const center = {
            lat: parseFloat(property.latitude),
            lng: parseFloat(property.longitude),
          };

          return (
            <Row>
              <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 3 }} style={{ padding: 16 }}>
                <Row style={{ marginBottom: '18px' }}>
                  <Col span={24} style={{ paddingTop: 25 }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                      width: '100%',
                    }}
                    >
                      <PropertyInlineTitleForm propertyRef={property} />
                      <Button
                        type="primary"
                        size="small"
                        onClick={(event) => {
                          event.preventDefault();
                          navigate(propertyURL);
                        }}
                      >
                        <EyeOutlined />
                        {' '}
                        Preview
                      </Button>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Space style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                    >
                      <Text level={3}>
                        {property.city}
                        ,
                        {' '}
                        {property.country}
                      </Text>
                      <Button
                        type="text"
                        size="small"
                        icon={<CopyOutlined />}
                        onClick={copyUrlToClipboard}
                      >
                        Share
                      </Button>
                    </Space>
                  </Col>
                </Row>
                <PropertyInlineImagesForm property={property} />
                <Row gutter={[16, 16]} style={{ paddingTop: '32px' }}>
                  <Col xs={24} md={16}>
                    <Row>
                      <PropertyTypeSummary property={property} />
                      <Col span={24}>
                        <PropertySummary property={property} />
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Title level={4} style={{ marginTop: 0 }}>Availabilities</Title>
                      <Col span={24} style={{ paddingTop: '8px' }}>
                        <PropertyAvailabilitiesForm property={property} />
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Title level={4} style={{ marginTop: 0 }}>More about the accommodation</Title>
                      <Col span={24} style={{ paddingTop: '8px' }}>
                        <PropertyInlineDescriptionForm propertyRef={property} />
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Title level={4} style={{ marginTop: 0 }}>Amenities</Title>
                      <Col span={24} style={{ paddingTop: '8px' }}>
                        <PropertyAmenities property={property} />
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Title level={4} style={{ marginTop: 0 }}>Where you will be</Title>
                      <Paragraph style={{ width: '100%' }}>
                        {`${property.city}, ${property.country}`}
                      </Paragraph>
                      {isLoaded && (
                        <GoogleMap
                          mapContainerStyle={{ height: '400px', width: '100%' }}
                          center={center}
                          zoom={15}
                        >
                          <MarkerF position={center} />
                        </GoogleMap>
                      )}
                    </Row>
                  </Col>
                  <Col xs={24} md={8}>
                    <Affix offsetTop={20}>
                      <PropertyHouseManualCard
                        property={property}
                      />
                    </Affix>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        }

        return (
          <Row>
            <Col span={18} offset={3}>
              <Skeleton active paragraph={{ rows: 12 }} />
              <Row gutter={[16, 16]} style={{ paddingTop: '32px' }}>
                <Col span={16} style={{ paddingRight: '48px' }}>
                  <Skeleton active paragraph={{ rows: 2 }} />
                  <Divider />
                  <Skeleton active paragraph={{ rows: 4 }} />
                  <Divider />
                  <Skeleton active paragraph={{ rows: 4 }} />
                  <Divider />
                  <Skeleton active paragraph={{ rows: 4 }} />
                </Col>
                <Col span={8}>
                  <Skeleton.Avatar active size={96} shape="square" />
                  <Skeleton active paragraph={{ rows: 5 }} />
                </Col>
              </Row>
            </Col>
          </Row>
        );
      }}
    />
  );
}

export default PropertyDetailsEdit;
