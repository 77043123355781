import React, { useRef, useState } from 'react';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { mdiAmplifierOff } from '@mdi/js';
import PropertyCard from './PropertyCard';

const containerStyle = {
  width: '100%',
  height: '100%',
};

function PropertyMap({
  properties, center, initialZoom = 13, viewport = null,
}) {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [centerPosition, setCenterPosition] = useState(center);

  const [bounds, setBounds] = useState(null);
  const mapRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const onLoad = React.useCallback((currentMap) => {
    mapRef.current = currentMap;

    // Set the center only once when the component is loaded
    if (properties != null && properties.length > 0) {
      setCenterPosition({
        lat: parseFloat(properties[0].latitude),
        lng: parseFloat(properties[0].longitude),
      });
    }

    if (viewport != null) {
      mapRef.current.fitBounds(viewport);
    }
    setIsButtonVisible(false);
  }, [properties]);

  const onUnmount = React.useCallback(() => {
    setIsButtonVisible(false);
    setBounds(null);
    setSelectedProperty(null);
    mapRef.current = null;
  }, []);

  const onBoundsChanged = React.useCallback(() => {
    if (mapRef && mapRef.current) {
      const newBounds = mapRef.current.getBounds();

      if (bounds) {
        // Calculate difference
        const latDiff = Math.abs(newBounds.getNorthEast().lat() - bounds.getNorthEast().lat());
        const lngDiff = Math.abs(newBounds.getNorthEast().lng() - bounds.getNorthEast().lng());

        // If the difference is significant (adjust the numbers as needed)
        if (latDiff > 0.01 || lngDiff > 0.01) {
          setIsButtonVisible(true);
          setBounds(newBounds);
        }
      } else {
        // If bounds has not been set yet
        setBounds(newBounds);
      }
    }
  }, [bounds]);

  const onSearchThisArea = () => {
    const queryParams = new URLSearchParams(location.search);
    if (bounds) {
      queryParams.set('neLat', bounds.getNorthEast().lat());
      queryParams.set('neLng', bounds.getNorthEast().lng());
      queryParams.set('swLat', bounds.getSouthWest().lat());
      queryParams.set('swLng', bounds.getSouthWest().lng());

      navigate(`/properties?${queryParams.toString()}`, { replace: true });
    }
  };

  return (
    <div style={{
      position: 'relative', width: '100%', height: '100%', minHeight: '300px', overflow: 'hidden',
    }}
    >
      <GoogleMap
        key={properties.length}
        mapContainerStyle={containerStyle}
        center={centerPosition}
        onBoundsChanged={onBoundsChanged}
        zoom={initialZoom}
        onLoad={onLoad}
        ref={mapRef}
        onUnmount={onUnmount}
      >

        {properties.map((property) => (
          <MarkerF
            key={property.id}
            position={{ lat: parseFloat(property.latitude), lng: parseFloat(property.longitude) }}
            onClick={() => {
              setSelectedProperty(property);
            }}
          />
        ))}

        {selectedProperty && (
        <InfoWindowF
          position={{
            lat: parseFloat(selectedProperty.latitude),
            lng: parseFloat(selectedProperty.longitude),
          }}
          onCloseClick={() => {
            setSelectedProperty(null);
          }}
        >
          <div style={{ maxWidth: '200px' }}>
            <PropertyCard
              property={selectedProperty}
              imageSize="small"
            />
          </div>
        </InfoWindowF>
        )}
      </GoogleMap>
      {isButtonVisible && (
        <Button
          icon={<SearchOutlined />}
          type="primary"
          style={{
            position: 'absolute', bottom: 32, left: '50%', transform: 'translateX(-50%)',
          }}
          onClick={() => {
            onSearchThisArea();
            setIsButtonVisible(false);
          }}
        >
          Search this area
        </Button>
      )}

    </div>
  );
}

export default createFragmentContainer(PropertyMap, {
  properties: graphql`
    fragment PropertyMap_properties on PropertyNode @relay(plural: true) {
        id
        latitude
        longitude
        title
        description
        ...PropertyCard_property
      }
    `,
});
