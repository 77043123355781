import React, { useEffect, useState } from 'react';
import {
  Button, Space, DatePicker, Input, InputNumber, Grid,
} from 'antd';
import { Autocomplete } from '@react-google-maps/api';
import {
  EnvironmentOutlined, SearchOutlined,
} from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { isDisabledDateByDateRanges } from 'src/utils/calendarUtils';
import { getDateRangeFromUrl, getGuestsNumberFromUrl } from 'src/utils/urlUtils';

const { RangePicker } = DatePicker;
const { useBreakpoint } = Grid;

function PropertySearchBar({ onCancel = null }) {
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();

  const [autocomplete, setAutocomplete] = useState(null);
  const [currentPlace, setCurrentPlace] = useState(null);
  const [guests, setGuests] = useState(getGuestsNumberFromUrl(location));
  const [cityValue, setCityValue] = useState('');

  const [dateRange, setDateRange] = useState(getDateRangeFromUrl(location));

  const handleGuestsChange = (value) => {
    setGuests(value);
  };

  const handlePlaceChanged = () => {
    const place = autocomplete.getPlace();
    setCityValue(place.formatted_address);
    setCurrentPlace(place);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleSearchClick = () => {
    const queryParams = new URLSearchParams(location.search);
    if (currentPlace?.geometry?.viewport) {
      // Update URL query parameters
      queryParams.set('neLat', currentPlace.geometry.viewport.getNorthEast().lat());
      queryParams.set('neLng', currentPlace.geometry.viewport.getNorthEast().lng());
      queryParams.set('swLat', currentPlace.geometry.viewport.getSouthWest().lat());
      queryParams.set('swLng', currentPlace.geometry.viewport.getSouthWest().lng());
    }

    queryParams.set('from', dateRange[0]?.toISOString().split('T')[0]);
    queryParams.set('to', dateRange[1]?.toISOString().split('T')[0]);
    queryParams.set('guests', guests);

    navigate(`/properties?${queryParams.toString()}`, { replace: true });

    if (onCancel) {
      onCancel();
    }
  };

  const onLoad = React.useCallback((autcompleteInstance) => {
    setAutocomplete(autcompleteInstance);
  }, []);

  const disabledDate = (current) => isDisabledDateByDateRanges(current, []);

  const isSearchDisabled = dateRange == null || dateRange.length === 0;

  const isMobile = !screens.md && !screens.lg && !screens.xl && !screens.xxl;

  return (
    <Space.Compact block={isMobile} direction={isMobile ? 'vertical' : 'horizontal'} style={{ height: !isMobile && '32px', lineHeight: '32px' }}>
      <Autocomplete
        onPlaceChanged={handlePlaceChanged}
        onLoad={onLoad}
        options={{
          types: ['(cities)'],
        }}
      >
        <Input
          autoFocus
          placeholder="City"
          prefix={<EnvironmentOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
          value={cityValue}
          style={{
            borderBottomLeftRadius: isMobile && 0,
            borderBottomRightRadius: isMobile && 0,
          }}
          onChange={(e) => setCityValue(e.target.value)}
        />
      </Autocomplete>
      <RangePicker
        style={{
          maxWidth: isMobile ? '100%' : '200px',
          borderRadius: isMobile && 0,
        }}
        placeholder={['From', 'To']}
        disabledDate={disabledDate}
        onChange={handleDateRangeChange}
        // @ts-ignore
        defaultValue={dateRange}
        format="DD MMM"
      />
      <InputNumber
        style={{
          width: isMobile ? '100%' : '100px',
          borderRadius: isMobile && 0,
        }}
        min={1}
        defaultValue={1}
        max={9}
        value={guests}
        onChange={handleGuestsChange}
        formatter={(value) => `${value} ${value === '1' ? 'guest' : 'guests'}`}
        parser={(value) => value.replace(/[^0-9]/g, '')}
      />
      <Button type="primary" icon={<SearchOutlined />} onClick={handleSearchClick} disabled={isSearchDisabled}>
        Search
      </Button>
    </Space.Compact>
  );
}

export default PropertySearchBar;
