import { useMutation } from 'react-relay/hooks';
import graphql from 'babel-plugin-relay/macro';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button, Card, Typography, Alert, Row,
} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';

const { Title } = Typography;

const VerifyAccountMutation = graphql`
  mutation VerifyEmailPageMutation($input: VerifyAccountInput!) {
    verifyAccount(input: $input) {
      success
      errors 
    }
  }
`;

function VerifyEmailPage() {
  const [commit, isInFlight] = useMutation(VerifyAccountMutation);
  const [statusDescription, setStatusDescription] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [messageType, setMessageType] = useState('info');

  const location = useLocation();
  const navigate = useNavigate();

  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    commit({
      variables: {
        input: {
          token,
        },
      },
      onCompleted: (data) => {
        if (data.verifyAccount.success) {
          setMessageType('success');
          setStatusMessage('Successfully verified');
          setStatusDescription('Your account has been verified successfully. You can now login with your username and password.');
        } else {
          // Checking for the "already verified" error
          const alreadyVerifiedError = data?.verifyAccount?.errors?.nonFieldErrors.find(
            (error) => error.code === 'already_verified',
          );

          if (alreadyVerifiedError) {
            setStatusMessage('Already verified');
            setStatusDescription('Your account has already been verified. You can now log in.');
            setMessageType(info);
          } else {
            // For other errors
            setMessageType('error');
            setStatusMessage('Error');
            setStatusDescription('An error occurred while verifying your account.');
            console.log(data.verifyAccount.errors);
          }
        }
      },
      onError: (err) => {
        // Handle error
        setMessageType('error');
        setStatusMessage('Error');
        setStatusDescription('An unexpected error occurred.');
        console.error(err);
      },
    });
  }, [commit, token]);

  const handleOk = () => {
    navigate('/login');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card style={{ width: '500px', marginTop: '50px' }}>
        {isInFlight ? (
          <>
            <Title style={{ textAlign: 'center', marginTop: 0 }}>Verifying your account...</Title>
            <Button type="primary" loading block>
              Loading
            </Button>
          </>
        ) : (
          <Row>
            <Alert
              message={statusMessage}
              description={statusDescription}
              type={messageType}
              style={{ width: '100%' }}
              showIcon
            />
            <Button type="primary" onClick={handleOk} block style={{ marginTop: 20 }}>
              Login
            </Button>
          </Row>
        )}
      </Card>
    </div>
  );
}

export default VerifyEmailPage;
