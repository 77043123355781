/**
 * @generated SignedSource<<51aa374f5117446868edf1e94ced387c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  "properties"
],
v1 = {
  "kind": "Variable",
  "name": "availableFrom",
  "variableName": "availableFrom"
},
v2 = {
  "kind": "Variable",
  "name": "availableTo",
  "variableName": "availableTo"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "availableFrom"
    },
    {
      "kind": "RootArgument",
      "name": "availableTo"
    },
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "northEast"
    },
    {
      "kind": "RootArgument",
      "name": "numGuests"
    },
    {
      "kind": "RootArgument",
      "name": "southWest"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./PropertySearchResultsPaginationQuery.graphql')
    }
  },
  "name": "PropertySearchResults_query",
  "selections": [
    {
      "alias": "properties",
      "args": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "northEast",
          "variableName": "northEast"
        },
        {
          "kind": "Variable",
          "name": "numGuests",
          "variableName": "numGuests"
        },
        {
          "kind": "Variable",
          "name": "southWest",
          "variableName": "southWest"
        }
      ],
      "concreteType": "PropertyConnection",
      "kind": "LinkedField",
      "name": "__PropertySearchResults_properties_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PropertyEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PropertyNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "concreteType": "PropertyAvailabilityNode",
                  "kind": "LinkedField",
                  "name": "relevantAvailabilities",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "availableFrom",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "availableTo",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PropertyCard_property"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PropertyMap_properties"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

node.hash = "1f4d2cac259471e5f1ac66e5bf9dc2a7";

module.exports = node;
