/**
 * @generated SignedSource<<59117ffdb8838075cd63adb18a0da04f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePropertyMutationPayload",
    "kind": "LinkedField",
    "name": "updateProperty",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PropertyNode",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ImageConnection",
            "kind": "LinkedField",
            "name": "orderedImages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImageNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyInlineImagesFormMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropertyInlineImagesFormMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "654011dfc32fe586773bc12fa3ca45f4",
    "id": null,
    "metadata": {},
    "name": "PropertyInlineImagesFormMutation",
    "operationKind": "mutation",
    "text": "mutation PropertyInlineImagesFormMutation(\n  $input: UpdatePropertyMutationInput!\n) {\n  updateProperty(input: $input) {\n    property {\n      id\n      orderedImages {\n        edges {\n          node {\n            id\n            url\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "26a175607c5929e5ecbcb9e47e35b734";

module.exports = node;
