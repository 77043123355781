import React, { useState } from 'react';
import {
  Typography, message, Form, Input, Button,
} from 'antd';
import { useFragment, useMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import formatNewlines from 'src/utils/miscUtils';

const updatePropertyMutation = graphql`
  mutation PropertyInlineDescriptionFormMutation($input: UpdatePropertyMutationInput!) {
    updateProperty(input: $input) {
      property {
        id
        description
      }
    }
  }
`;

function PropertyInlineDescriptionForm({ propertyRef }) {
  const property = useFragment(
    graphql`
      fragment PropertyInlineDescriptionForm_property on PropertyNode {
        id
        description
      }
    `,
    propertyRef,
  );

  const [commitMutation] = useMutation(updatePropertyMutation);
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [form] = Form.useForm();

  const handleEdit = () => {
    setIsEditing(true);
    form.setFieldsValue({ description: property.description });
  };

  const handleExpand = () => {
    setIsExpanded(true);
  };

  const handleSave = () => {
    const newDescription = form.getFieldValue('description');

    commitMutation({
      variables: {
        input: {
          id: property.id,
          description: newDescription,
        },
      },
      onCompleted: (response, errors) => {
        setIsEditing(false);
        setIsExpanded(false);
        if (errors && errors.length > 0) {
          const errMessage = errors[0].message || 'An error occurred while editing the description.';
          message.error(errMessage);
        } else {
          message.success('Description updated successfully!');
        }
      },
      onError: () => {
        message.error('Error updating description. Please try again.');
        setIsEditing(false);
        setIsExpanded(false);
      },
      optimisticResponse: {
        updateProperty: {
          property: {
            id: property.id,
            description: newDescription,
          },
        },
      },
    });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsExpanded(false);
  };

  return (
    property && (
      <div>
        {isEditing ? (
          <Form form={form} layout="vertical" onFinish={handleSave}>
            <Form.Item name="description">
              <Input.TextArea
                placeholder="Enter a description for your property"
                autoSize={{ minRows: 5 }}
              />
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
              <Form.Item style={{ display: 'inline-block', marginRight: 8 }}>
                <Button htmlType="button" onClick={handleCancel}>
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item style={{ display: 'inline-block' }}>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <Typography.Paragraph
            onDoubleClick={handleEdit}
            editable={{
              onStart: handleEdit,
              editing: isEditing,
            }}
            ellipsis={{
              rows: 4,
              expandable: true,
              onExpand: handleExpand,
            }}
          >
            {isExpanded ? formatNewlines(property.description) : property.description}
          </Typography.Paragraph>
        )}
      </div>
    )
  );
}

export default PropertyInlineDescriptionForm;
