import React, { useState, useEffect } from 'react';
import {
  Form, Col, Row,
} from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer, useMutation } from 'react-relay';
import AmenityIcon from '../../../common/AmenityIcon';
import ButtonFooter from './MultiStepButtonFooter';
import CustomSelectCard from '../../../common/CustomSelectCard';

function SelectAmenitiesForm({
  propertyDraft, amenities, goBack, goNext,
}) {
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  useEffect(() => {
    const initialAmenities = propertyDraft.amenities.edges.map((edge) => edge.node.id);
    setSelectedAmenities(initialAmenities);
  }, [propertyDraft.amenities]);

  const onAmenitySelect = (id) => {
    setSelectedAmenities([...selectedAmenities, id]);
  };

  const onAmenityDeselect = (id) => {
    setSelectedAmenities(selectedAmenities.filter((amenityId) => amenityId !== id));
  };

  const [commitSelectAmenities] = useMutation(graphql`
    mutation SelectAmenitiesFormMutation($input: SetAmenitiesMutationInput!) {
      setAmenities(input: $input) {
        propertyDraft {
          amenities {
            edges {
                node {
                    id
                    name
                }
            }
          }
        }
      }
    }
  `);

  const handleSubmit = () => {
    commitSelectAmenities({
      variables: {
        input: {
          amenityIds: selectedAmenities,
        },
      },
      onCompleted: () => {
        goNext();
      },
      onError: (error) => {
        console.error('Error during mutation:', error);
      },
    });
  };

  return (
    <>
      <Form onFinish={handleSubmit} style={{ padding: 16 }}>
        <Form.Item>
          <Row gutter={[16, 16]}>
            {amenities.edges.map(({ node: amenity }) => {
              const isSelected = selectedAmenities.includes(amenity.id);
              return (
                <Col xl={6} md={8} xs={12}>
                  <CustomSelectCard
                    id={amenity.id}
                    name={amenity.name}
                    selected={isSelected}
                    onSelect={onAmenitySelect}
                    onDeselect={onAmenityDeselect}
                    icon={<AmenityIcon name={amenity.name} size={1.2} />}
                  />
                </Col>
              );
            })}
          </Row>
        </Form.Item>
      </Form>
      <ButtonFooter
        onBack={goBack}
        onNext={handleSubmit}
        nextDisabled={selectedAmenities.length === 0}
      />
    </>
  );
}

export default createFragmentContainer(SelectAmenitiesForm, {
  propertyDraft: graphql`
    fragment SelectAmenitiesForm_propertyDraft on PropertyDraftNode {
      amenities {
        edges{
            node {
                id
                name
            }
        }
      }
    }
  `,
  amenities: graphql`
    fragment SelectAmenitiesForm_amenities on AmenityConnection {
      edges {
        node {
          id
          name
        }
      }
    }
  `,
});
