export const getExpiresIn = () => {
  const token = getToken();
  if (token === null || token == 'null') {
    return Date.now();
  }

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  const payload = JSON.parse(jsonPayload);

  const expirationTime = new Date(payload.exp * 1000);

  return expirationTime;
};

export const setRefreshToken = (refreshToken) => localStorage.setItem('refreshToken', refreshToken);

export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const getToken = () => localStorage.getItem('authToken');

export const unsetToken = () => {
  localStorage.removeItem('authToken');
};

export const unsetRefreshToken = () => {
  localStorage.removeItem('refreshToken');
};

export const setToken = (token) => {
  localStorage.setItem('authToken', token);
};

export const logoutUser = () => {
  unsetToken();
  unsetRefreshToken();
};
