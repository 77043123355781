// ImageUploadReorder.js
import React, { useState, useEffect } from 'react';
import {
  Form, Upload, message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useStorage } from 'reactfire';
import {
  ref, getDownloadURL, uploadBytesResumable, getStorage,
} from 'firebase/storage';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import { useMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

const mutation = graphql`
  mutation ImageUploadReorderGetSignedUrlMutation($input: GetSignedFileURLMutationInput!) {
    getSignedFileUrl(input: $input) {
      url
      path
    }
  }
`;

function ImageUploadReorder({ initialImages, onImagesChange }) {
  const [isInitialised, setIsInitialised] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [commitGetSignedUrl] = useMutation(mutation);
  const storage = getStorage();

  useEffect(() => {
    if (isInitialised) {
      return;
    }

    const initialFileList = initialImages.map((image) => ({
      uid: image.id,
      name: image.url,
      status: 'done',
      url: image.url,
    }));
    setIsInitialised(true);
    setFileList(initialFileList);
    onImagesChange(initialFileList);
  }, [initialImages]);

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    onImagesChange(newFileList);
  };

  const updateFileStatus = (newFile, status, percent = 0, url = '') => {
    setFileList((prevFileList) => {
      const updatedFileList = prevFileList.map((file) => {
        const matchingFile = newFile.uid === file.uid;
        if (matchingFile) {
          // update the file status with the matching UID
          file.status = status;
          if (percent > 0) {
            file.percent = percent;
          }
          if (url) {
            file.url = url;
          }
        }
        return file;
      });
      onImagesChange(updatedFileList);
      return updatedFileList;
    });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return;
    }
    const isLt4M = file.size / 1024 / 1024 < 4;
    if (!isLt4M) {
      message.error('Image must be smaller than 4MB!');
      return;
    }

    const extension = file.name.split('.').pop();

    file.status = 'uploading';
    setFileList([...fileList, file]);

    commitGetSignedUrl({
      variables: {
        input: { extension },
      },
      onCompleted: (response, errors) => {
        if (errors) {
          message.error('Error getting upload URL');
          console.error(errors);
          return;
        }
        const signedUrl = response.getSignedFileUrl.url;
        const imagePath = response.getSignedFileUrl.path;
        const storageRef = ref(storage, imagePath);

        // Upload the file to the pre-signed URL
        fetch(signedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        })
          .then((response) => {
            if (response.ok) {
              getDownloadURL(storageRef)
                .then((url) => {
                  message.success('Upload succeeded!');
                  updateFileStatus(file, 'done', 100, url);
                })
                .catch((error) => {
                  message.error('Error retrieving image public URL');
                  updateFileStatus(file, 'error');
                  console.error('Error getting document', error);
                });
            } else {
              message.error('Upload failed!');
              updateFileStatus(file, 'error');
            }
          })
          .catch((error) => {
            message.error('Upload failed!');
            updateFileStatus(file, 'error');
            console.error(error);
          });
      },
      onError: (err) => {
        updateFileStatus(file, 'error');
        message.error('Error getting upload URL');
        console.error(err);
      },
    });
  };

  function DraggableUploadListItem({ originNode, file }) {
    const {
      attributes, listeners, setNodeRef, transform, transition, isDragging,
    } = useSortable({
      id: file.uid,
    });
    const style = {
      width: '102px',
      height: '102px',
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: 'move',
    };

    // prevent preview event when drag end
    const className = isDragging
      ? css`
        a {
          pointer-events: none;
        }
      `
      : '';
    return (
      <div ref={setNodeRef} style={style} className={className} {...attributes} {...listeners}>
        {/* hide error tooltip when dragging */}
        {file.status === 'error' && isDragging ? originNode.props.children : originNode}
      </div>
    );
  }

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        const newArrayList = arrayMove(prev, activeIndex, overIndex);

        onImagesChange(newArrayList);
        return newArrayList;
      });
    }
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 2,
    },
  });

  return (
    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
      <SortableContext items={fileList.map((i) => i.uid)} strategy={horizontalListSortingStrategy}>
        <Upload
          listType="picture-card"
          fileList={fileList}
          multiple
          beforeUpload={beforeUpload}
          customRequest={() => {}}
          onChange={handleChange}
          itemRender={(originNode, file) => (
            <DraggableUploadListItem originNode={originNode} file={file} />
          )}
        >
          {fileList.length >= 30 ? null : (
            <div>
              <UploadOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </SortableContext>
    </DndContext>
  );
}

export default ImageUploadReorder;
