/**
 * @generated SignedSource<<06cfb04d3e9b4222d5c2a5a5840ecf90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyCreatedSuccessScreen_propertyDraft",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PropertyNode",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PropertyDraftNode",
  "abstractKey": null
};

node.hash = "f83b23b6399be3f326f366f9984e67cb";

module.exports = node;
