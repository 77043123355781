import React from 'react';
import {
  Badge,
  Button,
  Card, List, message, Typography,
} from 'antd';
import { createFragmentContainer, useMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import Paragraph from 'antd/es/typography/Paragraph';
import { useNavigate } from 'react-router-dom';
import useCreatePropertyDraftMutation from 'src/mutations/useCreatePropertyDraftMutation';
import { BookOutlined, EditOutlined, FileTextOutlined } from '@ant-design/icons';
import { getHouseManualEditUrl, getPropertyEditUrl } from 'src/utils/urlUtils';
import PropertyCard from '../property/PropertyCard';

const { Title } = Typography;

function MyProperties({ user }) {
  const [commit, isInFlight] = useMutation(useCreatePropertyDraftMutation);
  const navigate = useNavigate();
  const hasUnfinishedDraft = user.hasUnfinishedDraft === true;

  const handleButtonClick = () => {
    if (hasUnfinishedDraft === true) {
      navigate('/draft_property');
    } else {
      commit({
        variables: {},
        onCompleted: (response, errors) => {
          if (errors) {
            message.error('Something went wrong!');
            console.log('Got errors', errors);
            return;
          }
          navigate('/draft_property');
        },
        onError: (error) => {
          message.error('Something went wrong!');
          console.error('CreatePropertyDraftMutation error:', error);
        },
      });
    }
  };

  const properties = user?.propertySet?.edges.map((edge) => edge.node) || null;

  return (
    <Card style={{ width: '100%' }} bodyStyle={{ paddingBottom: '8px' }}>
      <Title level={3} style={{ marginTop: 0 }}>My Properties</Title>
      <List
        dataSource={properties}
        grid={{
          gutter: 16, column: 3, xl: 3, lg: 2, md: 2, sm: 2, xs: 1,
        }}
        renderItem={(property) => {
          const actions = [];
          const propertyHasHouseManual = property?.hasHouseManual === true;
          const houseManualEditURL = getHouseManualEditUrl(property.id);
          const propertyEditURL = getPropertyEditUrl(property.id);

          actions.push(
            <Button
              type="link"
              onClick={(event) => {
                event.preventDefault();
                navigate(propertyEditURL);
              }}
            >
              <EditOutlined />
              {' '}
              Edit
            </Button>,
          );

          actions.push(
            <Badge dot={!propertyHasHouseManual}>
              <Button
                type="link"
                onClick={(event) => {
                  event.preventDefault();
                  navigate(houseManualEditURL);
                }}
              >
                <FileTextOutlined />
                {' '}
                Manual
              </Button>
            </Badge>,
          );

          return (
            <List.Item
              key={property.id}
            >
              <PropertyCard
                property={property}
                actions={actions}
              />
            </List.Item>
          );
        }}
      />
      {hasUnfinishedDraft
         && <Button onClick={handleButtonClick}>Finish your listing</Button>}
    </Card>
  );
}

export default createFragmentContainer(MyProperties, {
  user: graphql`
    fragment MyProperties_user on CustomUserNode {
      propertySet(first: 10) {
        edges {
          node {
            id
            hasHouseManual
            ...PropertyCard_property
          }
        }
      } 
      hasUnfinishedDraft
      unfinishedDraftId
    }
  `,
});
