import { RelayEnvironmentProvider } from 'react-relay';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { FirebaseAppProvider } from 'reactfire';
import RelayEnvironment from './RelayEnvironment';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://b2d13460f1764485a169a4bc6ef114b5@o4505336710823936.ingest.sentry.io/4505336712658944',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/platform\.nestwop\.com/,
        /^https:\/\/api\.nestwop\.com/,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDS5DBT38EGHtLrOEWCk7CB_jeFBEj503U',
  authDomain: 'nestwop.firebaseapp.com',
  projectId: 'nestwop',
  storageBucket: 'nestwop.appspot.com',
  messagingSenderId: '878288567345',
  appId: '1:878288567345:web:eb813e404a13ac81dee910',
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RelayEnvironmentProvider environment={RelayEnvironment}>
    <React.StrictMode>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <App />
      </FirebaseAppProvider>
    </React.StrictMode>
  </RelayEnvironmentProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
