import React, { useState } from 'react';
import { Form, Input } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer, useMutation } from 'react-relay';
import TextArea from 'antd/es/input/TextArea';
import ButtonFooter from './MultiStepButtonFooter';

const provideDescriptionFormMutation = graphql`
  mutation ProvideDescriptionFormMutation($input: SetDescriptionMutationInput!) {
    setDescription(input: $input) {
      propertyDraft {
        id
        description
      }
    }
  }
`;

function ProvideDescriptionForm({ propertyDraft, goBack, goNext }) {
  const [description, setDescription] = useState(propertyDraft.description || '');
  const [commit, isInFlight] = useMutation(provideDescriptionFormMutation);

  const handleSubmit = () => {
    commit({
      variables: {
        input: {
          description,
        },
      },
      onCompleted: () => {
        goNext();
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  return (
    <>
      <Form layout="vertical" style={{ padding: 16 }}>
        <Form.Item>
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter a description for your property"
            autoSize={{ minRows: 5 }}
            maxLength={3000}
          />
        </Form.Item>
      </Form>
      <ButtonFooter
        onBack={goBack}
        onNext={handleSubmit}
        nextDisabled={!description}
      />
    </>
  );
}

export default createFragmentContainer(ProvideDescriptionForm, {
  propertyDraft: graphql`
    fragment ProvideDescriptionForm_propertyDraft on PropertyDraftNode {
      id
      description
    }
  `,
});
