import React, { useState } from 'react';
import {
  Modal, Form, Radio, Input, Button, Typography, Divider, Alert, message,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

const { Text } = Typography;

const query = graphql`
  query PropertyReportModalQuery {
    reportReasons(first: 10) {
      edges {
        node {
          id
          title
          description
        }
      }
    }
  }
`;

const reportMutation = graphql`
  mutation PropertyReportModalMutation($input: ReportPropertyMutationInput!) {
    reportProperty(input: $input) {
      success
    }
  }
`;

function PropertyReportModal({ propertyId }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [commitMutation, isInFlight] = useMutation(reportMutation);

  const data = useLazyLoadQuery(query, {}, { fetchPolicy: 'store-and-network' });
  const reportReasons = data.reportReasons.edges.map((edge) => edge.node);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSave = (values) => {
    const input = {
      reportData: {
        propertyId,
        reasonId: values.reason,
        comment: values.comment,
      },
    };

    commitMutation({
      variables: { input },
      onCompleted: (response, errors) => {
        if (errors) {
          message.error(errors[0].message);
        } else {
          setIsSubmitted(true);
        }
      },
      onError: (error) => {
        message.error('Something went wrong');
        console.error(error);
      },
    });
  };

  return (
    <div>
      <Button icon={<ExclamationCircleOutlined />} type="text" onClick={showModal}>
        Report Listing
      </Button>

      <Modal
        title={isSubmitted ? 'Report received' : 'Report a listing'}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        {isSubmitted ? (
          <div>
            <Alert
              message="Thank you for your report"
              description="Your report has been received and will be reviewed by our team. Your privacy is important to us, and your report will remain confidential."
              type="success"
              showIcon
            />
            <div style={{ textAlign: 'right', marginTop: '20px' }}>
              <Button onClick={handleCancel}>
                Close
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Text type="secondary">Reporting is confidential. The owner will not be notified of the report.</Text>
            <Form onFinish={handleSave} layout="vertical" style={{ marginTop: 20 }}>
              <Form.Item name="reason" rules={[{ required: true, message: 'Please select a reason!' }]}>
                <Radio.Group>
                  {reportReasons.map((reason, index) => (
                    <div>
                      <Radio key={reason.id} value={reason.id} style={{ paddingLeft: 10 }}>
                        <div style={{ marginLeft: 10 }}>

                          <Text>{reason.title}</Text>
                          <br />
                          <Text type="secondary">{reason.description}</Text>
                        </div>
                      </Radio>
                      {index !== reportReasons.length - 1
                  && <Divider style={{ marginTop: 10, marginBottom: 10 }} />}
                    </div>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item name="comment" label="Additional comments">
                <Input.TextArea rows={4} />
              </Form.Item>

              <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
                <Button onClick={handleCancel} style={{ marginRight: '10px' }}>
                  Cancel
                </Button>

                <Button type="primary" htmlType="submit" disabled={isInFlight}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </div>
  );
}

export default PropertyReportModal;
