/**
 * @generated SignedSource<<0ef88b76b6ff8e27ecd953bdd74f2407>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectAvailabilitiesForm_propertyDraft",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "daysNoticeRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PropertyAvailabilityNodeConnection",
      "kind": "LinkedField",
      "name": "availabilities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PropertyAvailabilityNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PropertyAvailabilityNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "availableFrom",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "availableTo",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PropertyDraftNode",
  "abstractKey": null
};

node.hash = "fab480538eae7a1d3295879b3f8b3481";

module.exports = node;
