import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QueryRenderer, useRelayEnvironment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  Alert,
  Badge,
  Button,
  Card, Col, Descriptions, Divider, message, Row, Skeleton, Typography,
} from 'antd';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { getHouseManualEditUrl, getPropertyUrl } from 'src/utils/urlUtils';
import PropertyImagesCarousel from '../property/PropertyImagesCarousel';
import { formatDateToString } from '../../utils/calendarUtils';
import HouseManualDetails from '../property/HouseManualDetails';
import ReservationCancelButton from './ReservationCancelButton';
import ReservationFeedbackModal from './ReservationFeedbackModal';
import UserAvatarGroup from '../user/UserAvatarGroup';
import ReservationCompanionsUpdateButton from './ReservationCompanionsUpdateButton';

const { Title } = Typography;

const ReservationDetailsQuery = graphql`
    query ReservationDetailsQuery($id: ID!) {
        reservation(id: $id) {
            id
            checkInDate
            checkOutDate
            status
            canCancel
            nights
            canProvideFeedback
            isLateCancellation
            isViewerGuest
            guest {
              firstName
              lastName
              company {
                name
              }
            }
            isViewerGuest
            isViewerHost
            areCompanionsConfigured
            companions {
              edges{
                node {
                  firstName
                  lastName
                }
              }
            }
            property {
              id
              city
              streetAddress
              postalCode
              isOwner
              hasHouseManual
              owner {
                firstName
                lastName
                company {
                  name
                }
              }
              country
              latitude
              longitude
              ...PropertyImagesCarousel_property
              ...HouseManualDetails_property
            }
            ...ReservationCompanionsUpdateButton_reservation
        }
    }
`;

function formatStatus(status) {
  if (!status) return '';

  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
}

function ReservationStatus({ status }) {
  let badgeStatus;
  switch (status) {
    case 'BOOKED':
      badgeStatus = 'processing';
      break;
    case 'CANCELLED':
      badgeStatus = 'error';
      break;
    case 'COMPLETED':
      badgeStatus = 'success';
      break;
    default:
      badgeStatus = 'default';
  }

  return (
    <Badge status={badgeStatus} text={formatStatus(status)} />
  );
}

function ReservationDetails() {
  const { id } = useParams();
  const environment = useRelayEnvironment();
  const [hovered, setHovered] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const toggleHover = () => setHovered(!hovered);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA25x-2K58jI2YByiq72l4ikwAcypprtus',
    libraries: ['places'],
    language: 'en',
  });

  const onCancellation = () => {
    // Refresh or do whatever you want after the cancellation is complete
    message.success('Reservation cancelled');
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const navigate = useNavigate();

  const titleStyle = {
    marginTop: 0,
    cursor: 'pointer',
    textDecoration: hovered ? 'underline' : 'none',
  };

  return (
    <QueryRenderer
      environment={environment}
      query={ReservationDetailsQuery}
      variables={{ id }}
      render={({ error, props }) => {
        if (error) {
          throw error;
        }

        if (props) {
          const { reservation } = props;
          const center = {
            lat: parseFloat(reservation.property.latitude),
            lng: parseFloat(reservation.property.longitude),
          };

          const isReservationCancelled = reservation.status === 'CANCELLED';
          const mapOptions = isReservationCancelled
            ? { draggable: false, scrollwheel: false, disableDoubleClickZoom: true } : {};

          const guests = [reservation.guest];
          const companions = reservation?.companions?.edges.map((edge) => edge.node);

          function renderCompanionsSection(reservation) {
            const {
              isViewerGuest, isViewerHost, areCompanionsConfigured,
            } = reservation;

            if (isViewerGuest) {
              return (
                <div style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
                }}
                >
                  {companions.length === 0 && <Typography.Text>No Companions</Typography.Text>}
                  {companions.length > 0 && <UserAvatarGroup users={companions} />}
                  <ReservationCompanionsUpdateButton
                    reservationRef={reservation}
                    isIconOnly
                  />
                </div>
              );
            }

            if (isViewerHost) {
              if (!areCompanionsConfigured) {
                return <Badge count="Awaiting Details" style={{ backgroundColor: '#52c41a' }} />;
              }

              if (companions.length === 0) {
                return <Typography.Text>No Companions</Typography.Text>;
              }

              return <UserAvatarGroup users={companions} />;
            }

            return null;
          }

          return (
            <Row style={{ maxHeight: 'calc(100vh - 66px)', height: 'calc(100vh - 66px)' }}>
              {isReservationCancelled && (
              <Col span={24}>
                <Alert message="This reservation has been cancelled." banner type="error" />
              </Col>
              )}
              <Col md={8} sm={24}>
                <div
                  id="scrollableDiv"
                  style={{
                    height: 'calc(100vh - 66px)',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    opacity: isReservationCancelled ? 0.5 : 1,
                  }}
                >
                  <Card
                    style={{ width: '100%' }}
                    bordered={false}
                    cover={(
                      <PropertyImagesCarousel property={reservation.property} />
                  )}
                  >

                    <Title
                      level={3}
                      onMouseLeave={toggleHover}
                      onMouseEnter={toggleHover}
                      style={titleStyle}
                      onClick={() => navigate(getPropertyUrl(reservation.property.id))}
                    >
                      {`${reservation.property.city}, ${reservation.property.country}`}
                    </Title>
                    {reservation.canProvideFeedback && (
                      <>
                        <Button style={{ marginBottom: 20 }} type="primary" block onClick={openModal}>Give Feedback</Button>
                        <ReservationFeedbackModal
                          isVisible={modalVisible}
                          toggleModal={setModalVisible}
                          reservationId={reservation.id}
                          nights={reservation.nights}
                          guestName={reservation.guest.firstName}
                        />
                      </>
                    )}
                    <Descriptions bordered size="small">
                      <Descriptions.Item label="Street Address" span={3}>{reservation.property.streetAddress}</Descriptions.Item>
                      <Descriptions.Item label="Post Code" span={3}>{reservation.property.postalCode}</Descriptions.Item>
                      <Descriptions.Item label="Host" span={3}>
                        <div style={{
                          display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
                        }}
                        >
                          <UserAvatarGroup users={[reservation.property.owner]} />
                          <Typography.Text>{reservation.property.owner.company.name}</Typography.Text>
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item label="Guest" span={3}>
                        <div style={{
                          display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
                        }}
                        >
                          <UserAvatarGroup users={guests} />
                          <Typography.Text>{reservation.guest.company.name}</Typography.Text>
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item label="Companions" span={3}>
                        {renderCompanionsSection(reservation)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Check-in" span={3}>{formatDateToString(reservation.checkInDate, 'DD MMM YYYY')}</Descriptions.Item>
                      <Descriptions.Item label="Check-out" span={3}>{formatDateToString(reservation.checkOutDate, 'DD MMM YYYY')}</Descriptions.Item>
                      <Descriptions.Item label="Status" span={3}><ReservationStatus status={reservation.status} /></Descriptions.Item>
                    </Descriptions>

                    <Divider />
                    <Title level={4} style={{ marginTop: 0 }}>House Manual</Title>

                    <HouseManualDetails property={reservation.property} />

                    {
                      reservation.property.isOwner === true && (
                      <Row style={{ marginTop: '16px' }}>
                        <Col span={24}>
                          <Button
                            block
                            type="primary"
                            href={getHouseManualEditUrl(reservation.property.id)}
                          >
                            {reservation.property.hasHouseManual === true ? 'Edit House Manual' : 'Create House Manual'}
                          </Button>
                        </Col>
                      </Row>
                      )
                    }

                    <Divider />

                    {reservation.canCancel && (
                    <ReservationCancelButton
                      type="default"
                      block
                      buttonText="Cancel Reservation"
                      reservation={reservation}
                      onCancellation={onCancellation}
                      isLateCancellation={reservation.isLateCancellation}
                      isViewerGuest={reservation.isViewerGuest}
                    />
                    )}
                  </Card>
                </div>
              </Col>
              <Col md={16} xs={24}>
                <div style={{
                  height: '100%', minHeight: '200px', width: '100%', opacity: isReservationCancelled ? 0.5 : 1,
                }}
                >
                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={{ height: '100%', width: '100%' }}
                      center={center}
                      options={mapOptions}
                      zoom={15}
                    >
                      <MarkerF position={center} />
                    </GoogleMap>
                  )}
                </div>
              </Col>
            </Row>
          );
        }
        return (
          <Row style={{ maxHeight: 'calc(100vh - 66px)', height: 'calc(100vh - 66px)' }}>
            <Col span={8}>
              <Row gutter={[16, 16]} style={{ padding: 20 }}>
                <Col span={24}>
                  <Card loading />
                </Col>
                <Col span={24}>
                  <Skeleton active />
                </Col>
              </Row>
            </Col>
            <Col span={16}>
              <div style={{ backgroundColor: '#ccc', width: '100%', height: '100%' }} />
            </Col>
          </Row>
        );
      }}
    />
  );
}

export default ReservationDetails;
