import React, { useState } from 'react';
import { Form, DatePicker } from 'antd';

function AvailabilityInput({
  dateRanges, handleDateRangeChange, disabledDate, label = 'Availability',
}) {
  const [newRange, setNewRange] = useState(null);
  return (
    <Form.Item label={label}>
      <DatePicker.RangePicker
        size="large"
        value={newRange}
        onChange={(dates) => {
          setNewRange(null);
          handleDateRangeChange(dateRanges.length, dates);
        }}
        disabledDate={disabledDate}
      />
    </Form.Item>
  );
}

export default AvailabilityInput;
