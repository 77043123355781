import React from 'react';
import {
  Badge,
  Card, Divider, Space, Tooltip, Typography,
} from 'antd';
import { Link, useLocation } from 'react-router-dom'; // Add this import
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';
import { getDateRangeFromUrl } from 'src/utils/urlUtils';
import PropertyImagesCarousel from './PropertyImagesCarousel';

dayjs.extend(utc);

const availabilitiesToString = (availabilities) => {
  if (availabilities.length === 0) {
    return '';
  }

  const availability = availabilities[0];

  return (
    <div>
      {dayjs.utc(availability.availableFrom).format('D MMM')}
      {' '}
      -
      {' '}
      {dayjs.utc(availability.availableTo).format('D MMM')}
    </div>
  );
};

const isWithinAvailability = (from, to, availabilities) => availabilities.some(
  (availability) => (
    (from.isAfter(dayjs.utc(availability.availableFrom)) || from.isSame(dayjs.utc(availability.availableFrom, 'day')))
  && (to.isBefore(dayjs.utc(availability.availableTo)) || to.isSame(dayjs.utc(availability.availableTo, 'day')))),
);

function PropertyCard({
  property, relevantAvailabilities = null, actions = null, imageSize = 'normal',
}) {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const [fromDate, toDate] = getDateRangeFromUrl(location);

  const isDateWithinAvailabilities = (relevantAvailabilities != null && fromDate != null && toDate != null)
    ? isWithinAvailability(fromDate, toDate, relevantAvailabilities) : true;

  const dot = <span style={{ margin: '0px 4px' }}>&middot;</span>;

  return (
    <Link to={`/properties/${property.id}?${queryParams.toString()}`}>
      <Card
        size="small"
        style={{ width: '100%' }}
        cover={(
          <PropertyImagesCarousel property={property} imageSize={imageSize} />
        )}
        actions={
          actions
        }
      >
        <Typography.Text strong ellipsis>{property.title}</Typography.Text>
        <br />
        <Typography.Text type="secondary" ellipsis>{property.description}</Typography.Text>
        <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
        <Typography.Text type="secondary" ellipsis>
          {property.numRooms}
          {' '}
          {property.numRooms === 1 ? 'room' : 'rooms'}
          {dot}
          {property.numGuests}
          {' '}
          {property.numGuests === 1 ? 'guest' : 'guests'}
        </Typography.Text>
        {relevantAvailabilities && (
        <Space style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
        >
          <Typography.Text>{availabilitiesToString(relevantAvailabilities)}</Typography.Text>
          <Tooltip title={isDateWithinAvailabilities ? 'Dates available' : 'Dates partially available'}>
            <Badge status={isDateWithinAvailabilities ? 'success' : 'warning'} />
          </Tooltip>
        </Space>
        )}
      </Card>
    </Link>
  );
}

export default createFragmentContainer(PropertyCard, {
  property: graphql`
    fragment PropertyCard_property on PropertyNode {
      id
      title
      description
      numRooms
      numGuests
      ...PropertyImagesCarousel_property
    }
  `,
});
