import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer, useMutation } from 'react-relay';
import PropertyIcon from 'src/components/common/PropertyIcon';
import ButtonFooter from './MultiStepButtonFooter';
import CustomSelectCard from '../../../common/CustomSelectCard';

function SelectPropertyTypeForm({
  propertyDraft, propertyTypes, goBack, goNext,
}) {
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);

  useEffect(() => {
    if (propertyDraft.propertyType) {
      setSelectedPropertyType(propertyDraft.propertyType.id);
    }
  }, [propertyDraft.propertyType]);

  const onPropertyTypeSelect = (id) => {
    setSelectedPropertyType(id);
  };

  const [commitSelectPropertyType] = useMutation(graphql`
    mutation SelectPropertyTypeFormMutation($input: SetPropertyTypeMutationInput!) {
      setPropertyType(input: $input) {
        propertyDraft {
          propertyType {
            id
            name
          }
          step
        }
      }
    }
  `);

  const handleSubmit = () => {
    commitSelectPropertyType({
      variables: {
        input: {
          propertyTypeId: selectedPropertyType,
        },
      },
      onCompleted: () => {
        goNext();
      },
      onError: (error) => {
        console.error('Error during mutation:', error);
      },
    });
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item>
        <Row gutter={[16, 16]} style={{ padding: 16 }}>
          {propertyTypes.edges.map(({ node: propertyType }) => {
            const isSelected = selectedPropertyType === propertyType.id;
            return (
              <Col md={8} xs={12} key={propertyType.id}>
                <CustomSelectCard
                  id={propertyType.id}
                  name={propertyType.name}
                  selected={isSelected}
                  onSelect={onPropertyTypeSelect}
                  onDeselect={() => { }}
                  icon={<PropertyIcon propertyType={propertyType.name} size={1.2} />}
                />
              </Col>
            );
          })}
        </Row>
      </Form.Item>
      <Form.Item>
        <ButtonFooter
          onBack={goBack}
          onNext={handleSubmit}
          nextDisabled={!selectedPropertyType}
          backButtonHidden
        />
      </Form.Item>
    </Form>
  );
}

export default createFragmentContainer(SelectPropertyTypeForm, {
  propertyTypes: graphql`
    fragment SelectPropertyTypeForm_propertyTypes on PropertyTypeConnection {
      edges {
        node {
          id
          name
        }
      }
    }
  `,
  propertyDraft: graphql`
    fragment SelectPropertyTypeForm_propertyDraft on PropertyDraftNode {
      propertyType {
        id
      }
    }
  `,
});
