import React from 'react';
import {
  Card, Col, List, message, Row, Space, Typography,
} from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer, useMutation } from 'react-relay';
import Title from 'antd/es/typography/Title';
import {
  EnvironmentOutlined, HomeOutlined, UserOutlined, ClockCircleOutlined, MailOutlined,
} from '@ant-design/icons';
import ButtonFooter from './MultiStepButtonFooter';

const { Text } = Typography;

// Important to include the me query as otherwise if we don't update the hasCompletedPropertySetup
// the user will keep getting redirected to the draft page
const finalReviewFormMutation = graphql`
  mutation FinalReviewFormMutation($input: CompleteDraftMutationInput!) {
    completeDraft(input: $input) {
      propertyDraft {
        id
        step
        ...PropertyCreatedSuccessScreen_propertyDraft
      }
      me {
        id
        hasCompletedPropertySetup
      }
    }
  }
`;

function FinalReviewForm({ propertyDraft, goBack, goNext }) {
  const dot = <span style={{ margin: '0px 4px' }}>&middot;</span>;

  const [commit, isInFlight] = useMutation(finalReviewFormMutation);

  const handleSubmit = () => {
    commit({
      variables: {
        input: {},
      },
      onCompleted: () => {
        goNext();
      },
      onError: (error) => {
        message.error('Something went wrong');
        console.error('Error:', error);
      },
    });
  };

  const guestsText = (
    <Typography.Text>
      {propertyDraft.numRooms}
      {' '}
      {propertyDraft.numRooms === 1 ? 'room' : 'rooms'}
      {dot}
      {propertyDraft.numGuests}
      {' '}
      {propertyDraft.numGuests === 1 ? 'guest' : 'guests'}
    </Typography.Text>
  );

  return (
    <>
      <Card style={{ marginBottom: 16 }} bodyStyle={{ paddingTop: 12, paddingBottom: 12 }}>
        <List
          itemLayout="horizontal"
          dataSource={[
            { label: 'Title', value: propertyDraft.title, icon: <HomeOutlined /> },
            { label: 'Street Address', value: propertyDraft.streetAddress, icon: <MailOutlined /> },
            { label: 'City', value: propertyDraft.city, icon: <EnvironmentOutlined /> },
            { label: 'Guests', value: guestsText, icon: <UserOutlined /> },
            // Add other data fields similarly
          ]}
          renderItem={(item) => (
            <List.Item>
              <Col span={24}>
                <Row>
                  <Space>
                    {item.icon}
                    <Text strong>{item.label}</Text>
                  </Space>
                </Row>
                <Row>
                  <Text style={{ marginLeft: 25 }}>{item.value}</Text>
                </Row>
              </Col>
            </List.Item>
          )}
        />
      </Card>

      <ButtonFooter
        onBack={goBack}
        onNext={handleSubmit}
        nextDisabled={false}
        nextButtonText="Submit"
      />
    </>
  );
}

export default createFragmentContainer(FinalReviewForm, {
  propertyDraft: graphql`
    fragment FinalReviewForm_propertyDraft on PropertyDraftNode {
      id
      title
      streetAddress
      city
      country
      daysNoticeRequired
      numRooms
      numGuests
    }
  `,
});
