import { useMutation } from 'react-relay/hooks';
import graphql from 'babel-plugin-relay/macro';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button, Card, Form, Input, message, Modal, Typography,
} from 'antd';
import { CheckCircleOutlined, LockOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

const { Title } = Typography;

const PasswordResetMutation = graphql`
  mutation NewPasswordPageMutation($input: PasswordResetInput!) {
    passwordReset(input: $input) {
      success
      errors
    }
  }
`;

function NewPasswordPage() {
  const [commit, isInFlight] = useMutation(PasswordResetMutation);
  const [formErrors, setFormErrors] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const onFinish = ({ password, confirmPassword }) => {
    const token = new URLSearchParams(location.search).get('token');

    commit({
      variables: {
        input: {
          newPassword1: password,
          newPassword2: confirmPassword,
          token,
        },
      },
      onCompleted: (data) => {
        if (data.passwordReset.success) {
          // Redirect user to login page or show success message
          setFormErrors({}); // clear the form errors
          setIsModalVisible(true);
        } else {
          // Show some error message
          message.error('Something went wrong!');
          const newFormErrors = {};
          Object.keys(data.passwordReset.errors).forEach((key) => {
            newFormErrors[key] = data.passwordReset.errors[key].map((error, index) => (
              <React.Fragment key={index}>
                {index > 0 && <br />}
                {error.message}
              </React.Fragment>
            ));
          });
          setFormErrors(newFormErrors);
        }
      },
      onError: (err) => {
        // Handle error
        message.error('Error encountered!');
      },
    });
  };

  const handleOk = () => {
    // Redirect user to login page
    setIsModalVisible(false);
    navigate('/login'); // Assuming '/login' is your login page
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Modal
        title={(
          <>
            <CheckCircleOutlined style={{ color: '#52c41a' }} />
            {' '}
            Password Reset Successfully
          </>
        )}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        okText="Go to Login" // Changes the text of the Ok button to "Go to Login"
        cancelButtonProps={{ style: { display: 'none' } }} // Hides the cancel button
      >
        <p>Your password has been reset successfully. Click "Go to Login" to login with your new password.</p>
      </Modal>
      <Card style={{ width: '400px', marginTop: '50px' }}>
        <Title style={{ textAlign: 'center', marginTop: 0 }}>Reset Password</Title>
        <Form onFinish={onFinish}>
          <Form.Item
            name="password"
            help={formErrors.newPassword1}
            rules={[{ required: true, message: 'Please input your password' }]}
            validateStatus={formErrors.newPassword1 ? 'error' : ''}

          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="New Password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            help={formErrors.newPassword2}
            rules={[{ required: true, message: 'Please confirm your password' }]}
            validateStatus={formErrors.newPassword2 ? 'error' : ''}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Confirm New Password"
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" loading={isInFlight}>Reset Password</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default NewPasswordPage;
