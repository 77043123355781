/**
 * @generated SignedSource<<7c35f73c13dedcad6812d027ec215fb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateReservationMutationPayload",
    "kind": "LinkedField",
    "name": "createReservation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReservationNode",
        "kind": "LinkedField",
        "name": "reservation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyReserveCard_CreateReservationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropertyReserveCard_CreateReservationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8c073455fc923e1d864d5c7d62c9241b",
    "id": null,
    "metadata": {},
    "name": "PropertyReserveCard_CreateReservationMutation",
    "operationKind": "mutation",
    "text": "mutation PropertyReserveCard_CreateReservationMutation(\n  $input: CreateReservationMutationInput!\n) {\n  createReservation(input: $input) {\n    reservation {\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "9945b694fba5309864623dae85e4bef6";

module.exports = node;
