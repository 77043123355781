export const customMapStyles = [
    {
        featureType: 'poi.business',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                lightness: '-1',
            },
        ],
    },
    {
        featureType: 'poi.medical',
        elementType: 'geometry.fill',
        stylers: [
            {
                lightness: '-6',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#a6ce95',
            },
            {
                lightness: '44',
            },
        ],
    },
    {
        featureType: 'poi.school',
        elementType: 'geometry',
        stylers: [
            {
                color: '#be8b7b',
            },
            {
                lightness: '69',
            },
        ],
    },
    {
        featureType: 'poi.sports_complex',
        elementType: 'geometry',
        stylers: [
            {
                color: '#4d4b46',
            },
            {
                lightness: '59',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
            {
                saturation: '22',
            },
            {
                lightness: '9',
            },
            {
                gamma: '-1.8',
            },
            {
                hue: '#b037777777777ff',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#a1daf2',
            },
        ],
    },
];

export const mapOptions = {
    styles: customMapStyles,
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true,
};

