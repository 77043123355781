/**
 * @generated SignedSource<<7721e14896faa5bd1762f18d3f8eb288>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ReservationEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReservationNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReservationList_reservations"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyPropertyReservations_user",
  "selections": [
    {
      "alias": null,
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "PropertyNodeConnection",
      "kind": "LinkedField",
      "name": "propertySet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PropertyNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PropertyNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "city",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "country",
                  "storageKey": null
                },
                {
                  "alias": "reservations_UPCOMING",
                  "args": [
                    (v0/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "statusFilter",
                      "value": "UPCOMING"
                    }
                  ],
                  "concreteType": "ReservationConnection",
                  "kind": "LinkedField",
                  "name": "reservations",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": "reservations(first:10,statusFilter:\"UPCOMING\")"
                },
                {
                  "alias": "reservations_ONGOING",
                  "args": [
                    (v0/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "statusFilter",
                      "value": "ONGOING"
                    }
                  ],
                  "concreteType": "ReservationConnection",
                  "kind": "LinkedField",
                  "name": "reservations",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": "reservations(first:10,statusFilter:\"ONGOING\")"
                },
                {
                  "alias": "reservations_PAST_NOT_COMPLETED",
                  "args": [
                    (v0/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "statusFilter",
                      "value": "PAST_NOT_COMPLETED"
                    }
                  ],
                  "concreteType": "ReservationConnection",
                  "kind": "LinkedField",
                  "name": "reservations",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": "reservations(first:10,statusFilter:\"PAST_NOT_COMPLETED\")"
                },
                {
                  "alias": "reservations_PAST",
                  "args": [
                    (v0/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "sortByCheckout",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "statusFilter",
                      "value": "PAST"
                    }
                  ],
                  "concreteType": "ReservationConnection",
                  "kind": "LinkedField",
                  "name": "reservations",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": "reservations(first:10,sortByCheckout:true,statusFilter:\"PAST\")"
                },
                {
                  "alias": "reservations_CANCELLED",
                  "args": [
                    (v0/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "statusFilter",
                      "value": "CANCELLED"
                    }
                  ],
                  "concreteType": "ReservationConnection",
                  "kind": "LinkedField",
                  "name": "reservations",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": "reservations(first:10,statusFilter:\"CANCELLED\")"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "propertySet(first:10)"
    }
  ],
  "type": "CustomUserNode",
  "abstractKey": null
};
})();

node.hash = "539a08a228b8f89a271de2f625608987";

module.exports = node;
