import React, {
  Suspense,
  useEffect, useState,
} from 'react';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useJsApiLoader } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';

import {
  Card, Col, Row,
} from 'antd';
import {
  getDateRangeFromUrl, getGuestsNumberFromUrl, getNorthEastFromUrl, getSouthWestFromUrl, getViewportFromUrl,
} from 'src/utils/urlUtils';
import PropertySearchResults from './PropertySearchResults';

const PropertyListQuery = graphql`
  query PropertySearchPagePropertyQuery($northEast: [Float], $southWest: [Float], $availableFrom: Date, $availableTo: Date, $numGuests: Int) {
    ...PropertySearchResults_query
  }
`;

function PropertySearchPage() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA25x-2K58jI2YByiq72l4ikwAcypprtus',
    libraries: ['places'],
    language: 'en',
  });
  const location = useLocation();

  const defaultCenter = { lat: 51.4984, lng: -0.1562 };

  const [viewport, setViewport] = useState(null);
  const [dateRange, setDateRange] = useState(getDateRangeFromUrl(location));

  const defaultNorthEast = getNorthEastFromUrl(location);
  const defaultSouthWest = getSouthWestFromUrl(location);
  const guests = getGuestsNumberFromUrl(location);

  // Initialize viewport and dateRange from URL query parameters
  useEffect(() => {
    if (isLoaded) {
      const initialViewport = getViewportFromUrl(location, window.google);
      setViewport(initialViewport);
    }
    setDateRange(getDateRangeFromUrl(location));
  }, [isLoaded, location]);

  const currentNorthEastFilter = viewport?.getNorthEast() != null
    ? [viewport.getNorthEast().lat(), viewport.getNorthEast().lng()] : defaultNorthEast;
  const currentSouthWestFilter = viewport?.getSouthWest() != null
    ? [viewport.getSouthWest().lat(), viewport.getSouthWest().lng()] : defaultSouthWest;

  const variables = {
    northEast: currentNorthEastFilter,
    southWest: currentSouthWestFilter,
    availableFrom: dateRange.length > 0 ? dateRange[0]?.toISOString().split('T')[0] : null,
    availableTo: dateRange.length > 0 ? dateRange[1]?.toISOString().split('T')[0] : null,
    numGuests: guests,
  };

  const data = useLazyLoadQuery(PropertyListQuery, variables);

  const loadingSkeletonComponent = (
    <Row style={{ maxHeight: 'calc(100vh - 66px)', height: 'calc(100vh - 66px)' }}>
      <Col xs={24} sm={24} lg={15}>
        <Row gutter={[16, 16]} style={{ padding: 20 }}>
          <Col lg={8} xs={24} sm={12}>
            <Card loading />
          </Col>
          <Col lg={8} xs={24} sm={12}>
            <Card loading />
          </Col>
          <Col lg={8} xs={24} sm={12}>
            <Card loading />
          </Col>
        </Row>
      </Col>
      <Col md={0} lg={9}>
        <div style={{ backgroundColor: '#ccc', width: '100%', height: '100%' }} />
      </Col>
    </Row>
  );

  if (!isLoaded) {
    return loadingSkeletonComponent;
  }

  return (
    <Suspense fallback={loadingSkeletonComponent}>
      <PropertySearchResults
        query={data}
        defaultMapCenter={defaultCenter}
        viewport={viewport}
      />
    </Suspense>
  );
}

export default PropertySearchPage;
