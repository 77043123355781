/**
 * @generated SignedSource<<7d3ebe63977c133e6d966ed3d0d1359f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latitude",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longitude",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOwner",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PropertyAvailabilityNode",
  "kind": "LinkedField",
  "name": "relevantAvailabilities",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableFrom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableTo",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = [
  (v11/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyDetailsPropertyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PropertyNode",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserSummary_user"
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PropertyImagesMasonry_property"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PropertyAmenities_property"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PropertySummary_property"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PropertyTypeSummary_property"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PropertyReserveCard_customUserNode"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropertyDetailsPropertyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PropertyNode",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyNode",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ImageConnection",
            "kind": "LinkedField",
            "name": "orderedImages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImageNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AmenityNodeConnection",
            "kind": "LinkedField",
            "name": "amenities",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AmenityNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AmenityNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v11/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numGuests",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numRooms",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numBathrooms",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyTypeNode",
            "kind": "LinkedField",
            "name": "propertyType",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nights",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8261904790b1f68af57de795cad113b",
    "id": null,
    "metadata": {},
    "name": "PropertyDetailsPropertyQuery",
    "operationKind": "query",
    "text": "query PropertyDetailsPropertyQuery(\n  $id: ID!\n) {\n  property(id: $id) {\n    id\n    title\n    description\n    latitude\n    longitude\n    city\n    country\n    isOwner\n    owner {\n      ...UserSummary_user\n      id\n    }\n    relevantAvailabilities {\n      id\n      availableFrom\n      availableTo\n    }\n    ...PropertyImagesMasonry_property\n    ...PropertyAmenities_property\n    ...PropertySummary_property\n    ...PropertyTypeSummary_property\n  }\n  me {\n    ...PropertyReserveCard_customUserNode\n    id\n  }\n}\n\nfragment PropertyAmenities_property on PropertyNode {\n  id\n  amenities {\n    edges {\n      node {\n        id\n        name\n        description\n      }\n    }\n  }\n}\n\nfragment PropertyImagesMasonry_property on PropertyNode {\n  id\n  orderedImages {\n    edges {\n      node {\n        id\n        url\n      }\n    }\n  }\n}\n\nfragment PropertyReserveCard_customUserNode on CustomUserNode {\n  nights\n}\n\nfragment PropertySummary_property on PropertyNode {\n  numGuests\n  numRooms\n  numBathrooms\n}\n\nfragment PropertyTypeSummary_property on PropertyNode {\n  owner {\n    username\n    id\n  }\n  propertyType {\n    name\n    id\n  }\n}\n\nfragment UserSummary_user on UserNode {\n  username\n  company {\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "9c5d5d95a2f434087d1bea4b739ce882";

module.exports = node;
