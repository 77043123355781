/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import {
  Card,
  Divider,
  Form, Space, Typography,
} from 'antd';
import { useMutation } from 'react-relay/hooks';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { CameraOutlined, FileTextOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ButtonFooter from './MultiStepButtonFooter';
import ImageUploadReorder from '../common/ImageUploadReorder';

const updateUploadedPhotosMutation = graphql`
  mutation UploadPhotosFormMutation($input: UpdateUploadedPhotosMutationInput!) {
    updateUploadedPhotos(input: $input) {
      propertyDraft {
        id
        orderedImages {
            edges {
                node {
                  id
                  url
                }
            }
        }
      }
    }
  }
`;

function UploadPhotosForm({ propertyDraft, goBack, goNext }) {
  const [fileList, setFileList] = useState([]);
  const [commit, isInFlight] = useMutation(updateUploadedPhotosMutation);
  const successfulUploadsCount = fileList.filter((file) => file.status === 'done').length;

  const initialImages = propertyDraft.orderedImages.edges.map(({ node }) => ({
    id: node.id,
    url: node.url,
  }));

  const handleImagesChange = (newFileList) => {
    setFileList(newFileList);
  };

  const onFinish = async () => {
    const imageUrls = fileList
      .filter((file) => file.status === 'done')
      .map((file) => file.url);
    commit({
      variables: {
        input: {
          imageUrls,
        },
      },
      onCompleted: () => {
        goNext();
      },
    });
  };

  // Min photos required
  const minPhotosRequired = 5;
  const remainingPhotosRequired = minPhotosRequired - successfulUploadsCount;

  return (
    <>
      <Form onFinish={onFinish} style={{ padding: 16 }}>
        <Card style={{ marginBottom: '20px' }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text>
              <CameraOutlined />
              {' '}
              Add at least
              {' '}
              <Typography.Text strong>
                {minPhotosRequired}
                {' '}
                photos
              </Typography.Text>
              {' '}
              to showcase your property.
            </Typography.Text>
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            <Typography.Text>
              <FileTextOutlined />
              {' '}
              Photos should be in
              {' '}
              <strong>JPG or PNG</strong>
              {' '}
              format and not exceed
              {' '}
              <strong>4MB</strong>
              {' '}
              in size.
            </Typography.Text>
            {remainingPhotosRequired > 0
            && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}
            {remainingPhotosRequired > 0
            && (
            <Typography.Text>
              <InfoCircleOutlined />
              {' '}
              You still need to upload at least
              {' '}
              <strong>{remainingPhotosRequired}</strong>
              {' '}
              more photo(s).
            </Typography.Text>
            )}
          </Space>
        </Card>
        <Form.Item>
          <ImageUploadReorder
            initialImages={initialImages}
            onImagesChange={handleImagesChange}
          />
        </Form.Item>
        {/* <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
      >
        <Masonry>
          {fileList.map((file) => (
            <Image key={file.uid} src={file.url} />
          ))}
        </Masonry>
      </ResponsiveMasonry> */}

      </Form>
      <ButtonFooter
        onNext={successfulUploadsCount < 5 ? null : onFinish}
        onBack={goBack}
        nextDisabled={isInFlight || successfulUploadsCount < 5}
      />
    </>
  );
}

export default createFragmentContainer(UploadPhotosForm, {
  propertyDraft: graphql`
    fragment UploadPhotosForm_propertyDraft on PropertyDraftNode {
      id
      orderedImages {
        edges {
          node {
            id
            url
          }
        }
      }
    }
  `,
});
