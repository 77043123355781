/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useRef } from 'react';
import { Button, Carousel } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

function PropertyImagesCarousel({ property, imageSize = 'normal' }) {
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const images = property?.orderedImages?.edges?.map((edge) => edge.node);

  function Arrow({ direction }) {
    const ArrowButton = direction === 'left' ? LeftOutlined : RightOutlined;
    const isDisabled = direction === 'left' ? currentSlide === 0 : currentSlide === images.length - 1;

    const handleClick = (e) => {
      e.preventDefault();
      if (!((direction === 'left' && currentSlide === 0) || (direction === 'right' && currentSlide === images.length - 1))) {
        direction === 'left' ? carouselRef.current.prev() : carouselRef.current.next();
      }
    };

    return (
      <Button
        onClick={handleClick}
        shape="circle"
        icon={<ArrowButton />}
        style={{
          position: 'absolute',
          top: '50%',
          [direction]: '10px',
          transform: 'translateY(-50%)',
          zIndex: 1,
          opacity: isDisabled ? '0.5' : '1',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.20)',
          visibility: isHovering ? 'visible' : 'hidden',
        }}
      />
    );
  }

  const handleChange = (_currentSlide, nextSlide) => {
    setCurrentSlide(nextSlide);
  };

  return (
    <div
      style={{ width: '100%', position: 'relative' }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Carousel
        ref={carouselRef}
        beforeChange={handleChange}
      >
        {images.map((currentImage) => (
          <div
            key={currentImage.id}
          >
            <img
              alt={property.title}
              src={currentImage.url}
              style={{
                width: '100%',
                height: imageSize === 'normal' ? '225px' : '150px',
                objectFit: 'cover',
              }}
            />
          </div>
        ))}
      </Carousel>
      <Arrow direction="left" />
      <Arrow direction="right" />
    </div>
  );
}

export default createFragmentContainer(PropertyImagesCarousel, {
  property: graphql`
    fragment PropertyImagesCarousel_property on PropertyNode {
      id
      orderedImages {
        edges {
          node {
            id
            url
          }
        }
      }
    }
  `,
});
