import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  Form, Input, Button, Card, Typography, message,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { setToken, setRefreshToken, logoutUser } from '../../auth/util';

const { Title } = Typography;

const LOGIN_MUTATION = graphql`
  mutation LoginPageTokenAuthMutation($input: ObtainJSONWebTokenInput!) {
    tokenAuth(input: $input) {
        token
        success
        errors
        user {
            id
            username
            email
        }
        refreshToken
    }
  }
`;

export default function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginMutation, isLoading] = useMutation(LOGIN_MUTATION);

  const handleSubmit = (values) => {
    // Just in case something exists
    logoutUser();

    const variables = {
      input: {
        username: username && username.toLowerCase(),
        password,
      },
    };

    loginMutation({
      variables,
      onCompleted: (response, errors) => {
        if (errors && errors.length > 0) {
          const errMessage = errors[0].message || 'An error occurred while registering.';
          message.error(errMessage);
          return;
        }

        const success = response?.tokenAuth?.success;
        if (success === false) {
          if (response?.tokenAuth?.errors?.nonFieldErrors) {
            const currentError = response?.tokenAuth?.errors?.nonFieldErrors[0].message;
            message.error(currentError);
          } else {
            message.error('Something went wrong...');
          }
        } else {
          const token = response?.tokenAuth?.token;
          const refreshToken = response?.tokenAuth?.refreshToken;

          setToken(token);
          setRefreshToken(refreshToken);

          navigate('/');
        }
      },
      onError: (error) => {
        message.error('Something went wrong.');
      },
    });
  };

  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh',
    }}
    >
      <Card style={{ width: '400px', marginTop: '50px' }}>
        <Title style={{ textAlign: 'center', marginTop: 0 }}>Login</Title>
        <Form name="login" onFinish={handleSubmit}>
          <Form.Item
            name="username"
            style={{ marginBottom: 8 }}
            rules={[
              {
                required: true,
                message: 'Please enter your username',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="password"
            style={{ marginBottom: 8 }}
            rules={[
              {
                required: true,
                message: 'Please enter your password',
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: 8 }}
          >
            <Typography.Link href="/password-reset">Forgot password</Typography.Link>
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" block loading={isLoading}>
              Login
            </Button>
            Or
            {' '}
            <Typography.Link href="/register">register now!</Typography.Link>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
