/**
 * @generated SignedSource<<2d67e99de544a22d42a069eedb49e2d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetPropertyAvailabilitiesMutationPayload",
    "kind": "LinkedField",
    "name": "setAvailabilities",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PropertyDraftNode",
        "kind": "LinkedField",
        "name": "propertyDraft",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "daysNoticeRequired",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyAvailabilityNodeConnection",
            "kind": "LinkedField",
            "name": "availabilities",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyAvailabilityNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PropertyAvailabilityNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "availableFrom",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "availableTo",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "step",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectAvailabilitiesFormMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectAvailabilitiesFormMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "fd4e90f6df434f79448ae6a562fb445d",
    "id": null,
    "metadata": {},
    "name": "SelectAvailabilitiesFormMutation",
    "operationKind": "mutation",
    "text": "mutation SelectAvailabilitiesFormMutation(\n  $input: SetPropertyAvailabilitiesMutationInput!\n) {\n  setAvailabilities(input: $input) {\n    propertyDraft {\n      id\n      daysNoticeRequired\n      availabilities {\n        edges {\n          node {\n            id\n            availableFrom\n            availableTo\n          }\n        }\n      }\n      step\n    }\n  }\n}\n"
  }
};
})();

node.hash = "ee995d8209fa813ffe00b05a8f999121";

module.exports = node;
