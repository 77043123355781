import React from 'react';
import { Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

const { Text } = Typography;

function PropertySummary({ property }) {
  const {
    numGuests, numRooms, numBathrooms,
  } = property;
  const dot = <span style={{ margin: '0px 4px' }}>&middot;</span>;

  return (
    <Text>
      {numGuests}
      {' '}
      guests
      {dot}
      {numRooms}
      {' '}
      bedroom
      {dot}
      {numBathrooms}
      {' '}
      bathroom
    </Text>
  );
}

export default createFragmentContainer(PropertySummary, {
  property: graphql`
    fragment PropertySummary_property on PropertyNode {
      numGuests
      numRooms
      numBathrooms
    }
  `,
});
