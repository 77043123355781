/**
 * @generated SignedSource<<2f643a7fd7832237759812cf530c3aa3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latitude",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longitude",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmPinFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmPinMutationPayload",
        "kind": "LinkedField",
        "name": "confirmPin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyDraftNode",
            "kind": "LinkedField",
            "name": "propertyDraft",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfirmPinFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmPinMutationPayload",
        "kind": "LinkedField",
        "name": "confirmPin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyDraftNode",
            "kind": "LinkedField",
            "name": "propertyDraft",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56c59044be99b5acfcddbb8dc4c40cac",
    "id": null,
    "metadata": {},
    "name": "ConfirmPinFormMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmPinFormMutation(\n  $input: ConfirmPinMutationInput!\n) {\n  confirmPin(input: $input) {\n    propertyDraft {\n      latitude\n      longitude\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "7fbf75cd1cf20a410da78667a3edacf0";

module.exports = node;
