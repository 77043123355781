/**
 * @generated SignedSource<<b5b6fe06f14f7113a8508ee2bc520a25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyHouseManualCard_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasHouseManual",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HouseManualDetails_property"
    }
  ],
  "type": "PropertyNode",
  "abstractKey": null
};

node.hash = "d8ec0ac60d2faf60151f177d126c2b8b";

module.exports = node;
