import React, { useState } from 'react';
import {
  Card, Typography, Tabs, Badge, Button,
} from 'antd';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import TabPane from 'antd/es/tabs/TabPane';
import ReservationList from './ReservationList';

const { Title } = Typography;

const RESERVATION_STATUS_STRING = {
  UPCOMING: 'Upcoming', ONGOING: 'Ongoing', PAST: 'Completed', CANCELLED: 'Cancelled',
};
const RESERVATION_STATUS = ['UPCOMING', 'ONGOING', 'PAST', 'CANCELLED'];

function MyPropertyReservations({ user }) {
  const [currentTab, setCurrentTab] = useState('UPCOMING');

  const onChange = (key) => {
    setCurrentTab(key);
  };

  const totalCountOfPastNotCompletedReservations = user.propertySet.edges.reduce(
    (total, { node: property }) => total + (property.reservations_PAST_NOT_COMPLETED?.totalCount || 0),
    0,
  );

  return (
    <Card style={{ width: '100%', marginTop: 20 }} bodyStyle={{ paddingBottom: '8px' }}>
      <Title level={3} style={{ marginTop: 0 }}>Reservations</Title>
      <Tabs defaultActiveKey="1" activeKey={currentTab} onChange={onChange}>
        {RESERVATION_STATUS.map(
          (statusFilter) => {
            const currentReservationsArr = user.propertySet.edges.flatMap(
              ({ node: property }) => property[`reservations_${statusFilter}`],
            );

            const currentReservations = currentReservationsArr != null && currentReservationsArr[0];

            const currentReservationsNodes = currentReservations?.edges?.map((edge) => edge.node);
            const currentReservationTabString = RESERVATION_STATUS_STRING[statusFilter];

            const badgeCount = statusFilter === 'PAST' ? totalCountOfPastNotCompletedReservations : 0;

            return (
              <TabPane
                tab={(
                  <Badge count={badgeCount}>
                    <Button type="text">{currentReservationTabString}</Button>
                  </Badge>
                  )}
                key={statusFilter}
              >
                <ReservationList
                  reservations={currentReservationsNodes || null}
                />
              </TabPane>
            );
          },
        )}
      </Tabs>
    </Card>
  );
}

export default createFragmentContainer(MyPropertyReservations, {
  user: graphql`
    fragment MyPropertyReservations_user on CustomUserNode {
      propertySet(first: 10) {
        edges {
          node {
            id
            title
            city
            country
            reservations_UPCOMING: reservations(first: 10, statusFilter: UPCOMING) {
                edges {
                    node {
                        ...ReservationList_reservations
                    }
                }
            }
            reservations_ONGOING: reservations(first: 10, statusFilter: ONGOING) {
              edges {
                  node {
                      ...ReservationList_reservations
                  }
              }
          }
            reservations_PAST_NOT_COMPLETED: reservations(first: 10, statusFilter: PAST_NOT_COMPLETED) {
                totalCount
            }
            reservations_PAST: reservations(first: 10, statusFilter: PAST, sortByCheckout: true) {
                edges {
                    node {
                        ...ReservationList_reservations
                    }
                }
            }
            reservations_CANCELLED: reservations(first: 10, statusFilter: CANCELLED) {
                edges {
                    node {
                        ...ReservationList_reservations
                    }
                }
            }
          }
        }
      }
    }
  `,
});
