// ReservationItem.js
import React, { useState } from 'react';
import { createFragmentContainer } from 'react-relay';
import {
  Card, Button, Badge, message,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import graphql from 'babel-plugin-relay/macro';
import { getReservationUrl } from 'src/utils/urlUtils';
import { formatDateToString } from '../../utils/calendarUtils';
import ReservationFeedbackModal from '../reservation/ReservationFeedbackModal';
import ReservationCancelButton from '../reservation/ReservationCancelButton';

function ReservationItem({ reservation }) {
  const { property, checkInDate, checkOutDate } = reservation;
  const [modalVisible, setModalVisible] = useState(false);

  const navigate = useNavigate();

  const handleOpenReservation = () => {
    navigate(getReservationUrl(reservation.id));
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const actions = [
    <Button type="link" onClick={handleOpenReservation}>
      Open
    </Button>,
  ];

  const onCancellation = () => {
    // Refresh or do whatever you want after the cancellation is complete
    message.success('Reservation cancelled');
    navigate(getReservationUrl(reservation.id));
  };

  if (reservation.canCancel) {
    actions.push(
      <ReservationCancelButton
        reservation={reservation}
        onCancellation={onCancellation}
        isLateCancellation={reservation.isLateCancellation}
        isViewerGuest={reservation.isViewerGuest}
      />,
    );
  }

  if (reservation.canProvideFeedback) {
    actions.push(
      <Badge dot>
        <Button type="text" onClick={openModal}>Feedback</Button>
      </Badge>,
    );
  }

  return (
    <Card
      style={{ width: '100%' }}
      cover={<img alt="property" style={{ maxHeight: '150px', objectFit: 'cover' }} src={property.images.edges[0]?.node.url} />}
      actions={actions}
    >
      <Card.Meta
        title={`${property.city}`}
        description={`${formatDateToString(checkInDate)} - ${formatDateToString(checkOutDate)}`}
      />
      <ReservationFeedbackModal
        isVisible={modalVisible}
        toggleModal={setModalVisible}
        reservationId={reservation.id} // Pass the reservation ID to the modal
        nights={reservation.nights}
        guestName={reservation.guest.firstName}
      />
    </Card>
  );
}

export default createFragmentContainer(ReservationItem, {
  reservation: graphql`
    fragment ReservationItem_reservation on ReservationNode {
      id
      checkInDate
      checkOutDate
      canCancel
      canProvideFeedback
      nights
      isLateCancellation
      isViewerGuest
      guest {
        firstName
      }
      property {
        city
        country
        images(first: 1) {
          edges {
            node {
              url
            }
          }
        }
      }
    }
  `,
});
