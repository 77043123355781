import React, { useState, useCallback } from 'react';
import { QueryRenderer } from 'react-relay';
import {
  Button, message, Typography, Divider, Col, Row, Affix, Space, Skeleton, Avatar, Card,
} from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { CopyOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import formatNewlines from 'src/utils/miscUtils';
import { getDateRangeFromUrl, getFullPropertyUrl, getPropertyEditUrl } from 'src/utils/urlUtils';
import PropertyImagesMasonry from './PropertyImagesMasonry';
import PropertyReserveCard from './PropertyReserveCard';
import PropertyAmenities from './PropertyAmenities';
import PropertySummary from './PropertySummary';
import PropertyTypeSummary from './PropertyTypeSummary';
import UserSummary from '../user/UserSummary';

const { Text } = Typography;

const { useRelayEnvironment } = require('react-relay');

const PropertyDetailsQuery = graphql`
  query PropertyDetailsPropertyQuery($id: ID!) {
    property(id: $id) {
      id
      title
      description
      latitude
      longitude
      city
      country
      isOwner
      owner {
        ...UserSummary_user
      }
      relevantAvailabilities {
        id
        availableFrom
        availableTo
      }
      ...PropertyImagesMasonry_property
      ...PropertyAmenities_property
      ...PropertySummary_property
      ...PropertyTypeSummary_property
    }
    me {
      ...PropertyReserveCard_customUserNode
    }
  }
`;

function PropertyDetails() {
  const { id } = useParams();
  const location = useLocation();
  const environment = useRelayEnvironment();
  const navigate = useNavigate();

  const dateRangeFromURL = getDateRangeFromUrl(location);
  const propertyEditURL = getPropertyEditUrl(id);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA25x-2K58jI2YByiq72l4ikwAcypprtus',
    libraries: ['places'],
    language: 'en',
  });

  const variables = {
    id,
  };

  const copyUrlToClipboard = useCallback(() => {
    navigator.clipboard.writeText(getFullPropertyUrl(id)).then(() => {
      message.success('URL copied to clipboard!');
    }, () => {
      message.error('Failed to copy URL');
    });
  }, []);

  return (
    <QueryRenderer
      environment={environment}
      query={PropertyDetailsQuery}
      variables={variables}
      render={({ error, props }) => {
        if (error) {
          throw error;
        }

        if (props) {
          const { me, property } = props;
          const center = {
            lat: parseFloat(property.latitude),
            lng: parseFloat(property.longitude),
          };

          return (
            <Row>
              <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 3 }} style={{ padding: 16 }}>
                <Row style={{ marginBottom: '18px' }}>
                  <Col span={24}>
                    <Space style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                      width: '100%',
                    }}
                    >
                      <Title level={3} style={{ marginBottom: '5px' }}>{property.title}</Title>
                      {property.isOwner && (
                        <Button
                          type="primary"
                          size="small"
                          onClick={(event) => {
                            event.preventDefault();
                            navigate(propertyEditURL);
                          }}
                        >
                          <EditOutlined />
                          {' '}
                          Edit
                        </Button>
                      )}
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                    >
                      <Text level={3}>
                        {property.city}
                        ,
                        {' '}
                        {property.country}
                      </Text>
                      <Button
                        type="text"
                        size="small"
                        icon={<CopyOutlined />}
                        onClick={copyUrlToClipboard}
                      >
                        Share
                      </Button>
                    </Space>
                  </Col>
                </Row>
                <PropertyImagesMasonry property={property} />
                <Row gutter={[60, 16]} style={{ paddingTop: '32px' }}>
                  <Col xs={24} md={16}>
                    <Row>
                      <Col xs={24} md={16}>
                        <PropertyTypeSummary property={property} />
                        <Col span={24}>
                          <PropertySummary property={property} />
                        </Col>
                      </Col>
                      <Col xs={24} md={8} style={{ display: 'flex', justifyContent: 'center' }}>
                        <UserSummary user={property.owner} />
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Title level={4} style={{ marginTop: 0 }}>Amenities</Title>
                      <Col span={24} style={{ paddingTop: '8px' }}>
                        <PropertyAmenities property={property} />
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Title level={4} style={{ marginTop: 0 }}>More about the accommodation</Title>
                      <Col span={24} style={{ paddingTop: '8px' }}>
                        <Paragraph
                          ellipsis={{
                            rows: 7,
                            expandable: true,
                          }}
                        >
                          {formatNewlines(property.description) }
                        </Paragraph>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Title level={4} style={{ marginTop: 0 }}>Where you will be</Title>
                      <Paragraph style={{ width: '100%' }}>
                        {`${property.city}, ${property.country}`}
                      </Paragraph>
                      {isLoaded && (
                      <GoogleMap
                        mapContainerStyle={{ height: '400px', width: '100%' }}
                        center={center}
                        zoom={15}
                      >
                        <MarkerF position={center} />
                      </GoogleMap>
                      )}
                    </Row>
                  </Col>
                  <Col xs={24} md={8}>
                    <Affix offsetTop={20}>
                      <PropertyReserveCard
                        user={me}
                        propertyId={property.id}
                        availabilities={property?.relevantAvailabilities ?? []}
                        defaultDateRange={dateRangeFromURL}
                      />
                    </Affix>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        }
        return (
          <Row>
            <Col span={18} offset={3}>
              <Skeleton active paragraph={{ rows: 12 }} />
              <Row gutter={[16, 16]} style={{ paddingTop: '32px' }}>
                <Col span={16} style={{ paddingRight: '48px' }}>
                  <Skeleton active paragraph={{ rows: 2 }} />
                  <Divider />
                  <Skeleton active paragraph={{ rows: 4 }} />
                  <Divider />
                  <Skeleton active paragraph={{ rows: 4 }} />
                  <Divider />
                  <Skeleton active paragraph={{ rows: 4 }} />
                </Col>
                <Col span={8}>
                  <Skeleton.Avatar active size={96} shape="square" />
                  <Skeleton active paragraph={{ rows: 5 }} />
                </Col>
              </Row>
            </Col>
          </Row>
        );
      }}
    />
  );
}

export default PropertyDetails;
