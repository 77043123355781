import React, { useState } from 'react';
import {
  Layout, Menu, Dropdown, Avatar, Space, Spin, Typography, Skeleton, Col, Row, Button, Grid,
} from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useJsApiLoader } from '@react-google-maps/api';
import { logoutUser } from 'src/auth/util';
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer, useRelayEnvironment } from 'react-relay';
import PropertySearchBar from './property/PropertySearchBar';
import MobileSearchModal from './property/MobileSearchModal';

const { Text } = Typography;
const { Header, Content, Footer } = Layout;
const { useBreakpoint } = Grid;

const UserQuery = graphql`
  query AppLayoutUserQuery {
    me {
      username
      hasCompletedPropertySetup
    }
  }
`;

function AppLayout({ children }) {
  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA25x-2K58jI2YByiq72l4ikwAcypprtus',
    libraries: ['places'],
    language: 'en',
  });

  const location = useLocation();
  const environment = useRelayEnvironment();
  const isDraftPropertyPage = location.pathname.includes('/draft_property');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const screens = useBreakpoint();

  const isMobile = !screens.md; // Or your preferred breakpoint for mobile

  const handleMenuClick = ({ key }) => {
    if (key === 'user') {
      return;
    }

    if (key === 'logout') {
      // Perform logout logic
      logoutUser();
      navigate('/login');
      return;
    }

    if (key === '/report-a-bug') {
      window.open('https://noteforms.com/forms/report-a-bug-v7u64p', '_blank');
      return;
    }

    navigate(key);
  };

  const userMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="/profile">Profile</Menu.Item>
      <Menu.Item key="/refer">Referrals</Menu.Item>
      <Menu.Item key="/report-a-bug">Report a Bug</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const renderAvatar = (props) => {
    if (props) {
      const username = props.me?.username;
      return (
        <Avatar
          size={32}
          src={(
            <Typography.Title level={5} style={{ color: 'white', marginTop: 2 }}>
              {username?.[0].toUpperCase() ?? 'U'}
            </Typography.Title>
          )}
          style={{ background: '#364d79' }}
        />
      );
    }

    return (
      <Skeleton.Avatar active shape="circle" style={{ marginTop: 16 }} />
    );
  };

  return (
    <QueryRenderer
      environment={environment}
      query={UserQuery}
      render={({ error, props }) => {
        if (error) {
          throw error;
        }

        if (props?.me?.hasCompletedPropertySetup === false && isDraftPropertyPage !== true) {
          navigate('draft_property');
          return null;
        }

        return (
          <Layout style={{ minHeight: '100vh' }}>
            {!isDraftPropertyPage && (
            <Header
              style={{
                background: '#ffffff',
                boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)',
                zIndex: 2,
              }}
            >
              <Row justify="space-between" align="middle">
                <Col span={3} style={{ textAlign: 'center' }}>
                  <Link to="/properties">
                    <img src="/logo512.png" alt="Logo" style={{ display: 'block', height: '40px' }} />
                  </Link>
                </Col>
                <Col span={18} style={{ textAlign: 'center' }}>
                  {isLoaded ? (
                    isMobile ? (
                      <>
                        <Button type="primary" onClick={() => setIsModalVisible(true)}>
                          Search
                        </Button>
                        <MobileSearchModal visible={isModalVisible} onCancel={() => setIsModalVisible(false)} />
                      </>
                    ) : (
                      <PropertySearchBar />
                    )
                  ) : (
                    <Skeleton.Button active block style={{ marginTop: 16 }} />
                  )}
                </Col>
                <Col span={3} style={{ textAlign: 'end' }}>
                  <Dropdown overlay={userMenu}>
                    {renderAvatar(props)}
                  </Dropdown>
                </Col>
              </Row>
            </Header>
            )}
            <Content style={{
              padding: 0,
              margin: 0,
              background: 'white',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
            >
              {children}
            </Content>
            <Footer style={{
              textAlign: 'center', paddingTop: '12px', paddingBottom: '12px', zIndex: 1,
            }}
            >
              <Text>&copy; 2023 Nestwop Limited.</Text>
              <Text type="secondary"> · </Text>
              <Link target="_blank" to="https://nestwop.notion.site/Nestwop-Privacy-Policy-c2f83409b5c64e479b334c42a279fbd4?pvs=4">Privacy</Link>
              <Text type="secondary"> · </Text>
              <Link target="_blank" to="https://nestwop.notion.site/Terms-of-Service-0f4b79c049804d8f85cc0bc8bfabb69b?pvs=4">Terms</Link>
              <Text type="secondary"> · </Text>
              <Link to="mailto:hello@nestwop.com">Contact Us</Link>
            </Footer>
            {' '}
          </Layout>
        );
      }}
    />
  );
}

export default AppLayout;
