import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';

dayjs.extend(utc);

export const getHouseManualEditUrl = (propertyId) => `/properties/${propertyId}/housemanual/edit`;
export const getPropertyEditUrl = (propertyId) => `/properties/${propertyId}/edit`;
export const getReservationUrl = (reservationId) => `/reservations/${reservationId}`;
export const getPropertyUrl = (propertyId) => `/properties/${propertyId}`;

export const getFullPropertyUrl = (propertyId) => {
  const loc = window.location;
  return `${loc.protocol}//${loc.hostname}${loc.port ? `:${loc.port}` : ''}/properties/${propertyId}`;
};

export function getViewportFromUrl(location, google) {
  const queryParams = new URLSearchParams(location.search);
  const neLat = queryParams.get('neLat');
  const neLng = queryParams.get('neLng');
  const swLat = queryParams.get('swLat');
  const swLng = queryParams.get('swLng');

  if (neLat && neLng && swLat && swLng) {
    const northEast = new google.maps.LatLng(parseFloat(neLat), parseFloat(neLng));
    const southWest = new google.maps.LatLng(parseFloat(swLat), parseFloat(swLng));
    return new google.maps.LatLngBounds(southWest, northEast);
  }

  return null;
}

export function getNorthEastFromUrl(location) {
  const queryParams = new URLSearchParams(location.search);
  const neLat = queryParams.get('neLat');
  const neLng = queryParams.get('neLng');

  if (neLat && neLng) {
    return [parseFloat(neLat), parseFloat(neLng)];
  }

  return null;
}

export function getGuestsNumberFromUrl(location) {
  const queryParams = new URLSearchParams(location.search);
  const guests = queryParams.get('guests');

  if (guests) {
    return parseInt(guests);
  }

  return 1;
}

export function getSouthWestFromUrl(location) {
  const queryParams = new URLSearchParams(location.search);
  const swLat = queryParams.get('swLat');
  const swLng = queryParams.get('swLng');

  if (swLat && swLng) {
    return [parseFloat(swLat), parseFloat(swLng)];
  }

  return null;
}

export function getDateRangeFromUrl(location) {
  const queryParams = new URLSearchParams(location.search);

  const availableFrom = queryParams.get('from');
  const availableTo = queryParams.get('to');

  const fromDate = availableFrom ? dayjs.utc(availableFrom) : null;
  const toDate = availableTo ? dayjs.utc(availableTo) : null;

  if (fromDate == null || toDate == null) {
    return [];
  }

  return [fromDate, toDate];
}
