import React from 'react';
import {
    mdiDoor,
    mdiHome,
    mdiAccountGroup,
    mdiHomeOutline,
    mdiBedKing,
    mdiHomeCity,
    mdiStairs,
} from '@mdi/js';
import Icon from '@mdi/react';

const propertyIconMap = {
    'Private Room': mdiDoor,
    'Entire House/Apartment': mdiHome,
    'Shared Room': mdiAccountGroup,
    'Studio Apartment': mdiHomeOutline,
    'Bed and Breakfast': mdiBedKing,
    Guesthouse: mdiHomeCity,
    Loft: mdiStairs,
};

function PropertyIcon({ propertyType, size = 1.1 }) {
    const iconPath = propertyIconMap[propertyType];

    if (!iconPath) {
        return null;
    }

    return (
        <Icon path={iconPath} size={size} />
    );
}

export default PropertyIcon;
