import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import Title from 'antd/es/typography/Title';

function PropertyTypeSummary({ property }) {
  const propertyType = property?.propertyType?.name ?? 'Place';
  const username = property?.owner?.username ?? 'User';

  return (
    <Title level={4} style={{ marginTop: 0 }}>
      {propertyType}
      {' '}
      by
      {' '}
      {username}
    </Title>
  );
}

export default createFragmentContainer(PropertyTypeSummary, {
  property: graphql`
    fragment PropertyTypeSummary_property on PropertyNode {
      owner {
        username
      }
      propertyType {
        name
      }
    }
  `,
});
