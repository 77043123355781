import { Result, Button } from 'antd';
import React from 'react';

class PageErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.refreshPage = this.refreshPage.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  refreshPage() {
    // reload the current page
    // this.props.navigate(-1);
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary" onClick={this.refreshPage}>Refresh</Button>}
        />
      );
    }

    return this.props.children;
  }
}

export default PageErrorBoundary;
