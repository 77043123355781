import React from 'react';
import {
  Form, DatePicker, Row, Col,
} from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

function AvailabilitiesList({
  dateRanges, handleDateRangeChange, handleRemoveDateRange, isRangeEditable = false,
}) {
  return dateRanges.map((range, index) => (
    <Form.Item key={index} style={{ marginBottom: '12px', width: '100%' }}>
      <Row align="middle" justify="space-between" style={{ width: '100%' }}>
        <Col xs={20} sm={22} md={22} lg={22}>
          <DatePicker.RangePicker
            style={{ width: '100%' }} // Ensure width is 100%
            value={range}
            disabled={!isRangeEditable}
            format="DD MMM"
            onChange={(dates) => handleDateRangeChange(index, dates)}
            allowClear={false}
          />
        </Col>
        <Col xs={4} sm={2} md={2} lg={2}>
          <MinusCircleOutlined
            style={{ marginLeft: '12px', fontSize: '1.25em' }}
            className="dynamic-delete-button"
            onClick={() => handleRemoveDateRange(index)}
          />
        </Col>
      </Row>
    </Form.Item>
  ));
}

export default AvailabilitiesList;
