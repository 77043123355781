import React, { useState } from 'react';
import { useMutation } from 'react-relay/hooks';
import graphql from 'babel-plugin-relay/macro';
import {
  Modal, Rate, Input, message, Typography, Alert,
} from 'antd';
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import NightCurrency from '../common/NightCurrency';

const ProvideFeedbackMutation = graphql`
  mutation ReservationFeedbackModalMutation($input: ProvideFeedbackMutationInput!) {
    provideFeedback(input: $input) {
      user {
        nights
        ...MyPropertyReservations_user
      }
      reservation {
        canProvideFeedback
        status
      }
    }
  }
`;

const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

function ReservationFeedbackModal({
  isVisible, toggleModal, reservationId, nights = 0, guestName = 'Guest',
}) {
  const [commit, isInFlight] = useMutation(ProvideFeedbackMutation);
  const [rating, setRating] = useState(4);
  const [review, setReview] = useState('');

  const handleOk = () => {
    if (rating === 0) {
      message.error('Please provide a rating');
      return;
    }

    commit({
      variables: {
        input: {
          reservationId,
          review,
          rating,
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          message.error('An error occurred while providing feedback');
        } else {
          message.success('Your feedback has been submitted successfully');
          toggleModal(false);
        }
      },
      onError: () => {
        message.error('An error occurred while providing feedback');
      },
    });
  };

  return (
    <Modal
      title={`Feedback for ${guestName}`}
      open={isVisible}
      onOk={handleOk}
      onCancel={() => toggleModal(false)}
      okButtonProps={{ loading: isInFlight }}
      okText="Submit"
    >
      <Alert
        message={(
          <>
            Provide feedback to claim
            <NightCurrency amount={nights} />
            .
          </>
        )}
        type="info"
        showIcon
      />
      <Typography.Title level={5} style={{ marginTop: 8 }}>
        Rate your experience with
        {' '}
        {guestName}
        :
      </Typography.Title>
      <Rate
        character={({ index }) => customIcons[index + 1]}
        value={rating}
        onChange={setRating}
      />
      <Typography.Title level={5} style={{ marginTop: 16 }}>
        Additional comments (optional):
      </Typography.Title>
      <Input.TextArea rows={4} value={review} onChange={(e) => setReview(e.target.value)} />
    </Modal>
  );
}

export default ReservationFeedbackModal;
