import React, { useState } from 'react';
import { Form, Input } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer, useMutation } from 'react-relay';
import ButtonFooter from './MultiStepButtonFooter';

const provideTitleFormMutation = graphql`
  mutation ProvideTitleFormMutation($input: SetTitleMutationInput!) {
    setTitle(input: $input) {
      propertyDraft {
        id
        title
      }
    }
  }
`;

function ProvideTitleForm({ propertyDraft, goBack, goNext }) {
  const [title, setTitle] = useState(propertyDraft.title || '');
  const [commit, isInFlight] = useMutation(provideTitleFormMutation);

  const handleSubmit = () => {
    commit({
      variables: {
        input: {
          title,
        },
      },
      onCompleted: () => {
        goNext();
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  return (
    <>
      <Form layout="vertical" size="large" style={{ padding: 16 }}>
        <Form.Item>
          <Input
            maxLength={50}
            showCount
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="e.g. A Stylish 1-Bedroom Gem Nestled in Notting Hill"
          />
        </Form.Item>
      </Form>
      <ButtonFooter
        onBack={goBack}
        onNext={handleSubmit}
        nextDisabled={!title}
      />
    </>
  );
}

export default createFragmentContainer(ProvideTitleForm, {
  propertyDraft: graphql`
    fragment ProvideTitleForm_propertyDraft on PropertyDraftNode {
      id
      title
    }
  `,
});
