import {
  Environment, Network, RecordSource, Store, commitMutation,
} from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';
import {
  getToken, setToken, getRefreshToken, setRefreshToken, getExpiresIn, logoutUser,
} from './auth/util';

const HTTP_ENDPOINT = process.env.REACT_APP_GRAPHQL_HTTP_ENDPOINT;

const REFRESH_TOKEN_MUTATION = graphql`
  mutation RelayEnvironmentRefreshTokenMutation($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      success
      errors
      payload
      token
      refreshToken
    }
  }
`;

const refreshEnvironment = new Environment({
  network: Network.create((operation, variables) => fetch(HTTP_ENDPOINT, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `JWT ${getRefreshToken()}`, // Use refresh token for this request
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then((response) => response.json()).catch((error) => {
    console.error('An error occurred:', error);
  })),
  store: new Store(new RecordSource()),
});

const tokenRefreshMiddleware = (next) => (operation, variables) => {
  let refreshPromise = null;

  const token = getToken();
  if (token) {
    if (Date.now() >= getExpiresIn()) {
      if (!refreshPromise) {
        refreshPromise = new Promise((resolve, reject) => {
          commitMutation(refreshEnvironment, {
            mutation: REFRESH_TOKEN_MUTATION,
            variables: {
              input: {
                refreshToken: getRefreshToken(),
              },
            },
            onCompleted: (response, errors) => {
              if (response?.refreshToken?.refreshToken && response?.refreshToken?.token) {
                setToken(response.refreshToken.token);
                setRefreshToken(response.refreshToken.refreshToken);
                resolve();
              } else {
                // Handle refresh token failure
                const mutationErrors = response?.refreshToken?.errors?.nonFieldErrors;
                if (mutationErrors && mutationErrors.some((error) => error.code === 'invalid_token')) {
                  logoutUser();
                  window.location.href = '/login';
                } else {
                  reject(errors);
                }
              }
            },
            onError: (error) => {
              reject(error);
            },
          });
        });
      }

      return refreshPromise.then(() => next(operation, variables)).catch((error) => {
        console.error('An error occurred:', error);
      });
    }
  }
  return next(operation, variables);
};

// Middleware function to handle unauthorized errors
const unauthorizedMiddleware = (next) => async (operation, variables) => {
  const result = await next(operation, variables);

  const { errors } = result;
  if (errors) {
    const unauthorizedErrors = errors.filter(
      ({ message }) => message === 'You do not have permission to perform this action',
    );
    if (unauthorizedErrors.length > 0) {
      // Redirect to login page or show an error message
      logoutUser();
      window.location.href = '/login';
    }
  }
  return result;
};

const network = Network.create(
  tokenRefreshMiddleware(
    unauthorizedMiddleware((request, variables) => fetch(HTTP_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `JWT ${getToken()}`,
      },
      body: JSON.stringify({
        query: request.text, // <-- The GraphQL document composed by Relay
        variables,
      }),
    }).then((response) => response.json())),
  ),
);

function createRelayEnvironment() {
  return new Environment({
    network,
    store: new Store(new RecordSource()),
  });
}

const RelayEnvironment = createRelayEnvironment();

export default RelayEnvironment;
