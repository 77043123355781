import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';

dayjs.extend(utc);

export const isDateWithinAvailableDates = (date, dateRanges) => {
  const dateDayjs = dayjs.utc(date);
  return dateRanges.some((dateRange) => {
    const availableFrom = dateRange[0].startOf('day');
    const availableTo = dateRange[1].endOf('day');
    return (dateDayjs.isAfter(availableFrom) || dateDayjs.isSame(availableFrom))
      && (dateDayjs.isBefore(availableTo) || dateDayjs.isSame(availableTo));
  });
};

export const isDateWithinAvailableAvailabilities = (date, availabilities) => {
  const dateDayjs = dayjs.utc(date);
  return availabilities.some((availability) => {
    const availableFrom = dayjs.utc(availability.availableFrom).startOf('day');
    const availableTo = dayjs.utc(availability.availableTo).endOf('day');
    return (dateDayjs.isAfter(availableFrom) || dateDayjs.isSame(availableFrom))
      && (dateDayjs.isBefore(availableTo) || dateDayjs.isSame(availableTo));
  });
};

export const isDisabledDateByAvailabilities = (current, availabilities) => {
  // Disable all dates before today
  if (current && current < dayjs.utc().startOf('day')) {
    return true;
  }
  // Enable only dates within available date ranges
  return !isDateWithinAvailableAvailabilities(current, availabilities);
};

export const isDisabledDateByDateRanges = (current, dateRanges) => {
  // Disable all dates before today
  if (current && current < dayjs.utc().startOf('day')) {
    return true;
  }
  // Enable only dates that are outside out of the already selected date ranges
  return isDateWithinAvailableDates(current, dateRanges);
};

export const adjustDatesToSingleAvailability = (dateRange, availabilities) => {
  if (dateRange == null || dateRange.length === 0) {
    return null;
  }

  const [start, end] = dateRange.map((date) => dayjs.utc(date));

  for (const availability of availabilities) {
    const availableFrom = dayjs.utc(availability.availableFrom).startOf('day');
    const availableTo = dayjs.utc(availability.availableTo).endOf('day');

    // Check if the startDate is within this availability
    if (start.isAfter(availableFrom) && start.isBefore(availableTo)) {
      // If the endDate is also within this availability, return as is
      if (end.isBefore(availableTo)) {
        return [start, end];
      }
      // If the endDate is beyond this availability, adjust it

      return [start, availableTo];
    }
  }
  // If no suitable availability is found, return null or any default value
  return null;
};

export const calculateCost = (dateRange) => {
  if (dateRange && dateRange[0] && dateRange[1]) {
    const nights = dayjs.utc(dateRange[1]).diff(dayjs.utc(dateRange[0]), 'day');
    const costPerNight = 1; // Change this to your desired cost per night
    return nights * costPerNight;
  }
  return 0;
};

export const formatDateToString = (date, format = 'DD MMM') => {
  const dateJs = dayjs.utc(date);
  return dateJs.format(format);
};
