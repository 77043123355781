/**
 * @generated SignedSource<<07a294728d6d91a7b03aaced5151ecb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfigureDetailsForm_propertyDraft",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numGuests",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numBathrooms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numRooms",
      "storageKey": null
    }
  ],
  "type": "PropertyDraftNode",
  "abstractKey": null
};

node.hash = "0873b71e639ca56232fbabfa4cb5ec9a";

module.exports = node;
