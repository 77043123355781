/**
 * @generated SignedSource<<a387c29077e4a76dc97994b31ee2168a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyReserveCard_customUserNode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nights",
      "storageKey": null
    }
  ],
  "type": "CustomUserNode",
  "abstractKey": null
};

node.hash = "c4afd5f6479d70dd180bca32b01c1d49";

module.exports = node;
