import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { List } from 'antd';
import ReservationItem from './ReservationItem';

function ReservationList({ reservations }) {
  if (reservations == null) {
    return <div>No reservations</div>;
  }

  return (
    <List
      dataSource={reservations}
      grid={{
        gutter: 16, column: 3, xl: 3, lg: 2, md: 2, sm: 2, xs: 1,
      }}
      renderItem={(reservation) => (
        <List.Item
          key={reservation.id}
        >
          <ReservationItem
            reservation={reservation}
          />
        </List.Item>
      )}
    />
  );
}

export default createFragmentContainer(ReservationList, {
  reservations: graphql`
      fragment ReservationList_reservations on ReservationNode @relay(plural: true) {
        id
        guest {
          username
        }
        checkInDate
        checkOutDate
        status
        ...ReservationItem_reservation
      }
    `,
});
