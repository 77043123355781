import React, {
  useEffect,
  useRef, useState,
} from 'react';
import { Form, Skeleton, Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer, useMutation } from 'react-relay';
import {
  GoogleMap, MarkerF, useJsApiLoader,
} from '@react-google-maps/api';
import { mapOptions } from 'src/utils/googleMapUtils';
import ButtonFooter from './MultiStepButtonFooter';

function ConfirmPinForm({ propertyDraft, goBack, goNext }) {
  const [location, setLocation] = useState({
    latitude: parseFloat(propertyDraft.latitude),
    longitude: parseFloat(propertyDraft.longitude),

  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA25x-2K58jI2YByiq72l4ikwAcypprtus',
    libraries: ['places'],
    language: 'en',
  });

  const mapRef = useRef(null);

  const isFormValid = () => location.latitude && location.longitude;

  const onMapClick = (e) => {
    setLocation({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
  };

  const [commitConfirmPin] = useMutation(graphql`
    mutation ConfirmPinFormMutation($input: ConfirmPinMutationInput!) {
      confirmPin(input: $input) {
        propertyDraft {
          latitude
          longitude
        }
      }
    }
  `);

  const handleSubmit = () => {
    commitConfirmPin({
      variables: {
        input: {
          latitude: location.latitude,
          longitude: location.longitude,
        },
      },
      onCompleted: () => {
        goNext();
      },
      onError: (error) => {
        console.error('Error during mutation:', error);
      },
    });
  };

  useEffect(() => {
    if (mapRef.current && location.latitude && location.longitude) {
      mapRef.current.panTo({ lat: location.latitude, lng: location.longitude });
    }
  }, [location]);

  return isLoaded ? (
    <>
      <div style={{ padding: 16, paddingTop: 0 }}>
        <Typography.Text style={{ marginBottom: '16px', display: 'block', textAlign: 'center' }}>
          Please confirm the location by checking the pin on the map. If needed, click on the correct location
          on the map to adjust the pin.
        </Typography.Text>
        <GoogleMap
          ref={mapRef}
          mapContainerStyle={{
            width: '100%',
            height: '400px',
          }}
          center={{
            lat: parseFloat(propertyDraft.latitude) || 0,
            lng: parseFloat(propertyDraft.longitude) || 0,
          }}
          onClick={onMapClick}
          onLoad={(map) => {
            mapRef.current = map;
          }}
          zoom={15}
          options={mapOptions}
        >
          {location.latitude && location.longitude && (
          <MarkerF position={{ lat: location.latitude, lng: location.longitude }} />
          )}
        </GoogleMap>
      </div>
      <Form onFinish={handleSubmit}>
        <Form.Item>
          <ButtonFooter
            onBack={goBack}
            onNext={handleSubmit}
            nextDisabled={!isFormValid()}
          />
        </Form.Item>
      </Form>
    </>
  ) : <Skeleton style={{ height: '490px' }} active />;
}

export default createFragmentContainer(ConfirmPinForm, {
  propertyDraft: graphql`
    fragment ConfirmPinForm_propertyDraft on PropertyDraftNode {
      latitude
      longitude
    }
  `,
});
