/**
 * @generated SignedSource<<27a587e74d573be8d2dcf833f1531998>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyInlineTitleFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePropertyMutationPayload",
        "kind": "LinkedField",
        "name": "updateProperty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyNode",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PropertyInlineTitleForm_property"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropertyInlineTitleFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePropertyMutationPayload",
        "kind": "LinkedField",
        "name": "updateProperty",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyNode",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c720471838879f8d98b3de478b14275",
    "id": null,
    "metadata": {},
    "name": "PropertyInlineTitleFormMutation",
    "operationKind": "mutation",
    "text": "mutation PropertyInlineTitleFormMutation(\n  $input: UpdatePropertyMutationInput!\n) {\n  updateProperty(input: $input) {\n    property {\n      id\n      title\n      ...PropertyInlineTitleForm_property\n    }\n  }\n}\n\nfragment PropertyInlineTitleForm_property on PropertyNode {\n  id\n  title\n}\n"
  }
};
})();

node.hash = "5db1bc5ff371dff7f82a37d378bde36e";

module.exports = node;
