import React, { useState } from 'react';
import {
  Card, Col, List, Pagination, Row,
} from 'antd';
import graphql from 'babel-plugin-relay/macro';
import { usePaginationFragment } from 'react-relay';
import PropertyCard from './PropertyCard';
import PropertyMap from './PropertyMap';

const PropertySearchResultsFragment = graphql`
fragment PropertySearchResults_query on Query
@argumentDefinitions(
  count: { type: "Int", defaultValue: 30 }
  cursor: { type: "String" }
)
@refetchable(queryName: "PropertySearchResultsPaginationQuery") {
  properties(
    first: $count
    after: $cursor
    northEast: $northEast
    southWest: $southWest
    availableFrom: $availableFrom
    availableTo: $availableTo
    numGuests: $numGuests
  ) @connection(key: "PropertySearchResults_properties") {
    pageInfo {
      hasNextPage
    }
    totalCount
    edges {
      node {
        relevantAvailabilities(availableFrom: $availableFrom, availableTo: $availableTo) {
          id
          availableFrom
          availableTo
        }
        ...PropertyCard_property
        ...PropertyMap_properties
      }
    }
  }
}
`;

const PAGE_SIZE = 30;

function PropertySearchResults({
  query, viewport, defaultMapCenter,
}) {
  if (query == null) {
    return <Card style={{ width: 300, marginTop: 16 }} loading />;
  }

  const { data, loadNext, isLoadingNext } = usePaginationFragment(PropertySearchResultsFragment, query);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);

    const cursorIndex = (page - 1) * pageSize - 1;
    const cursor = cursorIndex >= 0 ? data.properties.edges[cursorIndex]?.cursor : null;
    loadNext(pageSize, { cursor });
  };

  const properties = data.properties.edges?.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE).map((edge) => edge.node) || null;

  return (
    <Row style={{ maxHeight: 'calc(100vh - 66px)', height: 'calc(100vh - 66px)' }}>
      <Col xs={24} sm={24} lg={15}>
        <div
          id="scrollableDiv"
          style={{
            height: 'calc(100vh - 66px)',
            overflowY: 'scroll',
            overflowX: 'hidden',
            border: '1px solid rgba(140, 140, 140, 0.35)',
            padding: '30px',
          }}
        >
          <List
            loading={isLoadingNext}
            dataSource={properties}
            grid={{
              gutter: 16, column: 3, lg: 3, xs: 1, sm: 2,
            }}
            renderItem={(property) => {
              const availabilities = property?.relevantAvailabilities || [];
              return (
                <List.Item
                  key={property.id}
                >
                  <PropertyCard
                    property={property}
                    relevantAvailabilities={availabilities}
                  />
                </List.Item>
              );
            }}
          />
          <div style={{ textAlign: 'center', marginTop: 16 }}>
            <Pagination
              defaultCurrent={1}
              pageSize={PAGE_SIZE}
              onChange={handlePaginationChange}
              total={data.properties.totalCount}
            />
          </div>
        </div>
      </Col>
      <Col md={0} lg={9}>
        <PropertyMap
          viewport={viewport}
          center={viewport == null ? defaultMapCenter : viewport.getCenter()}
          properties={properties}
        />
      </Col>
    </Row>
  );
}

export default PropertySearchResults;
