// CompanionsUpdateButton.js
import React, { useState } from 'react';
import {
  Button, Modal, Form, Input, message, Checkbox, Col, Row, Space, Card, Tooltip, Typography, Empty, Divider,
} from 'antd';
import {
  useMutation, useFragment,
} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  CloseOutlined, EditOutlined, PlusOutlined, UserOutlined,
} from '@ant-design/icons';

// Relay fragment to fetch companions details
const CompanionsFragment = graphql`
  fragment ReservationCompanionsUpdateButton_reservation on ReservationNode {
    id
    property {
        numGuests
    }
    companions {
        edges {
            node {
                firstName
                lastName
                email
            }
        }
    }
  }
`;

// Relay mutation for updating companions
const UpdateCompanionsMutation = graphql`
  mutation ReservationCompanionsUpdateButtonMutation($input: UpdateCompanionsMutationInput!) {
    updateCompanions(input: $input) {
      reservation {
        id
        companions {
            edges {
                node {
                    firstName
                    lastName
                    email
                }
            }
        }
      }
    }
  }
`;

function ReservationCompanionsUpdateButton({ reservationRef, isIconOnly = false }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const reservation = useFragment(CompanionsFragment, reservationRef);
  const [commitUpdateCompanionsMutation] = useMutation(UpdateCompanionsMutation);

  const handleOpen = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      commitUpdateCompanionsMutation({
        variables: {
          input: {
            reservationId: reservation.id,
            companions: values.companions,
          },
        },
        onCompleted: (response, errors) => {
          if (errors) {
            message.error('Error occurred during updating companions.');
          } else {
            message.success('Companions updated successfully!');
            setIsModalVisible(false);
          }
        },
        onError: (error) => {
          message.error('Error occurred during updating companions.');
        },
      });
    });
  };

  return (
    <>
      {isIconOnly ? (
        <Button
          icon={<EditOutlined />}
          type="link"
          onClick={handleOpen}
        />
      ) : (
        <Button type="primary" onClick={handleOpen}>
          Confirm Your Party
        </Button>
      )}

      <Modal title="Confirm Your Party" open={isModalVisible} onCancel={handleClose} onOk={handleSubmit}>
        <p>Please let us know who will be joining for this reservation. This helps the host prepare for your stay.</p>

        <Form
          form={form}
          layout="vertical"
          initialValues={{ companions: reservation.companions.edges.map((edge) => edge.node) }}
        >
          <Form.List name="companions">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Card
                    key={field.key}
                    style={{ marginBottom: '12px' }}
                    size="small"
                    title={`Companion ${index + 1}`}
                    extra={<Button type="link" icon={<CloseOutlined />} onClick={() => remove(field.name)} />}
                  >
                    <Row gutter={8}>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          style={{ marginBottom: 12 }}
                          name={[field.name, 'firstName']}
                          rules={[{ required: true, message: 'First name is required.' }]}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          style={{ marginBottom: 0 }}
                          name={[field.name, 'lastName']}
                          rules={[{ required: true, message: 'Last name is required.' }]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'email']}
                          style={{ marginBottom: 0 }}
                          rules={[
                            { required: true, message: 'Email is required.' },
                            { type: 'email', message: 'Enter a valid email.' },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                ))}

                {fields.length === 0 && (
                <div style={{ textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                  <Divider />
                  <UserOutlined style={{ fontSize: '32px' }} />
                  <Typography.Title level={4} style={{ marginTop: '8px', marginBottom: '0px' }}>No Companions Added</Typography.Title>
                  <p>If you're traveling with companions, you can add them here. If you're traveling alone, simply confirm below.</p>
                  <Divider />
                </div>
                ) }

                <Tooltip
                  title={fields.length >= (reservation.property.numGuests - 1)
                    ? `Maximum of ${reservation.property.numGuests} guests allowed.`
                    : ''}
                >
                  <span>
                    <Button
                      type="dashed"
                      onClick={add}
                      disabled={fields.length >= (reservation.property.numGuests - 1)}
                      style={{ marginBottom: 12 }}
                      icon={<PlusOutlined />}
                    >
                      Add Companion
                    </Button>
                  </span>
                </Tooltip>

                <Form.Item
                  name="confirmDetails"
                  valuePropName="checked"
                  rules={[{ validator: (_, value) => (value ? Promise.resolve() : Promise.reject('Confirmation is required.')) }]}
                >
                  <Checkbox>
                    {fields.length === 0
                      ? "I confirm that I'm traveling alone."
                      : 'I confirm that the above details are accurate.'}
                  </Checkbox>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
}

export default ReservationCompanionsUpdateButton;
