/**
 * @generated SignedSource<<4d10213a014f17898cb2dd5dddcfca7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AmenityNodeConnection",
  "kind": "LinkedField",
  "name": "amenities",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AmenityNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AmenityNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectAmenitiesFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetAmenitiesMutationPayload",
        "kind": "LinkedField",
        "name": "setAmenities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyDraftNode",
            "kind": "LinkedField",
            "name": "propertyDraft",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectAmenitiesFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetAmenitiesMutationPayload",
        "kind": "LinkedField",
        "name": "setAmenities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyDraftNode",
            "kind": "LinkedField",
            "name": "propertyDraft",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8636dc074a1730d0f1feffaf16c916ac",
    "id": null,
    "metadata": {},
    "name": "SelectAmenitiesFormMutation",
    "operationKind": "mutation",
    "text": "mutation SelectAmenitiesFormMutation(\n  $input: SetAmenitiesMutationInput!\n) {\n  setAmenities(input: $input) {\n    propertyDraft {\n      amenities {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "82387a523c0c8fe4de71c1c28b404eb5";

module.exports = node;
