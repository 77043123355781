import graphql from 'babel-plugin-relay/macro';
import React, { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col, DatePicker, Divider, message, Popconfirm, Row, Space, Typography,
} from 'antd';
import { commitMutation, createFragmentContainer, useRelayEnvironment } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { getReservationUrl } from 'src/utils/urlUtils';
import { adjustDatesToSingleAvailability, calculateCost, isDisabledDateByAvailabilities } from '../../utils/calendarUtils';
import NightCurrency from '../common/NightCurrency';
import PropertyReportModal from './forms/property/PropertyReportModal';

const { RangePicker } = DatePicker;

const createReservationMutation = graphql`
  mutation PropertyReserveCard_CreateReservationMutation(
    $input: CreateReservationMutationInput!
  ) {
    createReservation(input: $input) {
      reservation {
        id
      }
    }
  }
`;

function PropertyReserveCard({
  user, propertyId, availabilities, defaultDateRange,
}) {
  const adjustedDefaultDateRange = adjustDatesToSingleAvailability(defaultDateRange, availabilities);
  const [dateRange, setDateRange] = useState(adjustedDefaultDateRange);

  const environment = useRelayEnvironment();
  const navigate = useNavigate();
  const handleReserveClick = () => {
    const [checkInDate, checkOutDate] = dateRange.map((date) => date.toISOString().split('T')[0]);

    commitMutation(environment, {
      mutation: createReservationMutation,
      variables: {
        input: {
          reservationData: {
            propertyId,
            checkIn: checkInDate,
            checkOut: checkOutDate,
          },
        },
      },
      onCompleted: (response, errors) => {
        if (errors && errors.length > 0) {
          const errMessage = errors[0].message || 'An error occurred while making the reservation.';
          message.error(errMessage);
        } else {
          message.success('Reservation successful');
          navigate(getReservationUrl(response.createReservation.reservation.id));
        }
      },
      onError: (err) => console.error(err),
    });
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const availableNights = user.nights;
  const reservationNightsRequired = calculateCost(dateRange);
  const leftoverNights = availableNights - reservationNightsRequired;

  const userHasEnoughNights = reservationNightsRequired <= availableNights;

  const disabledDate = (current) => isDisabledDateByAvailabilities(current, availabilities);

  const isReservationDisabled = !userHasEnoughNights || reservationNightsRequired === 0;
  return (
    <>
      <Card
        bordered={false}
      >
        <Row>
          <Col span={24} style={{ marginBottom: '16px' }}>
            <Space align="center" size={[2, 2]}>
              <NightCurrency amount={1} size="large" />
              {' '}
              <Typography.Text>
                / night
              </Typography.Text>
            </Space>
          </Col>
          <Col span={24}>
            <RangePicker
              defaultValue={dateRange}
              size="large"
              onChange={handleDateRangeChange}
              disabledDate={disabledDate}
              style={{ width: '100%' }}
              format="DD MMM"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Typography.Text type="secondary">Creating a reservation is final and ensures your stay.</Typography.Text>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Typography.Text>Balance: </Typography.Text>
          <div style={{ flexGrow: 1 }} />
          <NightCurrency amount={availableNights} />
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Typography.Text>Cost: </Typography.Text>
          <div style={{ flexGrow: 1 }} />
          <NightCurrency amount={reservationNightsRequired} />
        </Row>
        <Divider />
        <Row style={{ marginTop: '16px' }}>
          {userHasEnoughNights
            ? (
              <>
                <Typography.Text>Leftover: </Typography.Text>
                <div style={{ flexGrow: 1 }} />
                <NightCurrency amount={leftoverNights} />
              </>
            )
            : (
              <Alert
                message="Insufficient Lunas"
                description={(
                  <span>
                    Your balance is not enough to make this reservation. Earn more Lunas by hosting or
                    {' '}
                    <a href="/refer">refer a friend</a>
                    .
                  </span>
              )}
                type="warning"
                showIcon
              />
            )}
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col span={24}>
            <Popconfirm
              style={{ maxWidth: '200px' }}
              title="Make Reservation"
              description="The property will be reserved and the credits deducted from your account."
              onConfirm={handleReserveClick}
              okText="Confirm"
              disabled={isReservationDisabled}
              cancelText="Cancel"
            >
              <Button
                block
                type="primary"
                disabled={isReservationDisabled}
              >
                Reserve
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Card>
      <Row justify="center" style={{ marginTop: 10 }}>
        <Col>
          <PropertyReportModal propertyId={propertyId} />
        </Col>
      </Row>
    </>
  );
}

export default createFragmentContainer(PropertyReserveCard, {
  user: graphql`
    fragment PropertyReserveCard_customUserNode on CustomUserNode {
      nights
    }
  `,
});
