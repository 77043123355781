/**
 * @generated SignedSource<<ed50845d04ee5a319b99303ae9f8edff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasCompletedPropertySetup",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppLayoutUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppLayoutUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomUserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff54ed74a444babdc1435029b0fb8f97",
    "id": null,
    "metadata": {},
    "name": "AppLayoutUserQuery",
    "operationKind": "query",
    "text": "query AppLayoutUserQuery {\n  me {\n    username\n    hasCompletedPropertySetup\n    id\n  }\n}\n"
  }
};
})();

node.hash = "8b8e147934729297ca57efae6436aaa6";

module.exports = node;
